import * as React from "react";
import Action from "./Action";
import OutsideClickHandler from "react-outside-click-handler";
import DatePicker from "./DatePicker";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { useEffect } from "react";
interface LayoutHeaderProps {
  el: Record<string, any>;
  defaultData?: Record<string, any>;
  title: string | "-";
  onDateChange?: (range: { start_date: string; end_date: string }) => void;
  pin: (el: object) => void | undefined;
  info: (el: object) => void | undefined;
  remove: (el: object) => void | undefined;
  userSelect?: (el: string) => void;
  GroupSelect?: (group: string) => void;
  userSelected?: Record<string, any>;
  groupSelected?: string;
  usersList?: any;
  Groups?: any;
  DateToggle?: (value: any, id: any) => void | undefined;
  calendarDisable?: boolean | false;
  userOption?: boolean | false;
  table?: boolean | false;
  t: Record<string, any>;
  SmallWidget: boolean;
}

export default function LayoutHeader({
  el,
  pin,
  info,
  remove,
  onDateChange,
  defaultData,
  title,
  calendarDisable,
  DateToggle,
  userSelect,
  userSelected,
  SmallWidget,
  userOption,
  usersList,
  GroupSelect,
  groupSelected,
  Groups,
  t,
}: LayoutHeaderProps) {
  const [DatePopUp, setDatePopUp] = React.useState(false);
  const [ActionBtn, setActionBtn] = React.useState(false);
  const [DriverPopUp, setDriverPopUp] = React.useState(false);
  const [GroupPopUp, setGroupPopUp] = React.useState(false);
  const [CalendarDate, setCalendarDate] = React.useState<any>(defaultData);
  const [Users, setUsers] = React.useState([]);
  const user = useSelector((state: RootState) => state.user.profile);
  useEffect(() => {
    if (usersList) {
      setUsers(usersList);
    }
  }, [usersList]);
  function activeUser() {
    if (userSelected) {
      if (el.user && el.user.first_name) {
        return el.user.first_name + " " + el.user.last_name;
      } else {
        if (userSelected.first_name) {
          return userSelected.first_name + " " + userSelected.last_name;
        }
      }
    }
  }
  //el.permission && el.permission.admin)) ||

  return (
    <>
      <div className={"titleItem "}>
        <span className={"title"}>
          {el.w === 1 ? title.slice(0, 25) + "..." : title}
        </span>

        <div className={"itemHeaderOption"}>
          {el.group_select && (
            <div
              className={"titleItemCalendar titleItemDriver"}
              onClick={() => {
                setGroupPopUp(!GroupPopUp);
                setDriverPopUp(false);
                setDatePopUp(false);
              }}
            >
              <span className={"titleItemDriverIcon titleItemGroupIcon"}></span>
              <span className={"titleItemCalendarDate"}>
                {el.w === 1 ? "" : <>{groupSelected}</>}
              </span>
              <span className={"titleItemCalendarArrow"}></span>
            </div>
          )}
          {userOption && (
            <div
              className={"titleItemCalendar titleItemDriver"}
              onClick={() => {
                setDriverPopUp(!DriverPopUp);
                setGroupPopUp(false);
                setDatePopUp(false);
              }}
            >
              <span className={"titleItemDriverIcon"}></span>
              <span className={"titleItemCalendarDate"}>
                {el.w === 1 ? "" : <>{activeUser()}</>}
              </span>
              <span className={"titleItemCalendarArrow"}></span>
            </div>
          )}

          {!calendarDisable && (
            <div
              className={"titleItemCalendar"}
              onClick={() => {
                setDatePopUp(!DatePopUp);
                setDriverPopUp(false);
                setGroupPopUp(false);

                DateToggle && DateToggle(!DatePopUp, el);
              }}
            >
              <span className={"titleItemCalendarIcon"}></span>
              <span className={"titleItemCalendarDate"}>
                {el.w === 1 ? (
                  ""
                ) : (
                  <>
                    {defaultData && defaultData.start_date.replaceAll("-", ".")}
                    <strong>/</strong>
                    {defaultData && defaultData.end_date.replaceAll("-", ".")}
                  </>
                )}
              </span>
              <span className={"titleItemCalendarArrow"}></span>
            </div>
          )}

          {el.i && (
            <div
              className={"titleItemSetting"}
              onClick={() => setActionBtn(!ActionBtn)}
            ></div>
          )}
        </div>
      </div>
      <div className="CalendarParent">
        <OutsideClickHandler
          onOutsideClick={() => {
            DatePopUp && setDatePopUp(false);
            DatePopUp && DateToggle && DateToggle(!DatePopUp, el);
            DatePopUp && setActionBtn(false);
            DatePopUp && setDriverPopUp(false);
          }}
        >
          {GroupPopUp && (
            <div className="WidgetDriverList WidgetGroupList customScrollBar">
              {Groups
                ? Groups.map((gp: any, key: any) => (
                    <div
                      className={
                        groupSelected && groupSelected === gp
                          ? "GroupItem GroupItemSelect"
                          : "GroupItem"
                      }
                      key={key}
                      onClick={() => {
                        setGroupPopUp(false);
                        GroupSelect && GroupSelect(gp);
                      }}
                    >
                      <div>{gp}</div>
                    </div>
                  ))
                : t.no_data_available}
            </div>
          )}
          {DriverPopUp && (
            <div className="WidgetDriverList customScrollBar">
              {Users
                ? Users.map((driver: any, key: any) => (
                    <div
                      className={
                        userSelected && userSelected === driver
                          ? "DriverItem DriverItemSelect"
                          : "DriverItem"
                      }
                      key={key}
                      onClick={() => {
                        setDriverPopUp(false);
                        userSelect && userSelect(driver);
                      }}
                    >
                      <div>
                        {driver.picture ? (
                          <img src={driver.picture} />
                        ) : (
                          <span className="driverImgName">
                            {driver.first_name.charAt(0) +
                              "" +
                              driver.last_name.charAt(0)}
                          </span>
                        )}
                      </div>
                      <div>
                        {driver.first_name} {driver.last_name}
                      </div>
                    </div>
                  ))
                : t.no_data_available}
            </div>
          )}
          {ActionBtn && (
            <OutsideClickHandler
              onOutsideClick={() => {
                setActionBtn(false);
              }}
            >
              <Action
                SmallWidget={SmallWidget}
                t={t}
                el={el}
                pin={() => {
                  pin(el);
                }}
                info={() => {
                  info(el);
                }}
                remove={() => {
                  remove(el);
                }}
              />
            </OutsideClickHandler>
          )}
          {DatePopUp && (
            <div className={"datePickerChart"}>
              <DatePicker
                t={t}
                onDateChange={(range: {
                  start_date: string;
                  end_date: string;
                }) => {
                  console.log("range", range);
                  setCalendarDate(range);
                }}
                defaultData={defaultData}
              />
              <div className="DatePickerBtn">
                <button
                  className="cancel"
                  onClick={() => {
                    setDatePopUp(false);
                  }}
                >
                  {t.Cancel}
                </button>
                <button
                  className="ok"
                  onClick={() => {
                    DatePopUp && onDateChange && onDateChange(CalendarDate);
                    DatePopUp && setDatePopUp(false);
                    DatePopUp && DateToggle && DateToggle(!DatePopUp, el);
                  }}
                >
                  {t.OK}
                </button>
              </div>
            </div>
          )}
        </OutsideClickHandler>
      </div>
    </>
  );
}
