import * as React from "react";
import { connect, useSelector } from "react-redux";
import { DropDown } from "../../../utilities/components/FromUtils";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import {
  drivingLicenses,
  driverLicensesAdd,
  driverLicenses,
} from "../../../redux/actions/user.action";
import { useDispatch } from "../../../redux/store";
import { RootState } from "../../../redux/reducers";
import InputWrapper from "../../../Components/InputWrapper/InputWrapper";
import { useForm } from "react-hook-form";
import Calendar from "react-calendar";
import Moment from "moment";

interface Props {
  close: () => void | undefined;
}
export default function License({ close }: Props) {
  const { register } = useForm();
  const dispatch = useDispatch();
  const [IssueDateCalendar, setIssueDateCalendar] = useState(false);
  const [ExpireDateCalendar, setExpireDateCalendar] = useState(false);
  const [IssueDate, setIssueDate] = useState(new Date());
  const [ExpireDate, setExpireDate] = useState(new Date());
  const [Error, setError] = useState("");

  const [LicenseData, setLicenseData] = useState({
    driving_license_class: "",
    license_number: "",
    issue_date: Moment(IssueDate).format("YYYY-MM-DD"),
    expire_date: Moment(ExpireDate).format("YYYY-MM-DD"),
    driving_license_class_name: "",
  });
  const [DrivingLicenses, setDrivingLicenses] = React.useState([]);

  function read() {
    dispatch(drivingLicenses()).then((response: any) => {
      setDrivingLicenses(response.payload.results);
    });
  }

  function save() {
    dispatch(driverLicensesAdd(LicenseData)).then((response: any) => {
      if (response.payload && !response.payload.error) {
        close();
      } else {
        setError(response.payload.errorMassage);
      }
    });
  }

  const [t] = useTranslation();
  useEffect(() => {
    read();
  }, []);

  return (
    <div className={"popupBg"}>
      <div className={"popup-content popup-content-language"}>
        <div className="account-modal-close d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              close();
            }}
          >
            <img src={"/assets/closeCircle.svg"} alt="close-icon" />
          </button>
        </div>
        <div className={"title"}>Add License</div>
        <div className={"ErrorMassage"}>{Error}</div>

        <InputWrapper label={t("account.LicenseClass") + ":"} important>
          <DropDown
            selectedItem={{ name: LicenseData.driving_license_class_name }}
            textEntity={"name"}
            preText={""}
            items={DrivingLicenses}
            pngSource={null}
            placeholder="Select License"
            onChange={(selected: any) => {
              setLicenseData({
                ...LicenseData,
                driving_license_class: selected.id,
                driving_license_class_name: selected.name,
              });
              console.log("LicenseData", LicenseData);
            }}
          />
        </InputWrapper>
        <InputWrapper label={t("account.license_number") + ":"} important>
          <input
            name="license_number"
            onChange={(e: any) => {
              setLicenseData({
                ...LicenseData,
                license_number: e.target.value,
              });
            }}
            type="text"
            ref={register({ required: true })}
            autoComplete="license_number"
            value={LicenseData.license_number}
            placeholder="License Number"
          />
        </InputWrapper>
        <div className={"d-flex mt-2"}>
          <div className={"inputColTitle"}>
            <span>Issue Date: *</span>
            <div
              className="divInput"
              onClick={() => {
                setIssueDateCalendar(true);
                setExpireDateCalendar(false);
              }}
            >
              {Moment(IssueDate).format("YYYY-MM-DD")}
              <div className={"DatePickerLic"}>
                {IssueDateCalendar && (
                  <Calendar
                    onChange={(data: any) => {
                      if (data < ExpireDate) {
                        setIssueDateCalendar(false);
                        setLicenseData({
                          ...LicenseData,
                          issue_date: Moment(data).format("YYYY-MM-DD"),
                        });
                        setIssueDate(data);
                      } else {
                        alert("issue date can not be after Expiry date");
                      }
                    }}
                    value={IssueDate}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={"inputColTitle"}>
            <span>Expire Date: *</span>
            <div
              className="divInput"
              onClick={() => {
                setExpireDateCalendar(true);
                setIssueDateCalendar(false);
              }}
            >
              {Moment(ExpireDate).format("YYYY-MM-DD")}
              <div className={"DatePickerLic"}>
                {ExpireDateCalendar && (
                  <Calendar
                    onChange={(data: any) => {
                      if (data >= IssueDate) {
                        setExpireDateCalendar(false);
                        setLicenseData({
                          ...LicenseData,
                          expire_date: Moment(data).format("YYYY-MM-DD"),
                        });
                        setExpireDate(data);
                      } else {
                        alert("Expiry date can not be before issue date");
                      }
                    }}
                    value={ExpireDate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={"add"}
          onClick={() => {
            save();
          }}
        >
          <img src={"/assets/plus-white.svg"} />
          {t("account.add")}
        </div>
      </div>
    </div>
  );
}
