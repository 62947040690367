import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
//import ReduxDevTools from "./pages/ReduxDevTools/ReduxDevTools";
import App from "./App";
import "./styles/css/CoreCss.css";
import "./utilities/i18n";
//  <React.StrictMode> Commented
ReactDOM.render(
  <Provider store={store}>
    <App />
    {/*<ReduxDevTools />*/}
  </Provider>,
  document.getElementById("root"),
);
