import * as userService from "../services/user.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorHandler } from "../apiGateway";
export const profile = createAsyncThunk("profile", async (token?: string) => {
  try {
    return await userService.profile(token);
  } catch (err) {
    return errorHandler(err);
  }
});
export const profileHeader = createAsyncThunk(
  "profileHeader",
  async (token?: string) => {
    try {
      return await userService.profileHeader(token);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const company = createAsyncThunk("company", async (token?: string) => {
  try {
    return await userService.company(token);
  } catch (err) {
    return errorHandler(err);
  }
});
export const countries = createAsyncThunk("countries", async () => {
  try {
    return await userService.countries();
  } catch (err) {
    return errorHandler(err);
  }
});
export const permissions = createAsyncThunk("permissions", async () => {
  try {
    return await userService.permissions();
  } catch (err) {
    return errorHandler(err);
  }
});
export const impersonate = createAsyncThunk(
  "impersonate",
  async (company_id?: string) => {
    try {
      return await userService.impersonate(company_id);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const impersonateChange = createAsyncThunk(
  "impersonateChange",
  async (id: any) => {
    try {
      return await userService.impersonateChange(id);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const permissionChange = createAsyncThunk(
  "permissionChange",
  async ({ company, role }: { company: string; role: string }) => {
    try {
      return await userService.permissionChange(company, role);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const paymentMethods = createAsyncThunk("paymentMethods", async () => {
  try {
    return await userService.paymentMethods();
  } catch (err) {
    return errorHandler(err);
  }
});
export const inviteLink = createAsyncThunk(
  "inviteLink",
  async (token?: any) => {
    try {
      return await userService.inviteLink(token);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const paymentMethodEdit = createAsyncThunk(
  "paymentMethods",
  async (data: any) => {
    try {
      return await userService.paymentMethodEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const paymentMethodCreate = createAsyncThunk(
  "paymentMethods",
  async (data: any) => {
    try {
      return await userService.paymentMethodCreate(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const inviteEmails = createAsyncThunk(
  "inviteEmails",
  async (data: any) => {
    try {
      return await userService.inviteEmails(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const profileEdit = createAsyncThunk(
  "profile/edit",
  async ({ data, token }: { data: any; token?: any }) => {
    try {
      return await userService.profileEdit({ data, token });
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const companyEdit = createAsyncThunk(
  "company/edit",
  async (data: any) => {
    try {
      return await userService.companyEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const shippingAddressEdit = createAsyncThunk(
  "shipping-address/",
  async (data: any) => {
    try {
      return await userService.shippingAddressEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const shippingAddress = createAsyncThunk(
  "shipping-address/",
  async () => {
    try {
      return await userService.shippingAddress();
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const driverLicensesAdd = createAsyncThunk(
  "driver-licenses/",
  async (data: any) => {
    try {
      return await userService.driverLicensesAdd(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const passwordChange = createAsyncThunk(
  "password-change/",
  async (json: any) => {
    try {
      return await userService.passwordChange(json);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const drivingLicenses = createAsyncThunk(
  "driving-licenses/",
  async () => {
    try {
      return await userService.drivingLicenses();
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const driverLicenses = createAsyncThunk("driver-licenses/", async () => {
  try {
    return await userService.driverLicenses();
  } catch (err) {
    return errorHandler(err);
  }
});
export const languages = createAsyncThunk("languages/", async () => {
  try {
    return await userService.languages();
  } catch (err) {
    return errorHandler(err);
  }
});

export const languageAdd = createAsyncThunk(
  "spoken-languages/",
  async (data: any) => {
    try {
      return await userService.languageAdd(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const invoices = createAsyncThunk("invoices/", async () => {
  try {
    return await userService.invoices();
  } catch (err) {
    return errorHandler(err);
  }
});
export const spokenLanguages = createAsyncThunk(
  "spoken-languages/",
  async () => {
    try {
      return await userService.spokenLanguages();
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const driverLicenseDelete = createAsyncThunk(
  "driver-licenses/",
  async (id: any) => {
    try {
      return await userService.driverLicenseDelete(id);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const languageDelete = createAsyncThunk(
  "spoken-languages/",
  async (id: any) => {
    try {
      return await userService.languageDelete(id);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
