import React, { useEffect } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch } from "../../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import {
  trailers,
  vehicleCreate,
  vehicleDelete,
  vehicleEdit,
  vehiclesTag,
} from "../../../redux/actions/table.action";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../../utilities/components/Table/Table";
import { useTranslation } from "react-i18next";

// interface ChartProps {
//     // pin: () => void | undefined;
//     // remove: () => void | undefined;
//     // changeDate: (Range: object) => void | undefined;
//     // el: Record<string, any>;
// }

const DefaultParam = {
  offset: 0,
  limit: 10,
  search: false,
  ordering: false,
  according: false,
};

export default function Trailers() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const TableRowsData = [
    {
      name: "name",
      label: t("table.name"),
      type: "text",
      required: true,
      help_text: "",
      editable: true,
    },
    {
      name: "license",
      label: t("table.license"),
      type: "text",
      required: true,
      help_text: "",
      editable: true,
    },
    {
      name: "vehicle_brand",
      label: t("table.vehicle_brand"),
      type: "search",
      search: "vehiclesBrands",
      options: [],
      required: true,
      help_text: "",
      editable: true,
    },
    {
      name: "sub_type",
      label: t("table.Sub_Type"),
      type: "search",
      search: "vehiclesSubtypes",
      options: [],
      required: false,
      help_text: "",
      editable: true,
    },
    {
      name: "color",
      label: t("table.color"),
      type: "color",
      required: false,
      help_text: "",
      editable: true,
    },

    {
      name: "obj_groups",
      label: t("table.groups"),
      type: "array",
      required: false,
      help_text: "",
      editable: true,
    },
  ];

  const [Result, setResult] = React.useState<any>();
  const [DefaultParams, setDefaultParams] = React.useState<any>(DefaultParam);
  const tableData = useSelector((state: RootState) => state.table.trailers);
  useEffect(() => {
    //dispatch(trailers(DefaultParams));
  }, []);
  useEffect(() => {
    if (Result) {
      dispatch(trailers(DefaultParams));
    }
  }, [Result]);

  function request(Params: any) {
    if (
      DefaultParams.offset !== Params.offset ||
      DefaultParams.limit !== Params.limit ||
      DefaultParams.search !== Params.search ||
      DefaultParams.ordering !== Params.ordering ||
      DefaultParams.according !== Params.according
    ) {
      setDefaultParams(Params);
      dispatch(trailers(Params));
    }
  }

  function create(data: any) {
    dispatch(vehicleCreate(data)).then((res) => {
      if (res) {
        setResult(res);
      }
    });
  }

  function edit(data: any) {
    dispatch(vehicleEdit(data)).then((res) => {
      if (res) {
        setResult(res);
      }
    });
  }

  function deleteRow(data: any) {
    dispatch(vehicleDelete(data)).then((res) => {
      if (!res.payload.error) {
        setResult({ delete: true });
      } else {
        setResult(res);
      }
    });
  }
  function TagSave(ids: any, group_name: any) {
    dispatch(vehiclesTag({ ids, group_name })).then((res) => {
      if (!res.payload.error) {
        setResult(true);
        const title = t("account.saved");
        toast.success(title, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        // setResult(res);
      }
    });
  }

  return (
    <div>
      <Table
        getData={(Params: any) => {
          request(Params);
        }}
        CreateItem={(data) => create(data)}
        EditItem={(data) => edit(data)}
        ActionResult={Result}
        clearResult={() => {
          setResult(null);
        }}
        TableRowsData={TableRowsData}
        TagSave={(ids, group_name) => {
          TagSave(ids, group_name);
        }}
        tableData={tableData}
        editable={true}
        creatable={true}
        DeleteRow={(data) => deleteRow(data)}
        title={t("menu.trailers")}
        tableType={"TRAILER"}
      />
    </div>
  );
}
