import * as React from "react";
import { ChartLoadingSvg, MapLoadingSvg } from "./../assets/icons";

export default function Loading({ Type }: any) {
  return (
    <>
      {Type === "Map" ? (
        <div className={"widgetLoading"}>
          <MapLoadingSvg />
        </div>
      ) : (
        <div className={"widgetLoading"}>
          <ChartLoadingSvg />
        </div>
      )}
    </>
  );
}
