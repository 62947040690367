import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Param from "../../redux/Param";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { countries } from "../../redux/actions/user.action";
import { cantactUs } from "../../redux/actions/front.action";
import {
  InputWithIcon,
  LoadingButton,
  DropDown,
} from "../../utilities/components/FromUtils";
import FrontApi from "../../api/componentApi/FrontApi";
import { contactValidator } from "../../utilities/functions/frontValidator";
export default function ContactUs(props) {
  const { register } = useForm();
  const dispatch = useDispatch();
  const [tab, setTab] = useState("GENERAL");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [describe, setDescribe] = useState("");
  const [tabs, setTabs] = useState(["GENERAL", "CALL", "PARTNER"]);
  const [show, setShow] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [regionCountry, setRegionCountry] = useState(false);
  const [regionPrenumber, setRegionPrenumber] = useState(false);
  const [countryItems, setCountryItems] = useState([]);
  const [selectedPrenumber, setSelectedPrenumber] = useState(null);
  const [SendMassage, setSendMassage] = useState(false);
  const [Error, setError] = useState(false);
  const [prenumberItems, setPrenumberItems] = useState([]);

  const myRef = useRef();

  function getAllCountries() {
    dispatch(countries()).then((response) => {
      if (response.payload) {
        setCountryItems(response.payload.results);
        setPrenumberItems(response.payload.results);
      }
    });
  }
  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    if (props.history.action === "PUSH") {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });

  function sendContactRequest() {
    myRef.current.scrollIntoView({ behavior: "smooth" });

    const data = {
      contact_type: "GENERAL",
      name: name,
      company_name: companyName,
      role: role,
      email: email,
      phone_cc: selectedPrenumber ? selectedPrenumber.country_code : "",
      phone_number: selectedPrenumber
        ? "+" + selectedPrenumber.country_code + phone
        : phone,
      country: selectedCountry ? selectedCountry.id : "",
      country_code_ISO3: selectedCountry ? selectedCountry.alpha_code : "",
      inquiry: describe,
    };
    dispatch(cantactUs(data)).then((response) => {
      if (response.payload && !response.payload.error) {
        setSendMassage("your message sent successfully");
      } else {
        setError(response.payload.errorMassage);
      }
    });
  }

  const [t] = useTranslation();
  return (
    <div ref={myRef}>
      <div className="front-main-container-overlay">
        <h1>{t("contact.contact-us")}</h1>
        <div className="front-overal-paper-contact">
          <h3>{t("contact.help")}</h3>
          {SendMassage && (
            <div className="alert alert-success">{SendMassage}</div>
          )}
          {Error && <div className="alert alert-danger">{Error}</div>}
          <div className="content d-flex">
            <div className="information">
              <div className="options">
                <Link
                  // to={tab !== "GENERAL" ? "/contact-us/general" : null}
                  onClick={() => {
                    setTab("GENERAL");
                  }}
                  className={tab === "GENERAL" ? "active" : ""}
                >
                  {t("contact.general-inquiries")}
                </Link>
                <Link
                  // to={tab !== "CALL" ? "/contact-us/book-call" : null}
                  onClick={() => {
                    setTab("CALL");
                  }}
                  className={tab === "CALL" ? "active" : ""}
                >
                  {t("contact.book-call")}
                </Link>
                <Link
                  // to={
                  //   tab !== "PARTNER"
                  //     ? "/contact-us/become-partner"
                  //     : null
                  // }
                  onClick={() => {
                    setTab("PARTNER");
                  }}
                  className={tab === "PARTNER" ? "active" : ""}
                >
                  {t("contact.become-partner")}
                </Link>
              </div>
              <div className="main-container-contact-form">
                <div
                  style={{ width: "100%" }}
                  className="d-flex justify-space-between flex-wrap"
                >
                  <InputWithIcon
                    value={name}
                    placeholder="Your Full Name"
                    pngSource={"/assets/images/auth/Profile2x.png"}
                    onChange={(name) => setName(name)}
                  />
                  <InputWithIcon
                    value={role}
                    placeholder="Your Role"
                    pngSource={"/assets/images/front/Detail@2x.png"}
                    onChange={(role) => setRole(role)}
                  />
                  <InputWithIcon
                    value={email}
                    placeholder="Your Email"
                    pngSource={"/assets/images/auth/@2x.png"}
                    onChange={(email) => setEmail(email)}
                  />
                  <InputWithIcon
                    value={companyName}
                    placeholder="Company Name"
                    pngSource={"/assets/images/breadcrumbs/Home@2x.png"}
                    onChange={(companyName) => setCompanyName(companyName)}
                  />
                  <DropDown
                    selectedItem={selectedCountry}
                    items={countryItems}
                    pngSource={"/assets/images/header/Language@2x.png"}
                    placeholder="Select a Country"
                    onChange={(selectedCountry) => {
                      if (selectedPrenumber === null || !regionPrenumber) {
                        setSelectedCountry(selectedCountry);
                        setSelectedPrenumber(selectedCountry);
                        setRegionCountry(true);
                      } else {
                        setSelectedCountry(setSelectedCountry);
                        setRegionCountry(true);
                      }
                    }}
                  />

                  <div className="contact-from-number-dp">
                    <DropDown
                      selectedItem={selectedPrenumber}
                      items={prenumberItems}
                      textEntity={"country_code"}
                      preText={"+ "}
                      placeholder=""
                      onChange={(selectedPrenumber) => {
                        if (selectedCountry === null || !regionCountry) {
                          setSelectedCountry(selectedPrenumber);
                          setSelectedPrenumber(selectedPrenumber);
                          setRegionPrenumber(true);
                        } else {
                          setSelectedPrenumber(selectedPrenumber);
                          setRegionPrenumber(true);
                        }
                      }}
                    />

                    <InputWithIcon
                      value={phone}
                      placeholder="Phone"
                      pngSource={"/assets/images/settings/Mobile@2x.png"}
                      onChange={(phone) => setPhone(phone)}
                    />
                  </div>
                  <InputWithIcon
                    value={describe}
                    multiline={true}
                    placeholder="Please describe your needs and requirements"
                    onChange={(describe) => setDescribe(describe)}
                  />
                </div>
                <div className="from-button-container">
                  <div className="form-button">
                    <LoadingButton
                      loading={loading}
                      onClick={() => sendContactRequest()}
                      text={"Send"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="help-links flex-column remove-under-1250">
              <small> {t("contact.links")}</small>
              <Link to={"/impressum/impressum"}>{t("contact.impressum")}</Link>
              <Link to={"/impressum/terms-and-conditions"}>
                {t("contact.terms")}
              </Link>
              <Link to={"/impressum/privacy-policy"}>
                {t("contact.privacy-policy")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="platoon-ready-section">
        <div>
          <h2>{t("home.fleet-ready")}</h2>
          <h3>{t("home.fleet-ready-detail")}</h3>
        </div>
        <button onClick={() => props.history.push("/contact-us/book-call")}>
          {t("home.free-consultation")}
        </button>
      </div>
    </div>
  );
}
