import * as React from "react";
import { connect, useSelector } from "react-redux";
import {
  PageContainer,
  FormHeader,
  ImagePicker,
  DropDown,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import {
  profile,
  profileEdit,
  spokenLanguages,
  driverLicenses,
  driverLicenseDelete,
  languageDelete,
} from "../../redux/actions/user.action";
import { useDispatch } from "../../redux/store";
import { RootState } from "../../redux/reducers";
import InputWrapper from "../../Components/InputWrapper/InputWrapper";
import { useForm } from "react-hook-form";
import License from "./ProfilePopup/License";
import Language from "./ProfilePopup/Language";
import PasswordChange from "./ProfilePopup/PasswordChange";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";

const commaNumber = require("comma-number");

export default function Account() {
  const { register } = useForm();
  const dispatch = useDispatch();
  const [Data, setData] = useState<Record<string, any>>({});
  const [Error, setError] = useState("");
  const [ErrorLicense, setErrorLicense] = useState("");

  const [LicenseData, setLicenseData] = useState({
    driving_license_class: "",
    number: "",
    issue_date: "",
    expire_date: "",
  });

  const user = useSelector((state: RootState) => state.user.profile);
  const token = useSelector((state: RootState) => state.auth.token);
  const [image, setImage] = React.useState(null);
  const [SpokenLanguages, setSpokenLanguages] = React.useState([]);
  const [DriverLicenses, setDriverLicenses] = React.useState([]);

  const [BtnLoading, setBtnLoading] = React.useState(false);
  const [AddLanguage, setAddLanguage] = React.useState(false);
  const [AddLicenses, setAddLicenses] = React.useState(false);
  const [mobileError, setMobileError] = React.useState(false);
  const [PasswordChangeView, setPasswordChangeView] = React.useState(false);

  React.useEffect(() => {
    if (image !== null) {
      setData({
        ...Data,
        picture: image,
      });
    }
  }, [image]);

  function mobileInputFixer(name: any, value: any) {
    if (
      name === "mobile_number" ||
      name === "mobile_cc" ||
      name === "phone_number"
    ) {
      value = "+" + value.replaceAll("+", "");
      if (value.length !== 12) {
        setMobileError(true);
      } else {
        setMobileError(false);
      }
    }
    if (value === "+") {
      setMobileError(false);

      value = " ";
    }
    return value;
  }
  function sort(array: any, KeySort: any) {
    return array.sort(function (a: any, b: any) {
      if (a[KeySort].name < b[KeySort].name) {
        return -1;
      }
      if (a[KeySort].name > b[KeySort].name) {
        return 1;
      }
      return 0;
    });
  }
  function change(event: any) {
    const name = event.target.name;
    let value = event.target.value;
    // if(name === "groups"){

    // }
    value = mobileInputFixer(name, value);
    setData({
      ...Data,
      [name]: value,
    });
  }

  function read() {
    const token = `${localStorage.getItem("token")}`;
    dispatch(profile(token)).then((response: any) => {
      if (response.payload) {
        setData(response.payload);
        if (response.payload.picture) {
          setImage(response.payload.picture);
        }
      }
    });
    dispatch(spokenLanguages()).then((response: any) => {
      if (response.payload) {
        setSpokenLanguages(response.payload.results);
      }
    });
    dispatch(driverLicenses()).then((response: any) => {
      if (response.payload) {
        setDriverLicenses(response.payload.results);
      }
    });
  }
  const [t] = useTranslation();

  function SaveRequest(yo: any) {
    setBtnLoading(true);
    const data = { ...yo };
    if (typeof data.picture !== "object") {
      data.picture = undefined; //delete picture key because its string
    }
    data.birth_date = data.birth_date && data.birth_date.replaceAll("/", "-");
    dispatch(profileEdit({ data, token })).then((response: any) => {
      setBtnLoading(false);

      if (response.payload && !response.payload.error) {
        setData(response.payload);
        if (response.payload.picture) {
          setImage(response.payload.picture);
          toast.success(t("account.saved"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setError("");
          setMobileError(false);
        }
      } else {
        setError(response.payload.errorMassage);
      }
    });
  }

  function deleteLanguage(id: any) {
    dispatch(languageDelete(id)).then((response: any) => {
      read();
    });
  }
  function deleteLicense(id: any) {
    dispatch(driverLicenseDelete(id)).then((response: any) => {
      read();
    });
  }

  useEffect(() => {
    read();
  }, []);

  return (
    <PageContainer>
      <ToastContainer />
      <FormHeader text={t("account.profile")} />
      <div className={"ErrorMassage"}>{Error}</div>
      {AddLanguage && (
        <Language
          close={() => {
            read();
            setAddLanguage(false);
          }}
        />
      )}
      {AddLicenses && (
        <License
          close={() => {
            read();
            setAddLicenses(false);
          }}
        />
      )}
      {PasswordChangeView && (
        <PasswordChange
          close={() => {
            setPasswordChangeView(false);
          }}
        />
      )}

      <div className="profile-fa ">
        <div className="modal-content-section d-flex flex-column">
          <div className="modal-account-account-detail d-flex">
            <div className="right-section col-4 bar-right-section">
              <ImagePicker
                label="label for picture"
                image={Data.picture ? Data.picture : undefined}
                imageFile={(image: any) => setImage(image)}
              />
              <div className="account-balance d-flex align-items-center justify-content-center">
                <img
                  src={"/assets/images/settings/Wallet.png"}
                  alt="drag-n-drop-logo"
                />
                <small>
                  {t("account.balance")}: {t("account.$")}
                  <span>{commaNumber(Data.balance)}</span>
                </small>
              </div>
              <div className={"profileSide"}>
                <div className={"profileSideItem"}>
                  <div className={"profileSideItemHeader"}>
                    <img
                      src={"/assets/images/header/Language@2x.png"}
                      className={"iconSideProfile"}
                    />
                    {t("account.language")}
                    <img
                      src={"/assets/Plus.svg"}
                      className={"open"}
                      onClick={() => {
                        setAddLanguage(true);
                      }}
                    />
                  </div>
                  <div className={"profileSideItemData"}>
                    {SpokenLanguages &&
                      SpokenLanguages.sort((a: any, b: any) =>
                        a.language.name.localeCompare(b.language.name),
                      ).map((lang: any, key: any) => (
                        <div key={key} className={"profileSideItemLink"}>
                          <img src={lang.language.flag} />
                          {lang.language.name} : {lang.proficiency}
                          <img
                            onClick={() => {
                              deleteLanguage(lang.id);
                            }}
                            src={"/assets/icon-basic-delete.svg"}
                            className={"delete"}
                          />
                        </div>
                      ))}
                  </div>
                </div>
                <div className={"profileSideItem"}>
                  <div className={"profileSideItemHeader"}>
                    <img
                      src={"/assets/icon-basic-credit-card.svg"}
                      className={"iconSideProfile"}
                    />
                    {t("account.driver_licenses")}
                    <img
                      src={"/assets/Plus.svg"}
                      className={"open"}
                      onClick={() => {
                        setAddLicenses(true);
                      }}
                    />
                  </div>
                  <div className={"profileSideItemData"}>
                    {DriverLicenses &&
                      DriverLicenses.sort((a: any, b: any) =>
                        a.driving_license_class.name.localeCompare(
                          b.driving_license_class.name,
                        ),
                      ).map((lic: any, key: any) => (
                        <div key={key} className={"profileSideItemLink"}>
                          <img
                            src={lic.driving_license_class.icon}
                            className={"LicensesIcon"}
                          />
                          {lic.driving_license_class.name}
                          <img
                            onClick={() => {
                              deleteLicense(lic.id);
                            }}
                            src={"/assets/icon-basic-delete.svg"}
                            className={"delete"}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-8 "}>
              <div className={"d-flex"}>
                <div className="col-6 ">
                  <div className="profileSideColTitle">
                    {t("account.company_details")}
                  </div>
                  <InputWrapper label={t("account.first_name") + ":"} important>
                    <input
                      name="first_name"
                      onChange={(value) => change(value)}
                      ref={register({ required: true })}
                      autoComplete="firstName"
                      value={Data && Data.first_name}
                      placeholder="First Name"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.last_name") + ":"} important>
                    <input
                      name="last_name"
                      onChange={(value) => change(value)}
                      ref={register({ required: true })}
                      autoComplete="lastName"
                      value={Data && Data.last_name}
                      placeholder="Last Name"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.email") + ":"} important>
                    <input
                      name="email"
                      onChange={(value) => change(value)}
                      ref={register({ required: true })}
                      autoComplete="email"
                      value={Data && Data.email}
                      placeholder="Email"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.phone") + ":"} important>
                    <input
                      name="mobile_number"
                      onChange={(value) => change(value)}
                      type="text"
                      pattern="[0-9]*"
                      className={mobileError ? "ErrorInputBorder" : ""}
                      ref={register({ required: true })}
                      autoComplete="phone"
                      value={
                        Data && Data.mobile_number ? Data.mobile_number : ""
                      }
                      placeholder="Mobile"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.Date_of_Birth") + ":"}>
                    <input
                      name="birth_date"
                      onChange={(value) => change(value)}
                      ref={register({ required: true })}
                      autoComplete="dateOfBirth"
                      type={"date"}
                      value={
                        Data && Data.birth_date
                          ? Data.birth_date.replaceAll("/", "-")
                          : ""
                      }
                      placeholder="Date of Birth"
                    />
                  </InputWrapper>
                  <div
                    className={"input-with-label-container profilePassChange"}
                  >
                    <small>Passwod:</small>
                    <Button
                      onClick={() => {
                        setPasswordChangeView(true);
                      }}
                    >
                      Change Password
                    </Button>
                  </div>

                  <InputWrapper label={t("account.children")} important>
                    <DropDown
                      selectedItem={{
                        name: Data.has_children
                          ? t("account.YES")
                          : t("account.NO"),
                      }}
                      textEntity={"name"}
                      preText={""}
                      items={[
                        { name: t("account.YES"), value: true },
                        { name: t("account.NO"), value: false },
                      ]}
                      pngSource={"/assets/images/header/Language@2x.png"}
                      placeholder="Select Country"
                      onChange={(selected: any) => {
                        setData({
                          ...Data,
                          has_children: selected.value,
                        });
                      }}
                    />
                  </InputWrapper>
                </div>
                <div className="col-6 ">
                  <div className="profileSideColTitle">
                    {t("account.employee_details")}
                  </div>
                  <InputWrapper label={t("account.location_site") + ":"}>
                    <input
                      name="employee_location_site"
                      onChange={(value) => change(value)}
                      value={
                        Data && Data.employee_location_site
                          ? Data.employee_location_site
                          : ""
                      }
                      ref={register({ required: true })}
                      autoComplete=""
                      placeholder="Location Site"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.groups") + ":"}>
                    {/* <input
                      name="groups"
                      onChange={(value) => change(value)}
                      value={Data && Data.groups ? Data.groups : ""}
                      ref={register({ required: true })}
                      autoComplete=""
                      placeholder="Group 1, Group 2"
                    /> */}
                    <div className="groupsProfile">
                      {Data &&
                        Data.groups &&
                        Data.groups.map((tag: any, key: any) => (
                          <span key={key}>{tag}</span>
                        ))}
                    </div>
                  </InputWrapper>

                  <div className="input-with-label-container">
                    <small>
                      {t("account.employee_start_date")} : <span> </span>
                    </small>
                    <div>
                      <input
                        type={"text"}
                        name="employee_start_date"
                        className={"profileInput"}
                        onChange={(value) => change(value)}
                        value={
                          Data &&
                          Data.employee_start_date &&
                          Data.employee_start_date.split("-") &&
                          Data.employee_start_date.split("-")[0]
                        }
                        ref={register({ required: true })}
                        disabled={true}
                      />
                      <input
                        type={"text"}
                        name="employee_start_date"
                        className={"profileInput"}
                        onChange={(value) => change(value)}
                        value={
                          Data &&
                          Data.employee_start_date &&
                          Data.employee_start_date.split("-") &&
                          Data.employee_start_date.split("-")[1]
                        }
                        ref={register({ required: true })}
                        disabled={true}
                      />
                      <input
                        type={"text"}
                        name="employee_start_date"
                        className={"profileInput"}
                        onChange={(value) => change(value)}
                        value={
                          Data &&
                          Data.employee_start_date &&
                          Data.employee_start_date.split("-") &&
                          Data.employee_start_date.split("-")[2]
                        }
                        ref={register({ required: true })}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <InputWrapper label={t("account.status") + ":"}>
                    <input
                      name="employee_status"
                      onChange={(value) => change(value)}
                      value={
                        Data && Data.employee_status ? Data.employee_status : ""
                      }
                      ref={register({ required: true })}
                      disabled={true}
                      placeholder="Status"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.role") + ":"}>
                    <input
                      name="role"
                      onChange={(value) => change(value)}
                      value={Data && Data.role ? Data.role : ""}
                      ref={register({ required: true })}
                      disabled={true}
                      autoComplete="role"
                      placeholder="Role"
                    />
                  </InputWrapper>
                  <InputWrapper label={t("account.driverCard") + ":"}>
                    <input
                      name="driver_card_number"
                      onChange={(value) => change(value)}
                      value={
                        Data && Data.driver_card_number
                          ? Data.driver_card_number
                          : ""
                      }
                      ref={register({ required: true })}
                      disabled={true}
                      placeholder="---- ---- --"
                    />
                  </InputWrapper>
                </div>
              </div>
              <div className={"col-12 mt-2 profileColRow"}>
                <div className="profileSideColTitle">
                  {t("account.Leave")} :
                </div>
                <div className={"d-flex"}>
                  <div className={"inputColTitle"}>
                    <span>{t("account.remaining")}</span>
                    <input
                      type={"text"}
                      name=""
                      className={"profileInput"}
                      onChange={(value) => change(value)}
                      value={
                        Data &&
                        Data.leave_status &&
                        Data.leave_status.total &&
                        Data.leave_status.total -
                          (Data.leave_status.unpaid +
                            Data.leave_status.paid +
                            Data.leave_status.special +
                            Data.leave_status.sick)
                      }
                      ref={register({ required: true })}
                      disabled={true}
                    />
                  </div>
                  <div className={"inputColTitle"}>
                    <span>{t("account.paid")}</span>
                    <input
                      type={"text"}
                      name=""
                      className={"profileInput"}
                      onChange={(value) => change(value)}
                      value={
                        Data && Data.leave_status && Data.leave_status.total
                      }
                      ref={register({ required: true })}
                      disabled={true}
                    />
                  </div>
                  <div className={"inputColTitle"}>
                    <span>{t("account.unpaid")}</span>
                    <input
                      type={"text"}
                      name=""
                      className={"profileInput"}
                      onChange={(value) => change(value)}
                      value={
                        Data && Data.leave_status && Data.leave_status.unpaid
                      }
                      ref={register({ required: true })}
                      disabled={true}
                    />
                  </div>
                  <div className={"inputColTitle"}>
                    <span>{t("account.special")}</span>
                    <input
                      type={"text"}
                      name=""
                      className={"profileInput"}
                      onChange={(value) => change(value)}
                      value={
                        Data && Data.leave_status && Data.leave_status.special
                      }
                      ref={register({ required: true })}
                      disabled={true}
                    />
                  </div>
                  <div className={"inputColTitle"}>
                    <span>{t("account.sick")}</span>
                    <input
                      type={"text"}
                      name=""
                      className={"profileInput"}
                      onChange={(value) => change(value)}
                      value={
                        Data && Data.leave_status && Data.leave_status.sick
                      }
                      ref={register({ required: true })}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className={"d-flex mt-2 profileColRow"}>
                <div className={"col-6 mt-2 profileColRow"}>
                  <div className="profileSideColTitle">
                    {" "}
                    {t("account.accidents")} :
                  </div>
                  <div className={"d-flex"}>
                    <div className={"inputColTitle"}>
                      <span>{t("account.accidents")}</span>
                      <input
                        type={"text"}
                        name="role"
                        className={"profileInput"}
                        //onChange={(value) => change(value)}
                        //value={Data && Data.role ? Data.role : ""}
                        ref={register({ required: true })}
                        disabled={true}
                        autoComplete="role"
                        placeholder="10"
                      />
                    </div>
                    <div className={"inputColTitle"}>
                      <span>{t("account.damages")}</span>
                      <input
                        type={"text"}
                        name="role"
                        className={"profileInput"}
                        //onChange={(value) => change(value)}
                        //value={Data && Data.role ? Data.role : ""}
                        ref={register({ required: true })}
                        disabled={true}
                        autoComplete="role"
                        placeholder="10"
                      />
                    </div>
                  </div>
                </div>
                <div className={"col-6 mt-2 profileColRow"}>
                  <div className="profileSideColTitle">
                    {t("account.disposition")} :
                  </div>
                  <div className={"d-flex"}>
                    <div className={"inputColTitle"}>
                      <span>{t("account.level")}</span>
                      <input
                        type={"text"}
                        name="role"
                        className={"profileInput"}
                        //onChange={(value) => change(value)}
                        //value={Data && Data.role ? Data.role : ""}
                        ref={register({ required: true })}
                        disabled={true}
                        autoComplete="role"
                        placeholder="10"
                      />
                    </div>
                    <div className={"inputColTitle"}>
                      <span>{t("account.attention")}</span>
                      <input
                        type={"text"}
                        name="role"
                        className={"profileInput"}
                        //onChange={(value) => change(value)}
                        //value={Data && Data.role ? Data.role : ""}
                        ref={register({ required: true })}
                        disabled={true}
                        autoComplete="role"
                        placeholder="10"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <LoadingButton
              className={"updateBtn"}
              loading={BtnLoading}
              onClick={() => {
                SaveRequest(Data);
              }}
              text={t("account.Update")}
              icon={"/assets/icon-basic-refresh.svg"}
            />
          </div>
        </div>
      </div>

      {/*<AccountModal*/}
      {/*    data={Data}*/}
      {/*    ShippingAddress={ShippingAddress}*/}
      {/*    CompanyData={Company}*/}
      {/*    PaymentMethod={PaymentMethod}*/}
      {/*    imageUrl={Data.picture ? Data.picture : undefined}*/}
      {/*    save={(data) => {*/}
      {/*        SaveRequest(data);*/}
      {/*    }}*/}
      {/*    SaveShipping={(data) => {*/}
      {/*        SaveShipping(data);*/}
      {/*    }}*/}
      {/*    SaveCompany={(data) => {*/}
      {/*        SaveCompany(data);*/}
      {/*    }}*/}
      {/*    SavePayment={(data) => {*/}
      {/*        SavePayment(data);*/}
      {/*    }}*/}
      {/*    Logo={Company.logo}*/}
      {/*    show={Show}*/}
      {/*    modalContent={ModalContent}*/}
      {/*    changeShow={(show) => setShow(show)}*/}
      {/*    changeModalContent={(modalContent) => setModalContent(modalContent)}*/}
      {/*/>*/}
    </PageContainer>
  );
}
