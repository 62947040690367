import * as calendarService from "../services/calendar.service";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const holidays = createAsyncThunk("holidays", async () => {
  return await calendarService.holidays();
});
export const holidaysOpen = createAsyncThunk("holidaysOpen", async () => {
  return await calendarService.holidaysOpen();
});
export const myRequests = createAsyncThunk("myRequests", async () => {
  return await calendarService.myRequests();
});

export const holidayEdit = createAsyncThunk(
  "holidayEdit",
  async (data: any) => {
    return await calendarService.holidayEdit(data);
  },
);
export const createRequest = createAsyncThunk(
  "holidayEdit",
  async (data: any) => {
    return await calendarService.createRequest(data);
  },
);
