import { apiCallAction } from "../../../../redux/actions/dashboard.action";

function keyValue(params: Record<string, any>) {
  let prop: any = "";
  if (params) {
    const keys = Object.keys(params);
    keys.map((key: any, i: number) => {
      const objData = params[key] ? params[key] : "";
      let searchProp = "";
      const seperator = prop === "" ? "?" : "&";
      if (params[key]) {
        searchProp = seperator + key + "=" + objData + "";
      }
      prop += searchProp;
    });
  }
  return prop;
}
export const apiAction = async (
  url: string,
  apiDtailes: Record<string, any>,
  params?: Record<string, any>,
) => {
  let response: any = null;
  const prop = params ? await keyValue(params) : "";
  const final_url = url + "/" + prop;
  const mainUrl = apiDtailes.mainUrl;
  const headers = apiDtailes.headers;
  const method = apiDtailes.method;
  if (!mainUrl) {
    await apiCallAction(final_url).then((res: any) => {
      response = res;
    });
  } else {
    return fetch(mainUrl + final_url, {
      headers: headers,
      method: method ? method : "GET",
    }).then((response) => response.json());
  }

  return response;
};
