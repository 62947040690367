import * as frontService from "../services/front.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorHandler } from "../apiGateway";
export const cantactUs = createAsyncThunk("webcontact", async (data: any) => {
  try {
    return await frontService.cantactUs(data);
  } catch (err) {
    return errorHandler(err);
  }
});
// export const countries = createAsyncThunk("countries", async () => {
//   try {
//     return await userService.countries();
//   } catch (err) {
//     return errorHandler(err);
//   }
// });
