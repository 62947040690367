import * as React from "react";
import { connect, useSelector } from "react-redux";
import Param from "../../redux/Param";
import {
  PageContainer,
  FormHeader,
  ImagePicker,
  FormInformationSection,
  PaymentCard,
  DropDown,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import Breadcrumbs from "../../utilities/components/Breadcrumbs";
import AccountModal from "../../utilities/components/AccountModal";
import { useTranslation, withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { useEffect } from "react";
import {
  paymentMethods,
  paymentMethodEdit,
  paymentMethodCreate,
  countries,
  shippingAddress,
  shippingAddressEdit,
  invoices,
} from "../../redux/actions/user.action";
import { useDispatch } from "../../redux/store";
import { RootState } from "../../redux/reducers";
import { MasterCard, VisaCard } from "../../Components/svg";
import InputWrapper from "../../Components/InputWrapper/InputWrapper";
import { useForm } from "react-hook-form";
import Moment from "moment";
import Calendar from "react-calendar";

export default function Account() {
  const { register } = useForm();

  const [IssueDateCalendar, setIssueDateCalendar] = useState(false);
  const [IssueDate, setIssueDate] = useState(new Date());

  const dispatch = useDispatch();
  const [CardEdit, setCardEdit] = useState(false);
  const [Error, setError] = useState(false);
  const [BtnLoading, setBtnLoading] = useState(false);
  const [CardError, setCardError] = React.useState(false);
  const [Payment, setPayment] = useState<Record<string, any>>({});
  const [Shipping, setShipping] = useState<Record<string, any>>({});
  const [Card, setCard] = useState<Record<string, any>>({});
  const [Invoices, setInvoices] = useState<Record<string, any>>([]);
  const user = useSelector((state: RootState) => state.user.profile);
  const token = useSelector((state: RootState) => state.auth.token);
  const [CountriesData, setCountriesData] = React.useState([]);
  function readPayment() {
    dispatch(paymentMethods()).then((response: any) => {
      if (response.payload) {
        if (response.payload.results && response.payload.results[0]) {
          const l = response.payload.results.length;
          setPayment(response.payload.results[l - 1]);
        } else {
          setPayment([]);
        }
      }
    });
  }
  function SavePayment(data: any) {
    //alert("You dont have Active Card");

    if (Payment.id) {
      dispatch(paymentMethodEdit(Payment)).then((response: any) => {
        if (response.payload && !response.payload.error) {
          setPayment(response.payload);
          setCardError(false);
          setCardEdit(false);
        } else {
          setCardError(response.payload.errorMassage);
          readPayment();
        }
      });
    } else {
      dispatch(paymentMethodCreate(Payment)).then((response: any) => {
        if (response.payload && !response.payload.error) {
          setPayment(response.payload);
          setCardError(false);
          setCardEdit(false);
        } else {
          setCardError(response.payload.errorMassage);
          readPayment();
        }
      });
    }
  }
  const [t] = useTranslation();

  function Save(data: any) {
    setBtnLoading(true);
    dispatch(shippingAddressEdit(data)).then((response: any) => {
      setBtnLoading(false);

      if (response.payload && !response.payload.error) {
        setShipping(response.payload);
        toast.success(t("account.saved"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        setError(response.payload.errorMassage);
      }
    });
  }

  function read() {
    readPayment();
    dispatch(countries()).then((response: any) => {
      if (response.payload) {
        setCountriesData(response.payload.results);
      }
    });
    dispatch(invoices()).then((response: any) => {
      if (response.payload) {
        setInvoices(response.payload.results);
      }
    });
    dispatch(shippingAddress()).then((response: any) => {
      if (response.payload) {
        setShipping(response.payload);
      }
    });
  }

  function paymentInputValidator() {
    if (Payment && Payment.card_number) {
      if (
        Payment.card_number.match("^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$") !=
        null
      ) {
        //changeShow(false);
        SavePayment(Payment);
      } else {
        setCardError(true);
      }
    } else {
      //changeShow(false);
    }
  }

  function Change(event: any) {
    const name = event.target.name;
    const value = event.target.value;
    if (Shipping !== undefined) {
      setShipping({
        ...Shipping,
        [name]: value,
      });
    }
  }
  function ChangeCard(event: any) {
    const name = event.target.name;
    const value = event.target.value;
    setPayment({
      ...Payment,
      [name]: value,
    });
  }

  useEffect(() => {
    read();
  }, []);

  return (
    <PageContainer>
      <ToastContainer />

      <FormHeader text={t("account.billing")} />
      <div className={"ErrorMassage"}>{Error}</div>
      {CardEdit && (
        <div className={"popupBg"}>
          <div className={"popup-content popup-content-language"}>
            <div className="account-modal-close d-flex align-items-center justify-content-center">
              <button
                onClick={() => {
                  setCardEdit(false);
                }}
              >
                <img src={"/assets/closeCircle.svg"} alt="close-icon" />
              </button>
            </div>
            <div className={"title"}>{t("account.Update_Card")}</div>
            <div className={"ErrorMassage"}>{CardError}</div>

            <InputWrapper label={t("account.name_on_card") + ":"} important>
              <input
                type={"text"}
                name="card_holder"
                onChange={(value) => ChangeCard(value)}
                value={Payment && Payment.card_holder}
                ref={register({ required: true })}
                placeholder="Name on Card"
              />
            </InputWrapper>
            <InputWrapper label={t("account.card_number") + ":"} important>
              <input
                type={"text"}
                name="card_number"
                onChange={(e) => {
                  ChangeCard(e);
                  // const re = /[0-9]+$/;
                  // var number = e.target.value;
                  // const numberLength = number.length ;
                  // if((numberLength / 5) == 1 || (numberLength / 5) == 2 || (numberLength / 5) == 3 ){
                  //      number = number + " "
                  //   }

                  // if(re.test(e.target.value.replaceAll(" ",""))){
                  //   setPayment({
                  //     ...Payment,
                  //     "card_number": number,
                  //   });
                  // }
                }}
                value={Payment && Payment.card_number}
                ref={register({ required: true })}
                pattern={"^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$"}
                autoComplete="cardName"
                placeholder="Name on Card"
              />
            </InputWrapper>
            <div className={"d-flex mt-2"}>
              <div className={"inputColTitle"}>
                <span>{t("account.expire_at") + ": *"}</span>
                <div
                  className="divInput"
                  onClick={() => {
                    setIssueDateCalendar(true);
                  }}
                >
                  {Moment(IssueDate).format("YYYY-MM-DD")}
                  <div className={"DatePickerLic"}>
                    {IssueDateCalendar && (
                      <Calendar
                        onChange={(data: any) => {
                          setIssueDateCalendar(false);
                          setPayment({
                            ...Payment,
                            expire_at: Moment(data).format("YYYY-MM-DD"),
                          });
                          setIssueDate(data);
                        }}
                        value={IssueDate}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={"inputColTitle"}>
                <span>{t("account.CVV") + ":"}</span>
                <input
                  type={"text"}
                  name="cvv_code"
                  className={"profileInput"}
                  onChange={(value) => ChangeCard(value)}
                  value={Payment && Payment.cvv_code}
                  ref={register({ required: true })}
                />
              </div>
            </div>
            <div
              className={"add"}
              onClick={() => {
                SavePayment(Card);
              }}
            >
              <img src={"/assets/icon-basic-refresh.svg"} />
              {t("account.Update")}
            </div>
          </div>
        </div>
      )}
      <div className="profile-fa pt-4 col-10">
        <div className="modal-content-section d-flex flex-column">
          <div className="modal-account-account-detail d-flex">
            <div className="col-6">
              <div className="col-12 mb-5">
                <div className="form-information-section-header col-12 d-flex align-items-center justify-content-between">
                  <small>{t("account.payment_details")}</small>
                  <button
                    onClick={() => {
                      setCardEdit(true);
                    }}
                  >
                    <img
                      src={"/assets/images/settings/Task@2x.png"}
                      alt="edit-logo"
                    />
                  </button>
                </div>
                <div className={"d-flex justify-content-center"}>
                  <MasterCard data={Payment} />
                </div>
              </div>
              <div className="col-12  mb-5">
                <div className="form-information-section-header col-12 d-flex align-items-center justify-content-between">
                  <small>{t("account.Billing_History")}</small>
                </div>
                <div className={"billingHistory customScrollBar"}>
                  {Invoices && !Invoices.length && (
                    <span className="noBills">{t("account.no_bills")}</span>
                  )}
                  {Invoices &&
                    Invoices.map((Invoice: any, key: any) => (
                      <div key={key} className={"billingHistoryItem"}>
                        <img src={"/assets/icon-basic-bill.svg"} />
                        <span>{Invoice.due_on}</span>
                        <div className={"billingHistoryItemInfo"}>
                          <strong>€{Invoice.price}</strong>
                          {Invoice.pdf.length ? (
                            <a
                              href={Invoice.pdf}
                              target="_blank"
                              className="ItemStatus"
                              rel="noreferrer"
                            >
                              {Invoice.status}
                            </a>
                          ) : (
                            <div className="ItemStatus">{Invoice.status}</div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-information-section-header col-12 d-flex align-items-center justify-content-between">
                <small>{t("account.billing_address")}</small>
              </div>
              <div className={"pl-3 pr-3"}>
                <InputWrapper label={t("account.address") + ":"} important>
                  <input
                    name="address"
                    onChange={(value) => Change(value)}
                    ref={register({ required: true })}
                    value={Shipping && Shipping.address}
                    placeholder="Address"
                  />
                </InputWrapper>
                <InputWrapper label={t("account.additional") + ":"} important>
                  <input
                    name="address_supplement"
                    onChange={(value) => Change(value)}
                    ref={register({ required: true })}
                    value={Shipping && Shipping.address_supplement}
                    placeholder="C/O Factory Berlin GmbH"
                  />
                </InputWrapper>
                <InputWrapper label={t("account.city") + ":"} important>
                  <input
                    name="city"
                    onChange={(value) => Change(value)}
                    ref={register({ required: true })}
                    value={Shipping && Shipping.city}
                    placeholder={t("account.city")}
                  />
                </InputWrapper>
                <InputWrapper label={t("account.state") + ":"} important>
                  <input
                    name="state"
                    onChange={(value) => Change(value)}
                    ref={register({ required: true })}
                    value={Shipping && Shipping.state}
                    placeholder={t("account.state")}
                  />
                </InputWrapper>
                <InputWrapper label={t("account.postal_code") + ":"} important>
                  <input
                    name="zip_code"
                    onChange={(value) => Change(value)}
                    ref={register({ required: true })}
                    value={Shipping && Shipping.zip_code}
                    placeholder={t("account.postal_code")}
                  />
                </InputWrapper>
                <InputWrapper label={t("account.country") + ":"} important>
                  <DropDown
                    selectedItem={{
                      name: Shipping
                        ? Shipping.country_name
                          ? Shipping.country_name
                          : ""
                        : "",
                    }}
                    textEntity={"name"}
                    preText={""}
                    items={CountriesData}
                    pngSource={"/assets/images/header/Language@2x.png"}
                    placeholder={t("account.country")}
                    onChange={(selected: any) => {
                      setShipping({
                        ...Shipping,
                        country: selected.id ? selected.id : null,
                        country_name: selected.name,
                      });
                    }}
                  />
                </InputWrapper>
              </div>
            </div>
          </div>
          <div>
            <LoadingButton
              className={"updateBtn"}
              loading={BtnLoading}
              onClick={() => {
                Save(Shipping);
              }}
              text={t("account.Update")}
              icon={"/assets/icon-basic-refresh.svg"}
            />
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
