import * as React from "react";
import history from "../../redux/history";
import { createBrowserHistory } from "history";
import { useState, useRef, useEffect } from "react";
import { PageContainer } from "./FromUtils";
import InviteTeammates from "./InviteTeammates";
import { company, inviteLink } from "../../redux/actions/user.action";
import { useDispatch } from "../../redux/store";
import { History } from "history";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import store from "./../../redux/store";
import Param from "../../redux/Param";
import { setMenu, menuState } from "../../redux/actions/menu.action";
interface SideBarProps {
  setSideMenuItems: (data: any) => void | undefined;
  setSideState: (data: any) => void | undefined;
  sideMenuItems: any;
  sideState: any;
}
export default function SideBar({ sideState, sideMenuItems }: SideBarProps) {
  const [Link, setLink] = useState("");
  const [t] = useTranslation();
  const [InviteTeammatesShow, setInviteTeammatesShow] = React.useState(false);
  const user = useSelector((state: RootState) => state.user.profile);
  const menuOpen = useSelector((state: RootState) => state.menu.open);
  const dispatch = useDispatch();

  const [MenuIsOpen, setMenuIsOpen] = useState(sideState);
  const [SideMenuItems, setSideMenuItems] = useState(sideMenuItems);
  const [SideState, setSideState] = useState(sideMenuItems);
  const location = useLocation();
  const history = useHistory();
  const mounted = useRef();
  useEffect(() => {
    // if (!mounted.current) {
    //   // componentDidMount
    //   // mounted.current = true;
    // } else {
    //  componentDidUpdate
    if (SideMenuItems !== SideMenuItems) {
      setSideMenuItems(sideMenuItems);
    }

    if (sideState !== SideState) {
      setSideState(sideState);
      setMenuIsOpen(sideState);
    }
    // }
  });

  function updateDimensions() {
    if (window.innerWidth < 800 && SideState !== false) {
      setSideState(false);
    } else if (window.innerWidth > 800 && SideState !== true) {
      setSideState(true);
    }
  }
  useEffect(() => {
    updateDimensions();
    console.log("updateDimensions", sideState);
    dispatch(setMenu(sideState));
    dispatch(menuState(!sideState));
  }, [sideState]);

  function toggleMenus(index: any) {
    let i = 0;
    let j = 0;
    const items = [...sideMenuItems];
    for (i = 0; i < items.length; i++) {
      if (i !== index) {
        items[i].value = false;
        if (items[i].child.length > 0) {
          for (j = 0; j < items[i].child.length; j++) {
            items[i].child[j].value = false;
          }
        }
      } else {
        items[i].value = true;
      }
    }

    setSideMenuItems(items);
  }

  function toggleSubMenus(jIndex: any, index: any) {
    let i = 0;

    const items = SideMenuItems;
    for (i = 0; i < items[index].child.length; i++) {
      if (i !== jIndex) {
        items[index].child[i].value = false;
      } else {
        items[index].child[i].value = true;
      }
    }
    setSideMenuItems(items);
  }

  function pushWrapper(page: any, index: any, length: any) {
    if (page) {
      if (length === 0) {
        if (window.innerWidth < 800 && SideState === true) {
          setSideState(false);
        }
        history.push(page);
      }
    }
    toggleMenus(index);
  }

  function pushSubWrapper(page: any, jIndex: any, index: any) {
    if (page) {
      if (window.innerWidth < 800 && SideState === true) {
        setSideState(false);
      }
      history.push(page);
    }
    toggleSubMenus(jIndex, index);
  }

  function setMenuState(action: any) {
    if (!SideState) {
      dispatch(setMenu(action));
      if (action) {
        setMenuIsOpen(true);
      } else {
        setMenuIsOpen(false);
      }
    }
  }
  // useEffect(()=>{
  //   dispatch(setMenu(SideState))
  // },[MenuIsOpen])
  return (
    <nav
      className={MenuIsOpen ? "nav-container" : "nav-container-mobile"}
      onMouseLeave={() => MenuIsOpen && setMenuState(false)}
      onMouseOver={() => !MenuIsOpen && setMenuState(true)}
    >
      <div
        className={
          MenuIsOpen ? "menuParent menuDesktop" : "menuParent menuMobile"
        }
      >
        <div className={"menuContainer"}>
          <div className="side-header">
            {MenuIsOpen && (
              <img
                src={"/assets/images/SideBars/FS_logo.svg"}
                alt="fleetspark-logo"
              />
            )}
            {!MenuIsOpen && (
              <img
                className="side-header-logo"
                src={"/assets/images/logo/Ficonx2.png"}
                alt="fleetspark-logo"
              />
            )}
          </div>
          <ul className="side-items-container">
            {SideMenuItems.map(
              (item: any, index: any) =>
                !item.disable && (
                  <ul key={index}>
                    <li
                      onClick={() => {
                        if (!item.value) {
                          pushWrapper(item.page, index, item.child.length);
                        } else {
                          pushWrapper("", "", "");
                        }
                      }}
                      className="side-items-first-element d-flex align-items-center"
                      style={{
                        paddingLeft: !MenuIsOpen ? 15 : 20,
                        backgroundColor: item.value ? "#f8fafb" : "",
                      }}
                    >
                      {item.page === location.pathname && (
                        <div className="side-item-active-left" />
                      )}
                      {item.url === location.pathname.split("/")[1] && (
                        <div className="side-item-active-left" />
                      )}

                      <img
                        style={{
                          width: MenuIsOpen ? 25 : 35,
                          height: MenuIsOpen ? 25 : 35,
                        }}
                        src={item.icon}
                        alt="fleetspark-logo"
                      />
                      <small
                        style={{ display: !MenuIsOpen ? "none" : "unset" }}
                      >
                        {t(item.title)}
                      </small>

                      {item.notifCount !== 0 && (
                        <div
                          className="badge-side-items"
                          style={{ display: !MenuIsOpen ? "none" : "unset" }}
                        >
                          {item.notifCount}
                        </div>
                      )}
                      {!item.value && item.child.length > 0 && (
                        <img
                          className="sidemenu-expand-icon"
                          style={{ display: !MenuIsOpen ? "none" : "unset" }}
                          src={"/assets/images/sideMenu/Arrow@2x.png"}
                          alt="fleetspark-logo"
                        />
                      )}
                      {item.value && item.child.length > 0 && (
                        <img
                          className="sidemenu-expand-icon"
                          style={{ display: !MenuIsOpen ? "none" : "unset" }}
                          src={"/assets/images/sideMenu/ArrowDown@2x.png"}
                          alt="fleetspark-logo"
                        />
                      )}
                    </li>

                    {item.value &&
                      item.child.map(
                        (child: any, indexJ: any) =>
                          !child.disable && (
                            <li
                              onClick={() => {
                                if (!child.value) {
                                  pushSubWrapper(child.page, indexJ, index);
                                }
                              }}
                              key={indexJ}
                              className="side-items-second-element d-flex align-items-center"
                              style={{
                                backgroundColor: "#f8fafb",
                                height: !MenuIsOpen ? 0 : 20,
                                padding: !MenuIsOpen ? 0 : 20,
                              }}
                            >
                              <div
                                className={
                                  child.value
                                    ? "bullet-active"
                                    : "bullet-de-active"
                                }
                              />
                              <small
                                style={{
                                  display: !MenuIsOpen ? "none" : "unset",
                                  color: !child.value ? "#8697A8" : "#44566c",
                                }}
                              >
                                {t(child.title)}
                              </small>
                            </li>
                          ),
                      )}
                  </ul>
                ),
            )}
          </ul>

          {user.role === "MANAGER" && (
            <div
              className={
                !MenuIsOpen ? "inviteLink inviteLinkMobile" : "inviteLink "
              }
              onClick={() => {
                setInviteTeammatesShow(!InviteTeammatesShow);
              }}
            >
              <img src={"/assets/icon-basic-add-user.svg"} />
              {!MenuIsOpen ? "" : <span>{t("home.invite_teammates")}</span>}
            </div>
          )}
        </div>
      </div>
      {InviteTeammatesShow && (
        <InviteTeammates close={() => setInviteTeammatesShow(false)} />
      )}
    </nav>
  );
}
