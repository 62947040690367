import React, { useEffect } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch } from "../../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import {
  users,
  driverCreate,
  driverEdit,
  driverDelete,
  usersTag,
} from "../../../redux/actions/table.action";
import Table from "../../../utilities/components/Table/Table";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// interface ChartProps {
//     // pin: () => void | undefined;
//     // remove: () => void | undefined;
//     // changeDate: (Range: object) => void | undefined;
//     // el: Record<string, any>;
// }

const DefaultParam = {
  offset: 0,
  limit: 10,
  search: false,
  ordering: false,
  according: false,
};

export default function Users() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const TableRowsData = [
    {
      name: "email",
      label: t("table.email"),
      type: "email",
      required: true,
      help_text: "",
      editable: false,
    },
    {
      name: "password1",
      label: t("table.password"),
      type: "password",
      required: true,
      help_text: "",
      editable: false,
    },
    {
      name: "password2",
      label: t("table.password_repeat"),
      type: "password",
      required: true,
      help_text: "",
      editable: false,
    },
    {
      name: "first_name",
      label: t("table.first_name"),
      type: "text",
      required: false,
      help_text: "",
      editable: true,
    },
    {
      name: "last_name",
      label: t("table.last_name"),
      type: "text",
      required: false,
      help_text: "",
      editable: true,
    },
    {
      name: "driver_card_number",
      label: t("table.driver_card_number"),
      type: "text",
      required: true,
      help_text: "",
      editable: true,
    },
    {
      name: "role",
      label: t("table.Role"),
      type: "select",
      options: [
        { name: "DRIVER", label: t("invite.driver") },
        { name: "MANAGER", label: t("invite.manager") },
        { name: "OFFICER", label: t("invite.officer") },
      ],
      required: true,
      help_text: "",
      editable: true,
    },
    {
      name: "birth_date",
      label: t("table.birth_date"),
      type: "date",
      required: false,
      help_text: "",
      editable: true,
    },
    {
      name: "employee_location_site",
      label: t("table.employee_location_site"),
      type: "text",
      required: false,
      hiddenCreate: true,
      help_text: "",
      editable: true,
    },
    {
      name: "mobile_number",
      label: t("table.mobile_number"),
      type: "number",
      required: false,
      help_text: "i.e +41524204242",
      editable: true,
    },
    {
      name: "mobile_cc",
      label: t("table.mobile_cc"),
      type: "number",
      required: false,
      help_text: "",
      min_value: -2147483648,
      max_value: 2147483647,
      editable: false,
    },
    {
      name: "address",
      label: t("table.address"),
      type: "text",
      required: false,
      help_text: "",
      editable: true,
    },
    {
      name: "picture",
      label: t("table.picture"),
      type: "file",
      required: false,
      help_text: "",
      editable: false,
    },
    {
      name: "obj_groups",
      label: t("table.groups"),
      type: "array",
      required: false,
      help_text: "",
      editable: true,
    },
  ];
  const [Result, setResult] = React.useState<any>(null);
  const [DefaultParams, setDefaultParams] = React.useState<any>(DefaultParam);
  const tableData = useSelector((state: RootState) => state.table.users);
  useEffect(() => {
    //dispatch(drivers(DefaultParams));
  }, []);
  useEffect(() => {
    if (Result) {
      dispatch(users(DefaultParams));
    }
  }, [Result]);

  function request(Params: any) {
    if (
      DefaultParams.offset !== Params.offset ||
      DefaultParams.limit !== Params.limit ||
      DefaultParams.search !== Params.search ||
      DefaultParams.ordering !== Params.ordering ||
      DefaultParams.according !== Params.according
    ) {
      setDefaultParams(Params);
      dispatch(users(Params));
    }
  }

  function create(data: any) {
    dispatch(driverCreate(data)).then((res) => {
      if (res) {
        setResult(res);
      }
    });
  }

  function edit(data: any) {
    dispatch(driverEdit(data)).then((res) => {
      if (res) {
        setResult(res);
      }
    });
  }

  function deleteRow(data: any) {
    dispatch(driverDelete(data)).then((res) => {
      if (!res.payload.error) {
        setResult({ delete: true });
      } else {
        setResult(res);
      }
    });
  }
  function TagSave(ids: any, group_name: any) {
    dispatch(usersTag({ ids, group_name })).then((res) => {
      if (!res.payload.error) {
        setResult(true);
        const title = t("account.saved");
        toast.success(title, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        // setResult(res);
      }
    });
  }

  return (
    <div>
      <Table
        getData={(Params: any) => {
          request(Params);
        }}
        CreateItem={(data) => create(data)}
        EditItem={(data) => edit(data)}
        ActionResult={Result}
        clearResult={() => {
          setResult(null);
        }}
        TableRowsData={TableRowsData}
        tableData={tableData}
        editable={true}
        creatable={true}
        DeleteRow={(data) => deleteRow(data)}
        TagSave={(ids, group_name) => {
          TagSave(ids, group_name);
        }}
        tableType={t("menu.users")}
      />
    </div>
  );
}
