import * as React from "react";
import { useImmer } from "use-immer";
import { Link, useParams } from "react-router-dom";
import {
  InputWithIcon,
  CheckboxWithTitle,
  LoadingButton,
  DropDown,
} from "../../utilities/components/FromUtils";

import {
  signIn,
  mobileSms,
  inviteAccept,
} from "../../redux/actions/auth.action";
import { countries, profileEdit } from "../../redux/actions/user.action";
import { profile } from "../../redux/actions/user.action";
import { MobileValidator } from "../../utilities/functions/authValidator";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";

export default function Invite({ history }: any) {
  const prop: any = useParams();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [state, setState] = useImmer({
    phone_number: "",
    code: "",
    message_id: "",
    valid: "",
  });
  const Token = useSelector((state: RootState) => state.auth.token);
  const [CountriesData, setCountriesData] = React.useState([]);
  const [Country, setCountry] = React.useState({
    id: 59,
    name: "Germany",
    alpha2_code: "DE",
    alpha3_code: "DEU",
    flag: "https://fs-rest-static.s3.amazonaws.com/static/flags/de.png",
    country_code: 49,
  });
  const [ShowCode, setShowCode] = useState(false);
  const [Resend, setResend] = useState(false);

  const validate = (data: any) => {
    setState((state) => {
      state.valid = "";
    });
    if (MobileValidator(data)) {
      return true;
    } else {
      setState((state) => {
        state.valid = t("auth.Verification_email");
      });
      return false;
    }
  };
  useEffect(() => {
    dispatch(countries()).then((response: any) => {
      if (response.payload) {
        setCountriesData(response.payload.results);
      }
    });
  }, []);

  const VerifyMobile = () => {
    const data = {
      phone_number: "+" + Country.country_code + state.phone_number,
    };
    if (validate(data)) {
      dispatch(mobileSms(data)).then((response: any) => {
        if (response.payload && response.payload.statusCode === "200") {
          setState((state) => {
            state.phone_number = response.payload.body.phone_number;
            state.message_id = response.payload.body.message_id;
          });
          setShowCode(true);
        } else {
          history.push("/dashboard");

          setState((state) => {
            state.valid = t("auth.Verification_email");
          });
        }
      });
    }
  };
  const VerifyCode = () => {
    const data = {
      code: state.code,
      phone_number: state.phone_number,
      message_id: state.message_id,
    };
    setResend(false);

    if (validate(data)) {
      const mobile = "+" + Country.country_code + state.phone_number;
      dispatch(mobileSms(data)).then((response: any) => {
        if (response.payload && response.payload.statusCode === "200") {
          dispatch(
            profileEdit({
              data: { mobile_number: state.phone_number },
              token: Token,
            }),
          ).then((response: any) => {
            history.push("/dashboard");

            // if (!response.error) {
            //
            // } else {
            //   setState((state) => {
            //     state.valid =
            //       "we cant save your phone number right now , please try again";
            //   });
            // }
          });
        } else {
          setState((state) => {
            state.valid = t("auth.code_not_valid");
          });
        }
      });
    }
  };

  function resend() {
    const data = {
      phone_number: state.phone_number,
    };
    if (validate(data)) {
      dispatch(mobileSms(data)).then((response: any) => {
        if (response.payload && response.payload.statusCode === "200") {
          setState((state) => {
            state.phone_number = response.payload.body.phone_number;
            state.message_id = response.payload.body.message_id;
          });
          setShowCode(true);
          setResend(true);
        } else {
          setState((state) => {
            state.valid = t("auth.credentials_not_valid");
          });
        }
      });
    }
  }

  return (
    <div className="main-container d-flex">
      <div className="login-form-section full-section d-flex justify-content-center align-items-center fadeIn">
        <div className="login-form-inputs-container d-flex justify-content-center flex-column">
          <div className="login-form-inputs-header">
            <img
              className="login-form-inputs-header-img"
              src={"/assets/images/logo/Logox2.png"}
              alt="fleetspark-logo"
            />
          </div>

          <div className={"mobileInputContainer"}>
            {ShowCode && (
              <>
                <div className="signInText text-center ">
                  {t("auth.Confirmation")}
                </div>
                {Resend && (
                  <span className={"smallHint"}>{t("auth.resent_code")}</span>
                )}
                <InputWithIcon
                  value={state.code}
                  placeholder={t("auth.Code")}
                  type={"number"}
                  pngSource={"/assets/images/auth/Profile2x.png"}
                  onChange={(Code: string) =>
                    setState((state) => {
                      state.code = Code;
                    })
                  }
                />

                <div className="login-button">
                  <LoadingButton
                    onClick={() => VerifyCode()}
                    text={t("home.verifyMobile")}
                  />
                </div>
                <small className="login-form-switch-part body-2-text">
                  {t("home.dont-get-code")}
                  <span
                    onClick={() => {
                      resend();
                    }}
                    className="deep-sky-blue-text"
                  >
                    {t("home.resend_code")}
                  </span>
                </small>
              </>
            )}
            {!ShowCode && (
              <>
                <div className="signInText  text-center ">
                  {t("auth.Verify_Mobile")}
                </div>
                <span className={"smallHint"}>
                  {t("auth.enter_mobile_message")}
                </span>
                <div className={"mobileNumberInputFa"}>
                  <div className={"mobileNumberInputDropDown"}>
                    <DropDown
                      selectedItem={{
                        name: "+" + Country.country_code,
                        flag: Country.flag,
                      }}
                      textEntity={"name"}
                      preText={""}
                      items={CountriesData}
                      pngSource={"/assets/images/header/Language@2x.png"}
                      placeholder="Select Card Type"
                      onChange={(selected: any) => {
                        setCountry(selected);
                      }}
                    />
                  </div>
                  <input
                    className={"mobileNumberInput"}
                    value={state.phone_number}
                    placeholder="Phone Number"
                    type={"text"}
                    onChange={(e: any) =>
                      setState((state) => {
                        state.phone_number = e.target.value;
                      })
                    }
                  />
                </div>
                <div className="login-button">
                  <LoadingButton
                    onClick={() => VerifyMobile()}
                    text={t("home.verifyMobile")}
                  />
                </div>
              </>
            )}
          </div>
          {state.valid && (
            <div style={{ width: "200px", display: "block" }}>
              <span style={{ color: "red", fontSize: "11px" }}>
                {state.valid}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
