import * as authService from "../services/auth.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { errorHandler } from "../apiGateway";

// First, create the thunk
export const signIn = createAsyncThunk(
  "auth/signIn",

  async (data: IloginInput) => {
    try {
      return await authService.signIn(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
//
export const VerifyEmail = createAsyncThunk(
  "VerifyEmail",
  async (data: any) => {
    try {
      return await authService.VerifyEmail(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const VerifyEmailResend = createAsyncThunk(
  "VerifyEmail",
  async (data: any) => {
    try {
      return await authService.VerifyEmailResend(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const logoutAction = createAsyncThunk("auth/logout", async () => {
  try {
    return await authService.logout();
  } catch (err) {
    return errorHandler(err);
  }
});
export const inviteVerify = createAsyncThunk(
  "inviteVerify",
  async (data: any) => {
    try {
      return await authService.inviteVerify(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const inviteAccept = createAsyncThunk(
  "inviteAccept",
  async (data: any, key: any) => {
    try {
      return await authService.inviteAccept(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const signUp = createAsyncThunk("auth/signUp", async (data: any) => {
  try {
    return await authService.signUp(data);
  } catch (err) {
    return errorHandler(err);
  }
});
export const ResetConfirm = createAsyncThunk(
  "auth/password/reset/confirm/",
  async (data: any) => {
    try {
      return await authService.ResetConfirm(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data: any) => {
    try {
      return await authService.resetPassword(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const profileAction = createAsyncThunk("auth/profile", async () => {
  try {
    return await authService.profile();
  } catch (err) {
    return errorHandler(err);
  }
});

export const mobileSms = createAsyncThunk("auth/profile", async (data: any) => {
  try {
    return await authService.mobileSms(data);
  } catch (err) {
    return errorHandler(err);
  }
});
