import { createSlice } from "@reduxjs/toolkit";
import {
  drivers,
  vehicles,
  users,
  trailers,
  canDevices,
} from "../actions/table.action";

const initialState: Record<string, any> = {
  drivers: null,
  users: null,
  vehicles: null,
  trailers: null,
  canDevices: null,
};
const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(drivers.fulfilled, (state, { payload }) => {
      state.drivers = payload;
    });
    builder.addCase(users.fulfilled, (state, { payload }) => {
      state.users = payload;
    });
    builder.addCase(vehicles.fulfilled, (state, { payload }) => {
      state.vehicles = payload;
    });
    builder.addCase(trailers.fulfilled, (state, { payload }) => {
      state.trailers = payload;
    });
    builder.addCase(canDevices.fulfilled, (state, { payload }) => {
      state.canDevices = payload;
    });
  },
});

export default tableSlice;
