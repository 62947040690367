import * as dashboardService from "../services/dashboard.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
// First, create the thunk

export const vehicleStatus = createAsyncThunk(
  "dashboard/vehicleStatus",
  async () => {
    return await dashboardService.getVehicleStatus();
  },
);
export const groups = createAsyncThunk("dashboard/groups", async () => {
  return await dashboardService.groups();
});

export const userBreaksDaily = createAsyncThunk(
  "dashboard/userBreaksDaily",
  async ({ start_date }: { start_date: string }) => {
    return await dashboardService.getUsersBreaksDaily({
      start_date,
    });
  },
);

export const mapTrendCC = createAsyncThunk(
  "dashboard/users/cc/daily/map-trend",
  async ({
    start_date,
    imei,
    company_id,
  }: {
    start_date: any;
    imei: any;
    company_id: any;
  }) => {
    return await dashboardService.mapTrendCC({
      start_date,
      imei,
      company_id,
    });
  },
);

export const userIdlingDaily = createAsyncThunk(
  "dashboard/userIdlingDaily",
  async ({
    start_date,
    end_date,
  }: {
    start_date: string;
    end_date: string;
  }) => {
    return await dashboardService.getUsersIdlingDaily({
      start_date,
      end_date,
    });
  },
);

export const userIdlingWeekly = createAsyncThunk(
  "dashboard/users/idling",
  async ({ start_date, params }: { start_date: string; params?: any }) => {
    return await dashboardService.getUsersIdlingWeekly({
      start_date,
      params,
    });
  },
);
export const companiesBrakingDaily = createAsyncThunk(
  "dashboard/companies/braking/daily",
  async ({
    start_date,
    end_date,
    params,
  }: {
    start_date: string;
    end_date: string;
    params?: any;
  }) => {
    return await dashboardService.getCompaniesBraking({
      start_date,
      end_date,
      params,
    });
  },
);

export const companiesBrakingMonthly = createAsyncThunk(
  "dashboard/companies/braking/monthly",
  async ({
    start_date,
    end_date,
    params,
  }: {
    start_date: string;
    end_date: string;
    params?: any;
  }) => {
    return await dashboardService.getCompaniesBraking({
      start_date,
      end_date,
      params,
    });
  },
);
export const UsersFuelConsumption = createAsyncThunk(
  "dashboard/users/FuelConsumption/monthly",
  async ({
    start_date,
    end_date,
    params,
  }: {
    start_date: string;
    end_date: string;
    params?: any;
  }) => {
    return await dashboardService.getUsersFuelConsumption({
      start_date,
      end_date,
      params,
    });
  },
);
export const getWidgets = createAsyncThunk("dashboard/getWidgets", async () => {
  return await dashboardService.getWidgets();
});

export const widgetsPermissions = createAsyncThunk(
  "dashboard/widgetsPermissions",
  async (data: any) => {
    return await dashboardService.widgetsPermissions(data);
  },
);
export const UsersRankingQuarterly = createAsyncThunk(
  "dashboard/users/Ranking/Quarterly",
  async ({
    start_date,
    end_date,
    params,
  }: {
    start_date: string;
    end_date: string;
    params?: any;
  }) => {
    return await dashboardService.getUsersRankingQuarterly({
      start_date,
      end_date,
      params,
    });
  },
);
export const UsersPointDaily = createAsyncThunk(
  "dashboard/users/point/daily",
  async ({
    start_date,
    end_date,
    params,
  }: {
    start_date: string;
    end_date: string;
    params?: any;
  }) => {
    return await dashboardService.getUsersPoint({
      start_date,
      end_date,
      params,
    });
  },
);
export const userIdlingAvg = createAsyncThunk(
  "dashboard/userIdlingAvg",
  async ({ start_date }: { start_date: string }) => {
    return await dashboardService.getUserIdlingAvg({
      start_date,
    });
  },
);
export const companyIdlingDaily = createAsyncThunk(
  "dashboard/companyIdlingDaily",
  async ({ start_date }: { start_date: string }) => {
    return await dashboardService.getCompanyIdlingDaily({
      start_date,
    });
  },
);
export const companyCCDaily = createAsyncThunk(
  "dashboard/companyCCDaily",
  async ({ start_date, end_date }: Record<string, string>) => {
    return await dashboardService.getCompanyCC({
      start_date,
      end_date,
    });
  },
);
export const companyCCWeekly = createAsyncThunk(
  "dashboard/companyCCWeekly",
  async ({ start_date, end_date }: Record<string, string>) => {
    return await dashboardService.getCompanyCC({
      start_date,
      end_date,
    });
  },
);

export const companyCCMonthly = createAsyncThunk(
  "dashboard/companyCCMonthly",
  async ({ start_date, end_date }: Record<string, string>) => {
    return await dashboardService.getCompanyCC({
      start_date,
      end_date,
    });
  },
);
export const usersCCDaily = createAsyncThunk(
  "dashboard/usersCCDaily",
  async ({ start_date, end_date }: Record<string, string>) => {
    return await dashboardService.getUsersCC({
      start_date,
      end_date,
    });
  },
);
export const usersCCWeekly = createAsyncThunk(
  "dashboard/usersCCWeekly",
  async ({
    start_date,
    end_date,
    params,
  }: {
    start_date: string;
    end_date: string;
    params?: any;
  }) => {
    return await dashboardService.getUsersCC({
      start_date,
      end_date,
      params,
    });
  },
);
export const usersCCMonthly = createAsyncThunk(
  "dashboard/usersCCMonthly",
  async ({ start_date, end_date }: Record<string, string>) => {
    return await dashboardService.getUsersCC({
      start_date,
      end_date,
    });
  },
);
export const TableWidgetCall = createAsyncThunk(
  "dashboard/TableWidgetCall",
  async ({ start_date, end_date, url }: Record<string, string>) => {
    return await dashboardService.TableWidgetCall({
      start_date,
      end_date,
      url: url,
    });
  },
);

export const saveSetting = createAsyncThunk(
  "web/setting",
  async ({ sections, Token }: any) => {
    return await dashboardService.saveSetting({ sections, Token });
  },
);

export const getSetting = createAsyncThunk(
  "web/setting",
  async (Token: any) => {
    return await dashboardService.getSetting(Token);
  },
);
export const dataFixer = async (data: any, type?: any) => {
  if (data.config) {
    const colors = data.config.colors;
    const datasetsSimple: any = {
      label: "",
      fill: false,
      lineTension: 0.1,
      backgroundColor: colors[0],
      borderColor: "rgba(80,158,192,.51)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [],
    };

    const datasets: any = [];
    const res = {
      labels: [],
      datasets: null,
    };

    data.results.columns.map((column: any, columnIndex: any) => {
      if (columnIndex !== 0) {
        const a = { ...datasetsSimple };
        a.label = column;
        datasets.push(a);
      }
    });
    data.results.data.map((item: any, key: any) => {
      item.map((number: never, index: any) => {
        if (index === 0) {
          res.labels.push(number); //date
        } else {
          const asets = [...datasets[index - 1].data];
          if (type === "border") {
            datasets[index - 1].backgroundColor = data.config.colors;
            datasets[index - 1].borderWidth = 3;
            datasets[index - 1].borderColor = "rgba(101,102,105,0.51)";
          } else {
            datasets[index - 1].backgroundColor = data.config.colors[index - 1];
          }
          datasets[index - 1].data = asets;

          asets.push(item[index]);
        }
      });
    });
    console.log("    res.datasets = datasets;", res.datasets);
    res.datasets = datasets;
    return res;
  }
};

export const apiCallAction = async (url: string) => {
  return await dashboardService.apiCallWidget(url);
};
