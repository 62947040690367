import * as React from "react";
import { useImmer } from "use-immer";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  InputWithIcon,
  LoadingButton,
} from "../../utilities/components/FromUtils";

import {
  signIn,
  inviteVerify,
  inviteAccept,
} from "../../redux/actions/auth.action";
import { signUpValidator } from "../../utilities/functions/authValidator";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useEffect, useState } from "react";

export default function Invite({ history, props }: any) {
  const queryString = require("query-string");

  const prop: any = useParams();
  const loc: any = useLocation();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [state, setState] = useImmer({
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    password1: "",
    password2: "",
    permission: true,
    valid: "",
  });
  const [company, setCompany] = useState<Record<any, any>>({});
  const [VerificationEmail, setVerificationEmail] = useState(false);
  useEffect(() => {
    const query: any = queryString.parse(loc && loc.search);

    const data = { key: query.token };

    dispatch(inviteVerify(data)).then((response: any) => {
      if (response.payload && response.payload.company_name) {
        setCompany(response.payload);
      } else {
        history.replace("/");
      }
    });
  }, []);

  const validate = (data: any) => {
    setState((state) => {
      state.valid = "";
    });
    if (signUpValidator(data)) {
      return true;
    } else {
      setState((state) => {
        state.valid = t("auth.credentials_not_valid");
      });
      return false;
    }
  };

  const login = () => {
    const query: any = queryString.parse(loc && loc.search);

    const data = {
      password1: state.password1,
      password2: state.password1,
      first_name: state.firstname,
      last_name: state.lastname,
      email: state.email,
    };
    if (validate(data)) {
      dispatch(inviteAccept({ json: data, key: query.token })).then(
        (response: any) => {
          if (response.payload && response.payload.detail) {
            setVerificationEmail(true);
          } else {
            setState((state) => {
              state.valid = t("auth.credentials_not_valid");
            });
          }
        },
      );
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      login();
    }
  };
  return (
    <div className="main-container d-flex">
      <div className="login-form-section full-section d-flex justify-content-center align-items-center fadeIn">
        <div className="login-form-inputs-container d-flex justify-content-center flex-column">
          <div className="login-form-inputs-header">
            <img
              className="login-form-inputs-header-img"
              src={"/assets/images/logo/Logox2.png"}
              alt="fleetspark-logo"
            />
          </div>

          {/* <small className="signInText  text-center">
            {company.company_name}
          </small> */}
          {VerificationEmail && (
            <>
              <small className="signInText text-center">
                {t("auth.Verification_email")}
              </small>
            </>
          )}
          {!VerificationEmail && (
            <>
              <InputWithIcon
                value={state.firstname}
                placeholder={t("account.first_name")}
                pngSource={"/assets/images/auth/Profile2x.png"}
                onChange={(username: string) =>
                  setState((state) => {
                    state.firstname = username;
                  })
                }
              />
              <InputWithIcon
                value={state.lastname}
                placeholder={t("account.last_name")}
                pngSource={"/assets/images/auth/Profile2x.png"}
                onChange={(username: string) =>
                  setState((state) => {
                    state.lastname = username;
                  })
                }
              />

              <InputWithIcon
                value={state.email}
                placeholder={t("account.email")}
                pngSource={"/assets/images/auth/Profile2x.png"}
                onChange={(username: string) =>
                  setState((state) => {
                    state.email = username;
                  })
                }
              />
              <InputWithIcon
                value={state.password1}
                placeholder={t("home.password")}
                type="password"
                onKeyDown={(event: any) => handleKeyDown(event)}
                pngSource={"/assets/images/auth/Padlock2x.png"}
                onChange={(password: string) =>
                  setState((state) => {
                    state.password1 = password;
                  })
                }
              />

              {state.valid && (
                <div style={{ width: "200px", display: "block" }}>
                  <span style={{ color: "red", fontSize: "11px" }}>
                    {state.valid}
                  </span>
                </div>
              )}
              <div className="login-button">
                <LoadingButton
                  onClick={() => login()}
                  text={t("home.signUp")}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
