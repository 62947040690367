import { rest, restWithOutHook } from "../apiGateway";
import ky from "ky";

export const profile = (token?: string) => {
  return rest
    .get("profile/", {
      headers: { Authorization: `Bearer ` + token },
    })
    .json();
};
export const profileHeader = (token?: string) => {
  return restWithOutHook
    .get("profile/", {
      headers: { Authorization: `Bearer ` + token },
    })
    .json();
};

export const company = (token?: string) => {
  return rest
    .get("company/", {
      headers: { Authorization: `Bearer ` + token },
    })
    .json();
};

export const paymentMethods = () => {
  return rest.get("payment-methods/").json();
};
export const inviteLink = (token?: any) => {
  return rest
    .get("invite/link/", {
      headers: { Authorization: `Bearer ` + token },
    })
    .json();
};
export const countries = () => {
  return rest.get("countries/?limit=300").json();
};
export const shippingAddress = () => {
  return rest.get("shipping-address/").json();
};

export const impersonate = (company_id?: string) => {
  const p = company_id?.length ? "?company=" + company_id : "";
  return rest.get("impersonate/" + p).json();
};
export const passwordChange = (json: any) => {
  return rest.post("auth/password/change/", { json }).json();
};

export const impersonateChange = (id: any) => {
  return rest.post("impersonate/", { json: { id } }).json();
};

export const permissions = () => {
  return rest.get("permissions/").json();
};
export const permissionChange = (company: any, role: any) => {
  return rest.post("permissions/", { json: { company, role } }).json();
};

export const shippingAddressEdit = (json: any) => {
  return rest.put("shipping-address/", { json }).json();
};
export const inviteEmails = (json: any) => {
  return fetch(
    `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/` + "invite/emails/",
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      method: "POST",
      body: JSON.stringify(json),
    },
  ).then((response) => response.json());

  //return rest.post("invite/emails/", { json }).json();
};
export const paymentMethodEdit = (json: any) => {
  return rest.patch("payment-methods/" + json.id + "/", { json }).json();
};
export const paymentMethodCreate = (json: any) => {
  return rest.post("payment-methods/", { json }).json();
};

export const profileEdit = async ({
  data,
  token,
}: {
  data: any;
  token?: any;
}) => {
  const formData = new FormData();

  for (const key in data) {
    if (data[key]) {
      formData.append(key, data[key]);
    }
  }
  return ky
    .patch(`${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/` + "profile/", {
      body: formData,
      headers: {
        Authorization: token
          ? "Bearer " + token
          : `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .json();
};

export const companyEdit = (data: any) => {
  const formData = new FormData();
  for (const key in data) {
    if (data[key]) {
      formData.append(key, data[key]);
    }
  }
  return ky
    .patch(`${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/` + "company/", {
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
    .json();
};

export const driverLicensesAdd = (json: any) => {
  return rest.post("driver-licenses/", { json }).json();
};
export const drivingLicenses = () => {
  return rest.get("driving-licenses/").json();
};
export const driverLicenses = () => {
  return rest.get("driver-licenses/").json();
};
export const driverLicenseDelete = (id: any) => {
  return rest.delete("driver-licenses/" + id + "/").json();
};

export const languages = () => {
  return rest.get("languages/?limit=210").json();
};
export const invoices = () => {
  return rest.get("invoices/").json();
};
export const languageAdd = (json: any) => {
  return rest.post("spoken-languages/", { json }).json();
};
export const languageDelete = (id: any) => {
  return rest.delete("spoken-languages/" + id + "/").json();
};
export const spokenLanguages = () => {
  return rest.get("spoken-languages/").json();
};
