import * as React from "react";
import { useImmer } from "use-immer";
import { Link } from "react-router-dom";
import {
  InputWithIcon,
  CheckboxWithTitle,
  LoadingButton,
} from "../../utilities/components/FromUtils";

import { signIn } from "../../redux/actions/auth.action";
import { profile } from "../../redux/actions/user.action";
import { loginValidator } from "../../utilities/functions/authValidator";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";

export default function Login({ history }: any) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [BtnLoading, setBtnLoading] = React.useState(false);
  const [state, setState] = useImmer({
    username: "",
    password: "",
    rememberMe: false,
    permission: true,
    valid: "",
  });

  const validate = (data: any) => {
    setState((state) => {
      state.valid = "";
    });
    if (loginValidator(data)) {
      return true;
    } else {
      setState((state) => {
        state.valid = t("auth.credentials_not_valid");
      });
      return false;
    }
  };
  const login = () => {
    const data = {
      username: state.username,
      password: state.password,
    };
    if (validate(data)) {
      setBtnLoading(true);
      dispatch(signIn(data)).then((response: any) => {
        setBtnLoading(false);
        // if (response.payload && response.payload.user.role !== "MANAGER" && 0) {
        //   setState((state) => {
        //     state.valid =
        //       "You don’t have sufficient permissions to enter the site. Please contact your admin";
        //   });
        // } else {
        if (response.payload && response.payload.access_token) {
          localStorage.setItem("token", response.payload.access_token);
          localStorage.setItem("refresh_token", response.payload.refresh_token);
          localStorage.removeItem("user");
          dispatch(profile(response.payload.access_token)).then(async () => {
            if (!response.payload.user.mobile_number) {
              //history.push("/mobile/verify");
              window.location.replace("/mobile/verify");
            } else {
              //history.push("/dashboard");
              //history.goBack()
              const prev_url: any = await localStorage.getItem("prev_url");
              if (prev_url) {
                if (
                  prev_url.split("/")[3] !== "login" &&
                  prev_url.split("/")[3] !== "sign-up" &&
                  prev_url.split("/")[3] !== "impressum" &&
                  prev_url.split("/")[3] !== "contact-us"
                ) {
                  window.location.replace(prev_url);
                } else {
                  window.location.replace("/dashboard");
                }
              } else {
                window.location.replace("/dashboard");
              }
            }
          });
        } else {
          setState((state) => {
            state.valid = t("auth.credentials_not_valid");
          });
        }
        // }
      });
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      login();
    }
  };
  return (
    <div className="main-container d-flex">
      <div className="login-form-section full-section d-flex justify-content-center align-items-center fadeIn">
        <div className="login-form-inputs-container d-flex justify-content-center flex-column">
          <div className="login-form-inputs-header">
            <img
              className="login-form-inputs-header-img"
              src={"/assets/images/logo/Logox2.png"}
              alt="fleetspark-logo"
            />
          </div>
          <small className="body-1-text login-form-inputs-header-text remove-under-1440">
            {t("home.welcome-back")}
          </small>
          <InputWithIcon
            value={state.username}
            name={"name"}
            placeholder="Username (Email)"
            pngSource={"/assets/images/auth/Profile2x.png"}
            onChange={(username: string) =>
              setState((state) => {
                state.username = username;
              })
            }
          />
          <InputWithIcon
            value={state.password}
            placeholder={t("home.password")}
            type="password"
            onKeyDown={(event: any) => handleKeyDown(event)}
            pngSource={"/assets/images/auth/Padlock2x.png"}
            onChange={(password: string) =>
              setState((state) => {
                state.password = password;
              })
            }
          />
          <div className="d-flex aligm-items-center justify-content-between">
            <div>
              <CheckboxWithTitle
                value={state.rememberMe}
                text={t("home.remember_me")}
                onChange={(rememberMe: boolean) =>
                  setState((state) => {
                    state.rememberMe = rememberMe;
                  })
                }
              />
            </div>
            <Link
              to="/forgot-password"
              className="forget-password-link deep-sky-blue-text"
            >
              <span className="forgot-password-under-1440">
                {t("home.forgot-password")}
              </span>
            </Link>
          </div>
          {state.valid && (
            <div style={{ width: "200px", display: "block" }}>
              <span style={{ color: "red", fontSize: "11px" }}>
                {state.valid}
              </span>
            </div>
          )}
          <div className="login-button">
            <LoadingButton
              loading={BtnLoading}
              onClick={() => login()}
              text={"Login"}
            />
          </div>
          {/* <div className="or-text d-flex align-items-center justify-content-between remove-under-1440">
              <div className="or-line" />
              OR
              <div className="or-line" />
            </div>
            <div className="login-links d-flex align-items-center justify-content-center remove-under-1440">
              <a href="https://www.facebook.com">
                <img src={'/assets/images/logo/Facebookx2.png'} alt="facebook-logo" />
              </a>
              <a href="https://www.twiter.com">
                <img src={'/assets/images/logo/Twitterx2.png'} alt="twiter-logo" />
              </a>
              <a href="https://www.google.com" style={{ marginRight: 0 }}>
                <img src={'/assets/images/logo/Googlex2.png'} alt="googleplus-logo" />
              </a>
            </div> */}
          <small className="login-form-switch-part body-2-text">
            {t("home.dont-have-account")}
            <Link to="/contact-us/book-call" className="deep-sky-blue-text">
              {t("home.signUp")}
            </Link>
          </small>
        </div>
      </div>
    </div>
  );
}
