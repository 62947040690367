import * as React from "react";
import { useSelector } from "react-redux";
import {
  PageContainer,
  FormHeader,
  ImagePicker,
  DropDown,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import { useTranslation, withTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import {
  company,
  companyEdit,
  countries,
} from "../../redux/actions/user.action";
import { useDispatch } from "../../redux/store";
import { RootState } from "../../redux/reducers";
import InputWrapper from "../../Components/InputWrapper/InputWrapper";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Account() {
  const { register } = useForm();
  const dispatch = useDispatch();
  const [Data, setData] = useState<Record<string, any>>({});
  const [Error, setError] = useState(false);
  const user = useSelector((state: RootState) => state.user.profile);
  const token = useSelector((state: RootState) => state.auth.token);
  const [image, setImage] = React.useState(null);
  const [mobileError, setMobileError] = React.useState(false);
  const [BtnLoading, setBtnLoading] = React.useState(false);
  const [CountriesData, setCountriesData] = React.useState([]);

  const [Company, setCompany] = React.useState<Record<string, any>>({});

  React.useEffect(() => {
    if (image !== null) {
      setData({
        ...Data,
        logo: image,
      });
    }
  }, [image]);

  React.useEffect(() => {
    console.log("data", Data);
  }, [Data]);

  function mobileInputFixer(name: any, value: any) {
    if (
      name === "mobile_number" ||
      name === "mobile_cc" ||
      name === "phone_number"
    ) {
      value = "+" + value.replaceAll("+", "");
      if (value.length !== 12) {
        setMobileError(true);
      } else {
        setMobileError(false);
      }
    }
    return value;
  }

  function change(event: any) {
    const name = event.target.name;
    let value = event.target.value;
    value = mobileInputFixer(name, value);
    if (value === "") {
      value = " ";
    }
    setData({
      ...Data,
      [name]: value,
    });
  }
  const [t] = useTranslation();

  function read() {
    const token = `${localStorage.getItem("token")}`;
    dispatch(company(token)).then((response: any) => {
      setData(response.payload);
      if (response.payload.picture) {
        setImage(response.payload.picture);
      }
    });
    dispatch(countries()).then((response: any) => {
      if (response.payload) {
        setCountriesData(response.payload.results);
      }
    });
  }

  function SaveRequest(yo: any) {
    setBtnLoading(true);
    const data = { ...yo };
    if (typeof data.logo !== "object") {
      data.logo = undefined; //delete picture key because its string
    }
    data.birth_date = data.birth_date && data.birth_date.replaceAll("/", "-");
    dispatch(companyEdit(data)).then((response: any) => {
      setBtnLoading(false);
      if (response.payload && !response.payload.error) {
        toast.success(t("account.saved"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setData(response.payload);
      } else {
        setError(response.payload.errorMassage);
      }
    });
  }

  useEffect(() => {
    read();
  }, []);

  return (
    <PageContainer>
      <ToastContainer />

      <FormHeader text={t("account.account")} />
      <div className={"ErrorMassage"}>{Error}</div>
      <div className="profile-fa account-fa">
        <div className="modal-content-section d-flex flex-column">
          <div className="account-modal-shipping">
            <div className="modal-content-section d-flex flex-column">
              <small className="header">{t("account.company_details")}</small>
              <div className="modal-account-account-detail d-flex account-detailCol">
                {Data ? (
                  <>
                    <InputWrapper
                      label={t("account.company_name") + ":"}
                      important
                    >
                      <input
                        name="name"
                        onChange={(value) => change(value)}
                        ref={register({ required: true })}
                        autoComplete="name"
                        value={Data && Data.name}
                        placeholder={t("account.company_name")}
                      />
                    </InputWrapper>
                    <div className={"companyLogoEdit"}>
                      <InputWrapper label={t("account.Company_Logo")} important>
                        <ImagePicker
                          label={t("account.label_for_picture")}
                          image={Data.logo && Data.logo}
                          imageFile={(image: any) => setImage(image)}
                        />
                      </InputWrapper>
                    </div>

                    <InputWrapper label={t("account.email") + ":"} important>
                      <input
                        name="email"
                        onChange={(value) => change(value)}
                        ref={register({ required: true })}
                        autoComplete="email"
                        value={Data && Data.email}
                        placeholder={t("account.email")}
                      />
                    </InputWrapper>
                    <InputWrapper
                      label={t("account.phone_number") + ":"}
                      important
                    >
                      <input
                        name="phone_number"
                        onChange={(value) => change(value)}
                        ref={register({ required: true })}
                        autoComplete="phone"
                        value={Data && Data.phone_number}
                        placeholder={t("account.phone_number")}
                      />
                    </InputWrapper>

                    <InputWrapper label={t("account.address") + ":"} important>
                      <input
                        name="street_name"
                        onChange={(value) => change(value)}
                        ref={register({ required: true })}
                        autoComplete="Address"
                        value={Data && Data.street_name}
                        placeholder={t("account.address")}
                      />
                    </InputWrapper>
                    <InputWrapper
                      label={t("account.additional") + ":"}
                      important
                    >
                      <input
                        name="address_supplement"
                        onChange={(value) => change(value)}
                        ref={register({ required: true })}
                        autoComplete="Address"
                        value={Data && Data.address_supplement}
                        placeholder="Address"
                      />
                    </InputWrapper>
                    <InputWrapper
                      label={t("account.postal_code") + ":"}
                      important
                    >
                      <input
                        name="postal_code"
                        onChange={(value) => change(value)}
                        ref={register({ required: true })}
                        value={Data && Data.postal_code}
                        placeholder={t("account.postal_code")}
                      />
                    </InputWrapper>

                    <InputWrapper label={t("account.city") + ":"} important>
                      <input
                        name="city"
                        onChange={(value) => change(value)}
                        ref={register({ required: true })}
                        value={Data && Data.city}
                        placeholder={t("account.city")}
                      />
                    </InputWrapper>
                    <InputWrapper label={t("account.state") + ":"} important>
                      <input
                        name="state"
                        onChange={(value) => change(value)}
                        ref={register({ required: true })}
                        value={Data && Data.state}
                        placeholder={t("account.state")}
                      />
                    </InputWrapper>
                    <InputWrapper label={t("account.country") + ":"} important>
                      <DropDown
                        selectedItem={{
                          name: Data ? Data.country_name : "",
                        }}
                        textEntity={"name"}
                        preText={""}
                        items={CountriesData}
                        pngSource={"/assets/images/header/Language@2x.png"}
                        placeholder={t("account.select_country")}
                        onChange={(selected: any) => {
                          setData({
                            ...Data,
                            country: selected.id ? selected.id : null,
                            country_name: selected.name,
                          });
                        }}
                      />
                    </InputWrapper>
                  </>
                ) : (
                  <div className={"noDataAvailable"}>
                    {t("account.no_data_available")}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <LoadingButton
              className={"updateBtn"}
              loading={BtnLoading}
              onClick={() => {
                SaveRequest(Data);
              }}
              text={t("account.Update")}
              icon={"/assets/icon-basic-refresh.svg"}
            />
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
