import * as React from "react";
import { useTranslation } from "react-i18next";

export default function CookiePolicy() {
  const [t] = useTranslation();
  const date = "21 March 2020";
  return (
    <div className="front-overal-paper fadeIn">
      <div className="last-modified-header d-flex align-items-center">
        <img src={"/assets/images/front/Calendar@2x.png"} alt="calendar-icon" />
        <small>
          {t("legal.last-modify")}: <span>{date}</span>.
        </small>
      </div>
      <h2>{t("contact.cookie-policy")}</h2>
      <p>{t("contact.cookie-policy-detail")}</p>
    </div>
  );
}
