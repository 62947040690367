import { createSlice } from "@reduxjs/toolkit";
import {
  vehicleStatus,
  companyCCDaily,
  companyCCMonthly,
  companyCCWeekly,
  usersCCDaily,
  usersCCMonthly,
  usersCCWeekly,
  userBreaksDaily,
  userIdlingDaily,
  userIdlingWeekly,
  companyIdlingDaily,
  userIdlingAvg,
  getSetting,
  companiesBrakingDaily,
  companiesBrakingMonthly,
  UsersFuelConsumption,
  UsersRankingQuarterly,
  UsersPointDaily,
  widgetsPermissions,
  getWidgets,
} from "../actions/dashboard.action";

// Then, handle actions in your reducers:
const initialState: Record<string, any> = {
  getWidgets: null,
  companyCCDaily: null,
  companyCCWeekly: null,
  companyCCMonthly: null,
  usersCCDaily: null,
  usersCCWeekly: null,
  usersCCMonthly: null,
  userBreaksDaily: null,
  userIdlingDaily: null,
  userIdlingWeekly: null,
  userIdlingAvg: null,
  companyIdlingDaily: null,
  companiesBrakingDaily: null,
  companiesBrakingMonthly: null,
  UsersFuelConsumption: null,
  UsersRankingQuarterly: null,
  UsersPointDaily: null,
  widgetsPermissions: null,
  vehicleStatus: [{}],
};
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UsersPointDaily.fulfilled, (state, { payload }) => {
      state.UsersPointDaily = payload;
    });
    builder.addCase(widgetsPermissions.fulfilled, (state, { payload }) => {
      state.widgetsPermissions = payload;
    });
    builder.addCase(UsersRankingQuarterly.fulfilled, (state, { payload }) => {
      state.UsersRankingQuarterly = payload;
    });

    builder.addCase(UsersFuelConsumption.fulfilled, (state, { payload }) => {
      state.UsersFuelConsumption = payload;
    });
    builder.addCase(companiesBrakingDaily.fulfilled, (state, { payload }) => {
      state.companiesBrakingDaily = payload;
    });
    builder.addCase(companiesBrakingMonthly.fulfilled, (state, { payload }) => {
      state.companiesBrakingMonthly = payload;
    });
    builder.addCase(vehicleStatus.fulfilled, (state, { payload }) => {
      //state.vehicleStatus = payload.body;
      state.vehicleStatus = payload;
    });
    builder.addCase(userBreaksDaily.fulfilled, (state, { payload }) => {
      state.userBreaksDaily = payload;
    });
    builder.addCase(userIdlingDaily.fulfilled, (state, { payload }) => {
      state.userIdlingDaily = payload;
    });
    builder.addCase(userIdlingWeekly.fulfilled, (state, { payload }) => {
      state.userIdlingWeekly = payload;
    });
    builder.addCase(userIdlingAvg.fulfilled, (state, { payload }) => {
      state.userIdlingAvg = payload;
    });
    builder.addCase(companyIdlingDaily.fulfilled, (state, { payload }) => {
      state.companyIdlingDaily = payload;
    });
    builder.addCase(companyCCDaily.fulfilled, (state, { payload }) => {
      state.companyCCDaily = payload.data;
    });
    builder.addCase(companyCCWeekly.fulfilled, (state, { payload }) => {
      state.companyCCWeekly = payload;
    });
    builder.addCase(companyCCMonthly.fulfilled, (state, { payload }) => {
      state.companyCCMonthly = payload;
    });
    builder.addCase(usersCCDaily.fulfilled, (state, { payload }) => {
      state.usersCCDaily = payload.data;
    });
    builder.addCase(usersCCWeekly.fulfilled, (state, { payload }) => {
      state.usersCCWeekly = payload;
    });
    builder.addCase(usersCCMonthly.fulfilled, (state, { payload }) => {
      state.usersCCMonthly = payload.data;
    });
  },
});

export default dashboardSlice;
