import React, { useEffect } from "react";
import LoadingView from "../Loading";

interface ItemAddProps {
  add: (type: string, element: object) => void | undefined;
  close: () => void | undefined;
  sectionId: string;
  sectionKey: string;
  WidgetsList: Record<string, any>;
  t: any;
}

export default function AddView({
  add,
  close,
  sectionId,
  sectionKey,
  WidgetsList,
  t,
}: ItemAddProps) {
  const [Menu, setMenu] = React.useState("All");
  const [Loading, setLoading] = React.useState(true);
  const [Sections, setSections] = React.useState<Array<Record<any, any>>>([]);
  const [Cats, setCats] = React.useState<Array<Record<any, any>>>([]);

  function addWidgetFunction(item: any) {
    item.element.i = item.element.i + sectionId;
    item.element.section_id = sectionId;
    item.element.section_key = sectionKey;
    item.element.static = item.element.static === "true" ? true : false;
    add(item.name, item.element);
  }

  useEffect(() => {
    if (WidgetsList.widgets) {
      setSections(WidgetsList.widgets);
      setCats(WidgetsList.categories);
      setLoading(false);
    } else {
      alert("you have not permission to access this view");
      close();
    }
  }, []);
  return (
    <div className={"popupItems"}>
      {Loading ? (
        <LoadingView />
      ) : (
        <div className={"itemsContent"}>
          <span className={"closePopup"} onClick={() => close()}></span>
          <div className={"menu-categories"}>
            <div className={"title"}>{t.Categories}</div>
            <ul className="side-items-container">
              {Cats &&
                Cats.map((item, index) => (
                  <ul key={index}>
                    <li
                      onClick={() => {
                        index === 0 ? setMenu("All") : setMenu(item.name);
                      }}
                      className="side-items-first-element d-flex align-items-center"
                      style={{
                        paddingLeft: Menu !== item.name ? 15 : 20,
                        backgroundColor: Menu === item.name ? "#f8fafb" : "",
                      }}
                    >
                      {Menu == item.name && (
                        <div className="side-item-active-left" />
                      )}
                      <img
                        style={{
                          width: Menu == item.name ? 25 : 35,
                          height: Menu ? 25 : 35,
                        }}
                        src={item.icon}
                        alt="fleetspark-logo"
                      />
                      <span>{item.name}</span>
                    </li>
                  </ul>
                ))}
            </ul>
          </div>

          <div className={"itemViews"}>
            {Sections &&
              Sections.map((section: any, i: any) =>
                Menu === section.title || Menu === "All" ? (
                  <section key={i}>
                    <div className={"viewTitle"}>{section.title}</div>
                    <div className={"items"}>
                      {section.items.map((item: any, index: any) => (
                        <div className={"itemView"} key={index}>
                          <div className={"imgBox"}>
                            <div className={"itemDescView"}>
                              <div className={"itemDescViewText"}>
                                {item.desc}
                              </div>
                              <div
                                className={"addToDashboardBtn"}
                                onClick={() => addWidgetFunction(item)}
                              >
                                <img src={"./assets/AddElementPlus.svg"} />
                                {t.Add_to_Dashboard}
                              </div>
                            </div>
                            <div className={"itemIcon"}>
                              <div>
                                <img src={item.icon} />
                              </div>
                            </div>
                          </div>
                          <span className={"desc"}>{item.title}</span>
                        </div>
                      ))}
                    </div>
                  </section>
                ) : null,
              )}
          </div>
        </div>
      )}
    </div>
  );
}
