import ky from "ky";
let token = localStorage.getItem("token");
let refresh_token = localStorage.getItem("refresh_token");
function extendHeader() {
  const header: any = {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Language : localStorage.getItem("lang"),
  };
  if (token) {
    header["Authorization"] = `Bearer ${token}`;
  }
  return header;
}

async function logOut() {
  await localStorage.removeItem("refresh_token");
  await localStorage.removeItem("token");
  await localStorage.setItem("prev_url", window.location.href);
  if (window.location.href !== "http://localhost:3000/") {
    window.location.replace("/login");
  }
}

export async function errorHandler(err: any) {
  try {
    const res: any = await err.response.json();
    let errorMassage = "";
    const keys = Object.keys(res);
    keys.map((key, i) => {
      let message = "";
      const objData = res[key];
      const inputName = key.replaceAll("_", " ");
      if (objData.isArray) {
        objData.map(
          (massageText: any) =>
            (message += inputName + " : " + massageText + "  "),
        );
      } else {
        message = inputName + " : " + objData + "  ";
      }
      errorMassage += message;
    });
    res.errorMassage = errorMassage.replaceAll(",", "  ");
    //res.errorMassage = errorMassage.slice(0, -2);
    res.error = true;

    return res;
  } catch (error) {
    const res: any = {};
    res.errorMassage = "Server Error 500";
    res.error = true;
    return res;
  }
}
export const restWithOutHook = ky.create({
  prefixUrl: `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/`,
  headers: extendHeader(),
});
async function hook(response: any, request: any) {
  if (response.status === 401) {
    //logOut();
    await ky
      .post(
        `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/` +
          "auth/token/refresh/",
        {
          json: { refresh: refresh_token },
        },
      )
      .json()
      .then((res: any) => {
        localStorage.setItem("refresh_token", res.refresh);
        localStorage.setItem("token", res.access);
        refresh_token = res.refresh;
        token = res.access;

        request.headers.set("Authorization", `Bearer ${res.access}`);
        return ky(request);
      })
      .catch((err: any) => {
        logOut();
        throw "Error";
      });
  }
}
export const rest = ky.create({
  prefixUrl: `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/`,
  headers: extendHeader(),
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set(
          "Accept-Language",
          localStorage.getItem("lang") || "en",
        );
        //request.headers.set(Language, localStorage.getItem("lang") || "en");
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        await hook(response, request);
      },
    ],
  },
});

export const aws = ky.create({
  prefixUrl: `${process.env.REACT_APP_BACKEND_AWS_ADDR_APIS}/`,
  headers: {
    "x-api-key": "ZuRgyZaRCC1e1w4bYQFEz175H45MXc5F3h3vd6Ul",
    Authorization: `Bearer ${token}`,
    //Language :"de",
    //Language :  localStorage.getItem("lang") || "en",
    //"Accept-Language": localStorage.getItem("lang") || "en",
  },
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set(
          "Accept-Language",
          localStorage.getItem("lang") || "en",
        );
        //request.headers.set(Language, localStorage.getItem("lang") || "en");
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        // console.log("b", request.headers);

        hook(response, request);
      },
    ],
  },
});

class ApiGateway {
  header: { Accept: string; "Content-Type": string; Authorization?: string };
  rest: typeof ky;
  aws: typeof ky;

  constructor() {
    this.header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    this.rest = ky.create({
      prefixUrl: `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/`,
      headers: extendHeader(),
    });
    this.aws = ky.create({
      prefixUrl: `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/`,
      headers: extendHeader(),
    });
    this.createInstance();
  }

  createInstance() {
    if (token) {
      this.header["Authorization"] = `Bearer ${token}`;
    }
    this.rest = ky.create({
      prefixUrl: `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/`,
      headers: extendHeader(),
    });
  }
}

export default new ApiGateway();
