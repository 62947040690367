import * as React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function FrontHeader() {
  const [t] = useTranslation();
  return (
    <div className="front-footer-container">
      <div className="front-footer-section-container">
        <div className="front-logo-footer">
          <img src={"/assets/images/logo/Logox2.png"} alt="fleetspark-logo" />
          <h3>{t("home.address-info")}</h3>
        </div>
      </div>
      <div className="front-footer-section-container footer-information-bottom">
        <small>{t("home.copy-right")}</small>
        <div className="front-header-links">
          <Link
            to={"/impressum/privacy-policy"}
            className={"footer-page-link-deactive border-right"}
          >
            {t("contact.privacy-policy")}
          </Link>
          <Link
            to={"/impressum/terms-and-conditions"}
            className={"footer-page-link-deactive"}
          >
            {t("home.terms-condition")}
          </Link>
        </div>
      </div>
      <div className="app_version">{process.env.REACT_APP_VERSION}</div>
    </div>
  );
}
