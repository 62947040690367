import * as React from "react";
import Paginate from "react-paginate";
import { PageContainer, FormHeader, InputWithIcon } from "../FromUtils";
import ColFilter from "./ColFilter";
import ColEdit from "./ColEdit";
import EditViewComponent from "./EditView";
import CreateViewComponent from "./CreateView";
import LoadingView from "../../../utilities/components/Loading";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import { useState, useRef, useLayoutEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import i18n from "i18next";
interface Props {
  tableData: Record<string, any>;
  tableParent?: string | "";
  title?: string | "";
  TableRowsData?: Record<string, any>;
  tableType: string | "";
  editable?: boolean | false;
  creatable?: boolean | false;
  ActionResult: any;
  getData: (Params: Record<string, any>) => void | undefined;
  clearResult?: () => void | undefined;
  CreateItem?: (data: Record<string, any>) => void | undefined;
  EditItem?: (data: Record<string, any>) => void | undefined;
  DeleteRow?: (data: Record<string, any>) => void | undefined;
  TagSave?: (user_id: any, tag: any) => void | undefined;
}

export default function Table({
  tableData,
  tableType,
  title,
  TableRowsData,
  CreateItem,
  DeleteRow,
  EditItem,
  editable,
  creatable,
  getData,
  tableParent,
  ActionResult,
  clearResult,
  TagSave,
}: Props) {
  const [Offset, setOffset] = React.useState(0);
  const [PageCount, setPageCount] = React.useState(0);
  const [AllCount, setAllCount] = React.useState(0);
  const [Search, setSearch] = React.useState("");
  const [Ordering, setOrdering] = React.useState("");
  const [OrderingWidget, setOrderingWidget] = React.useState("");
  const [According, setAccording] = React.useState("+");
  const [WidgetAccording, setWidgetAccording] = React.useState("+");
  const [Data, setData] = React.useState<Array<Record<string, any>>>([]);
  const [Cols, setCols] = React.useState<Array<string>>([]);
  const [SelectedRows, setSelectedRows] = React.useState<Array<string>>([]);
  const [SelectedAll, setSelectedAll] = React.useState(false);
  const [EditData, setEditData] = React.useState<Record<string, any>>({});
  const [FilterCol, setFilterCol] = React.useState<Array<string>>([
    "id",
    "company",
  ]);
  const [CurrentPage, setCurrentPage] = React.useState(0);
  const [Limit, setLimit] = React.useState(10);
  const [LimitOpen, setLimitOpen] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [EditView, setEditView] = React.useState(false);
  const [CreateView, setCreateView] = React.useState(false);
  const [MultipleRow, setMultipleRow] = React.useState(false);
  const [ColFilterView, setColFilterView] = React.useState(false);
  const [Loading, setLoading] = React.useState(true);
  const [ColEditView, setColEditView] = React.useState<number>();
  const location = useLocation();
  const [t] = useTranslation();

  function insertRows(response: any) {
    let data = [];
    if (response) {
      setLoad(true);

      if (tableParent === "Widget") {
        data = response;
      } else {
        data = response.results;
      }

      if (data && data.length > 0) {
        const keys = Object.keys(data[0]);
        setData(data);
        if (tableParent !== "Widget") {
          setPageCount(Math.ceil(response.count / Limit));
          setAllCount(response.count);
        }
        setCols(keys);
      } else {
        setData([]);
        setPageCount(0);
        setAllCount(0);
      }
    }
  }

  async function request(searching?: any) {
    const Params = {
      offset: !searching ? Offset : 0,
      limit: Limit,
      search: Search,
      ordering: Ordering,
      according: According,
    };
    setLoading(false);
    return getData(Params);
  }

  async function HandlePageClick(e: any) {
    const selectedPage = e.selected;
    const offset = selectedPage * Limit;
    await setCurrentPage(selectedPage);
    await setOffset(offset);
    const Params = {
      offset: offset,
      limit: Limit,
      search: Search,
      ordering: Ordering,
      according: According,
    };

    return getData(Params);
  }

  React.useEffect(() => {
    const filters = localStorage.getItem(tableType + "_filter");
    if (filters) {
      setFilterCol(JSON.parse(filters));
    } else {
      setFilterCol(["company", "id"]);
    }

    insertRows(tableData);
  }, [tableData]);
  React.useEffect(() => {
    localStorage.setItem(tableType + "_filter", JSON.stringify(FilterCol));
  }, [FilterCol]);

  React.useEffect(() => {
    request();
  }, [location, According, Limit, PageCount]);
  React.useEffect(() => {
    setOffset(0);
    request(true);
  }, [Search]);

  function selectAll() {
    if (SelectedAll) {
      setSelectedRows([]);
      setSelectedAll(false);
    } else {
      setSelectedAll(true);
      const newIds = Data.map((el) => el.id);
      setSelectedRows(newIds);
    }
  }

  function selectRow(val: string) {
    if (!SelectedRows.includes(val)) {
      setSelectedRows([...SelectedRows, val]);
    } else {
      setSelectedRows(SelectedRows.filter((item) => item !== val));
    }
  }

  function search(key: string) {
    key = key.toLowerCase();
    setSearch(key);
  }

  function changeLimit(val: any) {
    setLimit(Number(val));
  }

  function editedData(data: any) {
    EditItem && EditItem(data);
  }

  function deleteItem(data: any) {
    DeleteRow && DeleteRow(data);
  }

  function CreateRequest(data: any) {
    CreateItem && CreateItem(data);
  }

  function filterCol(val: string) {
    if (!FilterCol.includes(val)) {
      setFilterCol([...FilterCol, val]);
    } else {
      setFilterCol(FilterCol.filter((item) => item !== val));
    }
  }

  function OrderChecker(col: any) {
    if (tableParent === "Widget") {
      if (OrderingWidget === col) {
        return true;
      } else {
        return false;
      }
    } else {
      if (Ordering === col) {
        return true;
      } else {
        return false;
      }
    }
  }

  function sortBy(col: any) {
    if (tableParent === "Widget") {
      const sortData =
        According === "+"
          ? [...Data].sort((a: any, b: any) => (a[col] > b[col] ? 1 : -1))
          : [...Data].sort((a: any, b: any) => (a[col] < b[col] ? 1 : -1));
      setData(sortData);
      setOrderingWidget(col);
    } else {
      setOrdering(col);
      setAccording(According === "+" ? "-" : "+");
    }
    setWidgetAccording(WidgetAccording === "+" ? "-" : "+");
  }

  function CustomColsGenerator(data: any, key: any, index: number) {
    if (key === "status") {
      return (
        <div className={"flexCol"}>
          {data === "active" && <i className={"vehicleStatus active"} />}
          {data === "deactivate" && (
            <i className={"vehicleStatus deactivate"} />
          )}
          {data === "pending" && <i className={"vehicleStatus pending"} />}
          {data}
        </div>
      );
    }
    if (key === "color") {
      return (
        <div className={"flexCol"}>
          <div className={"color-item"} style={{ background: data }}></div>
        </div>
      );
    }

    if (key === "vehicle_brand") {
      return <div className={"flexCol"}>{data ? data.name : " - "}</div>;
    }

    if (key === "obj_groups") {
      return (
        <div className={"flexCol"}>
          <div className={"row-profile-item"}>
            {data &&
              data[0] &&
              data.map((tag: any, key: number) => (
                <span key={key} className={"spanTagTable"}>
                  {tag}
                </span>
              ))}
          </div>
        </div>
      );
    }
    if (key === "picture") {
      return (
        <div className={"flexCol"}>
          <div className={"row-profile-item"}>
            <img alt={""} src={data} />
          </div>
        </div>
      );
    }
    if (key === "icon") {
      return (
        <div className={"flexCol"}>
          <div className={"row-vehicle-icon"}>
            {data ? <img src={data} alt={""} /> : "-"}
          </div>
        </div>
      );
    }
    if (key === "id" || key === "user") {
      return <div className={"flexCol"}>{index + 1 + Offset}</div>;
    }

    if (data === null) {
      return " - ";
    }
    if (typeof data !== "object") {
      return data ? data : " - ";
    } else {
      return "Object Given";
    }
  }

  function idSelector(row: Record<string, any>) {
    if (row.user) {
      return row.user;
    } else {
      return row.id;
    }
  }

  function editPopup(rowItem: any, index: any) {
    if (SelectedRows.length <= 1) {
      setMultipleRow(false);
    } else {
      setMultipleRow(true);
    }
    setEditData(rowItem);
    setColEditView(index);
  }

  function colGeneratorName(col: string) {
    if (col === "obj_groups") {
      return t("table.groups");
    } else {
      if (t("columns." + col)) {
        const tr: any = t("columns", { returnObjects: true });
        console.log("tr", tr);
        console.log("[col]", col);
        return tr[col.toLowerCase()];
      }
      return col.replaceAll("_", " ");
    }
  }
  function confirmation() {
    clearResult && clearResult();
    if (ActionResult) {
      if (ActionResult.payload && !ActionResult.payload.error) {
        const title = ActionResult.delete
          ? t("table.deleted")
          : t("account.saved");
        toast.success(title, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }
  }
  const firstUpdate = useRef(true);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  }, [i18n.language]);

  return (
    <>
      {Loading && <LoadingView />}
      {EditView && (
        <EditViewComponent
          Title={title ? title : tableType}
          Type={tableType}
          ActionResult={ActionResult}
          save={(data: any) => editedData(data)}
          TableRowsData={TableRowsData ? TableRowsData : []}
          RowData={EditData}
          deleteRow={(data: any) => deleteItem(data)}
          close={() => {
            setEditView(false);

            confirmation();
          }}
        />
      )}
      {CreateView && (
        <CreateViewComponent
          Title={title ? title : tableType}
          Type={tableType}
          ActionResult={ActionResult}
          save={(data: any) => CreateRequest(data)}
          TableRowsData={TableRowsData ? TableRowsData : []}
          close={() => {
            setCreateView(false);
            confirmation();
          }}
        />
      )}
      <PageContainer tableView={true}>
        <ToastContainer />
        <FormHeader text={title ? title : tableType} />
        <div className={"tableSearch"}>
          <div className="search-input-table">
            <InputWithIcon
              value={Search}
              placeholder={t("table.Search")}
              pngSource={"/assets/icon-basic-search.svg"}
              onChange={(key: string) => search(key)}
            />
          </div>
          {creatable && (
            <div
              onClick={() => setCreateView(true)}
              className={"createBtn"}
            ></div>
          )}
        </div>
        <div className="tableViewContainer customScrollBar">
          <div className="tableContainer ">
            {load && Data.length ? (
              <table className="table tableVehicle">
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        type="checkbox"
                        onClick={() => selectAll()}
                        checked={SelectedAll}
                      />
                    </th>
                    {Cols.map(
                      (col, index) =>
                        !FilterCol.includes(col) && (
                          <th scope="col" key={index}>
                            <div className={"thCol"}>
                              {colGeneratorName(col)}
                              <div
                                className={"sortByItem"}
                                onClick={() => {
                                  sortBy(col);
                                }}
                              >
                                {OrderChecker(col) ? (
                                  WidgetAccording === "+" ? (
                                    <img
                                      alt={""}
                                      src={
                                        "/assets/images/front/sortByArrow.svg"
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt={""}
                                      src={
                                        "/assets/images/front/sortByArrowUp.svg"
                                      }
                                    />
                                  )
                                ) : (
                                  <img
                                    alt={""}
                                    src={"/assets/images/front/sortByArrow.svg"}
                                  />
                                )}
                              </div>
                            </div>
                          </th>
                        ),
                    )}
                    <th scope="col" className={"thDetails "}>
                      <div
                        className={"colDetails"}
                        onClick={() => {
                          setColFilterView(!ColFilterView);
                        }}
                      />
                      {ColFilterView && (
                        <div className={"colEditPopup"}>
                          <ColFilter
                            close={() => setColFilterView(false)}
                            filter={(val: any) => filterCol(val)}
                            cols={Cols}
                            active={FilterCol}
                          />
                        </div>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((rowItem, index) => (
                    <tr
                      className={
                        SelectedRows.includes(idSelector(rowItem))
                          ? "tableRow tableRowActive"
                          : "tableRow"
                      }
                      key={index}
                    >
                      {/*<th scope="row"><input type="checkbox" value={rowItem.id}/></th>*/}
                      <th scope="row">
                        <input
                          type="checkbox"
                          onChange={() => selectRow(idSelector(rowItem))}
                          checked={SelectedRows.includes(idSelector(rowItem))}
                        />
                      </th>

                      {Cols.map((key, indexKey) => (
                        <>
                          {!FilterCol.includes(key) && (
                            <td key={indexKey}>
                              {CustomColsGenerator(rowItem[key], key, index)}
                            </td>
                          )}
                        </>
                      ))}
                      <td className={"thDetails"}>
                        {tableParent !== "Widget" && (
                          <div
                            className={"flexCol colDetailsFa"}
                            onClick={() => {
                              editPopup(rowItem, index);
                            }}
                          >
                            <div className={"colDetails"} />
                            {ColEditView === index ? (
                              <div className={"colEditPopup"}>
                                <ColEdit
                                  multiple={MultipleRow}
                                  close={() => {
                                    editPopup({}, -1);
                                  }}
                                  edit={() => {
                                    setEditView(true);
                                    setColEditView(-1);
                                  }}
                                  SelectedRows={SelectedRows}
                                  group={(tag: any) => {
                                    TagSave && TagSave(SelectedRows, tag);
                                  }}
                                  cols={Cols}
                                  data={Data}
                                  editable={editable ? editable : false}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={"emptyTable"}>{t("table.no_data_available")}</div>
            )}
          </div>
        </div>
        {tableParent !== "Widget" && (
          <div className={"tableFooter"}>
            <div className={"tableFooterDiv showRows"}>
              {t("table.Show")} :
              <div
                className={"customSelect"}
                onClick={() => {
                  setLimitOpen(!LimitOpen);
                }}
              >
                <div className={"customSelectValue"}>
                  {Limit}
                  <img src={"/assets/SelectArrowDown.svg"} />
                </div>
                <div
                  className={
                    LimitOpen
                      ? "customSelectOptionsView customSelectOptionsViewShow"
                      : "customSelectOptionsView"
                  }
                >
                  <div
                    onClick={() => {
                      changeLimit(10);
                    }}
                  >
                    10
                  </div>
                  <div
                    onClick={() => {
                      changeLimit(20);
                    }}
                  >
                    20
                  </div>
                  <div
                    onClick={() => {
                      changeLimit(30);
                    }}
                  >
                    30
                  </div>
                  <div
                    onClick={() => {
                      changeLimit(40);
                    }}
                  >
                    40
                  </div>
                </div>
              </div>
            </div>

            <div className={"tableFooterDiv itemsCount"}>
              {t("table.Showing")} {Offset} {t("table.To")}{" "}
              {Offset + Limit > AllCount ? AllCount : Offset + Limit}{" "}
              {t("table.of")} {AllCount} {t("table.Items")}
            </div>
            <div className={"tableFooterDiv paginationDiv"}>
              <Paginate
                previousLabel={""}
                nextLabel={""}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={PageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={(v) => HandlePageClick(v)}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        )}
      </PageContainer>
    </>
  );
}
