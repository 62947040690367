import { createSlice } from "@reduxjs/toolkit";
import { logoutAction, profileAction, signIn } from "../actions/auth.action";
import apiGateway from "../apiGateway";
// Then, handle actions in your reducers:
const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token") || "",
    refresh_token: localStorage.getItem("refresh_token") || "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      // if (payload.user.role === "MANAGER") {
      localStorage.setItem("token", payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);

      apiGateway.createInstance();
      state.token = payload.access_token;
      state.refresh_token = payload.refresh_token;
      // }
    });

    //builder.addCase(signUp.fulfilled, () => {});
    builder.addCase(logoutAction.fulfilled, (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      state.token = "";
    });
    //builder.addCase(resetPassword.fulfilled, () => {});
  },
});

export default authSlice;
