import * as React from "react";
import { InputWithIcon } from "./FromUtils";
import SwitchRole from "../../pages/Setting/SwitchRole";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { logoutAction } from "../../redux/actions/auth.action";
import { profile } from "../../redux/actions/user.action";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { useEffect } from "react";
import InviteTeammates from "./InviteTeammates";
import i18n from "i18next";
import OutsideClickHandler from "react-outside-click-handler";

interface HeaderAndMainProps {
  sideState: any;
  setSideState: (flag: boolean) => void;
  children: React.ReactNode;
}

export default function HeaderAndMain({
  sideState,
  setSideState,
  children,
}: HeaderAndMainProps) {
  const [t] = useTranslation();
  const [dpMenu, setDpMenu] = React.useState(false);
  const [SwitchRol, setSwitchRol] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.user.profile);
  const Token = useSelector((state: RootState) => state.auth.token);
  const changeLanguage = (lng: any) => {
    localStorage.setItem("lang", lng);
    i18n.changeLanguage(lng);
  };
  const langs = [
    { name: "English", key: "en", flag: "/assets/flags/gb.png" },
    { name: "Deutsch", key: "de", flag: "/assets/flags/de.png" },
    { name: "Polski", key: "pl", flag: "/assets/flags/pl.png" },
  ];
  const logOutHandler = () => {
    dispatch(logoutAction()).then(() => {
      history.replace("/");
      localStorage.removeItem("token");
      localStorage.removeItem("coca-manager");
      window.location.reload();
    });
  };
  useEffect(() => {
    if (!user.username) {
      dispatch(profile(Token));
    }
  }, []);
  return (
    <div
      className={
        sideState
          ? " header-content-container-desktop d-flex flex-column"
          : "header-content-container-mobile d-flex flex-column"
      }
    >
      {SwitchRol && (
        <SwitchRole
          close={() => {
            setSwitchRol(false);
          }}
        />
      )}
      <header
        className={
          sideState
            ? "header-container header-container-desktop d-flex align-items-center justify-content-between"
            : "header-container  header-container-mobile d-flex align-items-center justify-content-between"
        }
      >
        <div
          className="header-drop-menu"
          style={{ height: dpMenu ? "unset" : 0 }}
        >
          {dpMenu && (
            <OutsideClickHandler
              onOutsideClick={() => {
                setDpMenu(false);
              }}
            >
              <button
                onClick={() => {
                  setSwitchRol(true);
                }}
              >
                {t("account.Switch_Role")}
              </button>
              <button onClick={() => logOutHandler()}>
                {t("home.logout")}
              </button>
            </OutsideClickHandler>
          )}
        </div>

        <div className="header-expand-container d-flex align-items-center">
          <button
            onClick={() => {
              if (sideState === false) {
                setSideState(true);
              } else {
                setSideState(false);
              }
            }}
          >
            {sideState && (
              <img src={"/assets/images/header/Menu@2x.png"} alt="input-icon" />
            )}
            {!sideState && (
              <img
                src={"/assets/images/header/MenuClosed@2x.png"}
                alt="input-icon"
              />
            )}
          </button>
          {/* <div className="search-input-container remove-under-1250">
            <InputWithIcon
              value={search}
              placeholder="Search..."
              pngSource={"/assets/icon-basic-search.svg"}
              onChange={(search: string) => setSearch(search)}
            />
          </div> */}
        </div>
        <div className="d-flex align-items-center">
          <div className="langMenu language-header-main d-flex align-items-center ">
            <div className="header-language-icon d-flex align-items-center">
              <img
                src={"/assets/images/header/Language@2x.png"}
                alt="input-icon"
              />
            </div>
            <button className="header-language-container d-flex align-items-center">
              <img
                src={"/assets/images/header/ArrowDown@2x.png"}
                alt="input-icon"
              />
            </button>
            <div className="langMenu-drop-menu">
              {langs.map((lang, key) => (
                <button
                  key={key}
                  onClick={() => changeLanguage(lang.key)}
                  className={i18n.language === lang.key ? "activeLang" : ""}
                >
                  <img className={"langFlag"} src={lang.flag} />
                  {lang.name}
                </button>
              ))}
            </div>
          </div>
          {/* <div className="header-notification-main d-flex align-items-center remove-under-1250">
            <button className="notification-email header-notification-container d-flex align-items-center">
              <img
                src={
                  messages.length > 0
                    ? "/assets/images/header/MessageNotification@2x.png"
                    : "/assets/images/header/Message@2x.png"
                }
                alt="input-icon"
              />
            </button>
            <button className="header-notification-container d-flex align-items-center">
              <img
                src={
                  notifications.length > 0
                    ? "/assets/images/header/BellNotification@2x.png"
                    : "/assets/images/header/Bell@2x.png"
                }
                alt="input-icon"
              />
            </button>
          </div> */}
          <div
            className="header-account-container d-flex align-items-center"
            onClick={() => setDpMenu(!dpMenu)}
          >
            <div className="header-account-circle header-account-profileImage d-flex align-items-center justify-content-center">
              <img src={user.picture} />
            </div>
            <small className="header-account-text">
              {user.first_name
                ? user.first_name + " " + user.last_name
                : user.username}
            </small>
            <button className="icon-account-container d-flex align-items-center">
              <img
                src={"/assets/images/header/ArrowDown@2x.png"}
                alt="input-icon"
              />
            </button>
          </div>
        </div>
      </header>
      <main className="content-container">{children}</main>
    </div>
  );
}
