import { createSlice } from "@reduxjs/toolkit";
import {
  profile,
  profileEdit,
  shippingAddress,
  company,
  inviteLink,
  inviteEmails,
} from "../actions/user.action";
const user = localStorage.getItem("user");

const initialState: Record<string, any> = {
  profile: user ? JSON.parse(user) : {},
  profileEdit: null,
  company: null,
  inviteLink: null,
  inviteEmails: null,
  shippingAddress: null,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(profile.fulfilled, (state, { payload }) => {
      state.profile = payload;
      localStorage.setItem("user", JSON.stringify(payload));
    });
    builder.addCase(shippingAddress.fulfilled, (state, { payload }) => {
      state.shippingAddress = payload;
    });
    builder.addCase(company.fulfilled, (state, { payload }) => {
      state.company = payload;
    });
    builder.addCase(inviteEmails.fulfilled, (state, { payload }) => {
      state.inviteEmails = payload;
    });
    builder.addCase(inviteLink.fulfilled, (state, { payload }) => {
      state.inviteLink = payload;
    });
    builder.addCase(profileEdit.fulfilled, (state, { payload }) => {
      state.profileEdit = payload;
      localStorage.setItem("user", JSON.stringify(payload));
      state.profile = payload;
    });
  },
});

export default userSlice;
