import * as React from "react";
import { useImmer } from "use-immer";
import { Link, useParams, useLocation } from "react-router-dom";

import {
  InputWithIcon,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import {
  VerifyEmail,
  VerifyEmailResend,
} from "../../redux/actions/auth.action";
import { EmailValidator } from "../../utilities/functions/authValidator";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { useEffect, useState } from "react";

export default function VerifyEmailCo({ history }: any) {
  const prop: any = useParams();
  const dispatch = useDispatch();
  const queryString = require("query-string");
  const loc: any = useLocation();
  const [t] = useTranslation();
  const [Error, setError] = useState(true);
  const [Email, setEmail] = useState("");
  const [state, setState] = useImmer({
    permission: true,
    valid: "",
  });
  const validate = (data: any) => {
    setState((state) => {
      state.valid = "";
    });
    if (EmailValidator(data)) {
      return true;
    } else {
      setState((state) => {
        state.valid = t("auth.Verification_email");
      });
      return false;
    }
  };
  useEffect(() => {
    const query: any = queryString.parse(loc && loc.search);

    const data = { key: query.token };

    dispatch(VerifyEmail(data)).then((response: any) => {
      if (!response.error) {
        history.replace("/login");
      } else {
        setError(true);
      }
    });
  }, []);

  function resend() {
    const data = { email: Email };
    if (validate(data)) {
      dispatch(VerifyEmailResend(data)).then((response: any) => {
        console.log("response", response);
        if (!response.error) {
          history.replace("/login");
        } else {
          setState((state) => {
            state.valid = response.error.message;
          });
        }
      });
    }
  }

  return (
    <div className="main-container d-flex">
      <div className="login-form-section full-section d-flex justify-content-center align-items-center fadeIn">
        <div className="login-form-inputs-container d-flex justify-content-center flex-column">
          <div className="login-form-inputs-header">
            <img
              className="login-form-inputs-header-img"
              src={"/assets/images/logo/Logox2.png"}
              alt="fleetspark-logo"
            />
          </div>

          <small className="signInText  text-center">
            {t("auth.token_expired")}
          </small>
          <InputWithIcon
            value={Email}
            placeholder={t("home.email")}
            type="email"
            pngSource={"/assets/images/auth/Profile2x.png"}
            onChange={(email: string) => setEmail(email)}
          />
          {state.valid && (
            <div style={{ width: "200px", display: "block" }}>
              <span style={{ color: "red", fontSize: "11px" }}>
                {state.valid}
              </span>
            </div>
          )}
          <div className="login-button">
            <LoadingButton
              onClick={() => resend()}
              text={t("auth.Resend_Verification_Email")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
