import * as React from "react";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { enGB, de } from "date-fns/locale";
import staticRangesGenerator from "./staticRanges";
import Moment from "moment";

export default function DatePicker({ onDateChange, defaultData, t }) {
  const staticRanges = staticRangesGenerator(de, t);

  const [DateSelected, setDateSelected] = React.useState([
    {
      startDate: defaultData ? new Date(defaultData.start_date) : new Date(),
      // endDate: defaultData
      //     ? new Date(defaultData.end_date)
      //     : addDays(new Date(), 7),
      endDate: defaultData ? new Date(defaultData.end_date) : new Date(),
      key: "selection",
    },
  ]);

  const handleDateChange = (item) => {
    setDateSelected([item.selection]);
    if (onDateChange) {
      onDateChange({
        start_date: Moment(item.selection.startDate).format("YYYY-MM-DD"),
        end_date: Moment(item.selection.endDate).format("YYYY-MM-DD"),
      });
      // onDateChange({
      //   start_date: addDays(new Date(item.selection.startDate), 1)
      //     .toISOString()
      //     .split("T")[0],
      //   end_date: addDays(new Date(item.selection.endDate), 1)
      //     .toISOString()
      //     .split("T")[0],
      // });
    }
  };

  return (
    <>
      <DateRangePicker
        toISOString={false}
        maxDate={Moment().toDate()}
        locale={localStorage.getItem("lang") === "de" ? de : enGB}
        staticRanges={staticRanges}
        onChange={(item) => handleDateChange(item)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={1}
        minimumNights={0}
        ranges={DateSelected}
        inputRanges={[]}
        direction="horizontal"
      />
    </>
  );
}
