import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import CalendarManager from "./CalendarManager";
import CalendarDriver from "./CalendarDriver";

export default function CalendarView({ history }: any) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [event, setEvent] = React.useState([]);
  const role = useSelector((state: RootState) => state.user.profile);
  return role.role === "MANAGER" ? <CalendarManager /> : <CalendarDriver />;
}
