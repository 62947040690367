import { rest } from "../apiGateway";

export const companyVehicle = (data: any) => {
  const search = data.search ? "&search=" + data.search : "";
  const according = data.according === "-" ? data.according : "";
  const order = data.ordering ? "&ordering=" + according + data.ordering : "";
  return rest
    .get(
      "company-vehicles/?type=TRUCK&type=TRACTOR" +
        "&limit=" +
        data.limit +
        "&offset=" +
        data.offset +
        search +
        order,
    )
    .json<Record<string, any>>();
};
export const companyVehicleCAN = (data: any) => {
  const search = data.search ? "&search=" + data.search : "";
  const according = data.according === "-" ? data.according : "";
  const order = data.ordering ? "&ordering=" + according + data.ordering : "";
  return rest
    .get(
      "company-vehicles/?type=TRUCK&type=TRACTOR&candevice__isnull=True" +
        "&limit=" +
        100 +
        "&offset=" +
        0 +
        search +
        order,
    )
    .json<Record<string, any>>();
};

export const vehicleEdit = (json: IVehicle) => {
  return rest
    .patch("company-vehicles/" + json.id + "/", { json })
    .json<IvehicleResponse>();
};
export const vehicleDelete = (json: IVehicle) => {
  return rest
    .delete("company-vehicles/" + json.id + "/", { json })
    .json<IvehicleResponse>();
};
export const vehicleCreate = (json: IVehicle) => {
  return rest.post("company-vehicles/", { json }).json<IvehicleResponse>();
};

export const users = (data: any) => {
  const search = data.search ? "&search=" + data.search : "";
  const according = data.according === "-" ? data.according : "";
  const order = data.ordering ? "&ordering=" + according + data.ordering : "";

  return rest
    .get(
      "company/profiles/" +
        "?limit=" +
        data.limit +
        "&offset=" +
        data.offset +
        search +
        order,
    )
    .json<Record<string, any>>();
};

export const drivers = (data: any) => {
  const search = data.search ? "&search=" + data.search : "";
  const according = data.according === "-" ? data.according : "";
  const order = data.ordering ? "&ordering=" + according + data.ordering : "";

  return rest
    .get(
      "company/profiles/" +
        "?role=DRIVER" +
        "&limit=" +
        data.limit +
        "&offset=" +
        data.offset +
        search +
        order,
    )
    .json<Record<string, any>>();
};

export const driversEdit = (json: any) => {
  return rest.patch("company/profiles/" + json.id + "/", { json }).json();
};
export const vehiclesBrands = (data: any) => {
  const search = data.search ? "?search=" + data.search : "";
  const type =
    data.type === "TRAILER"
      ? (search === "" ? "?" : "&") + "type=TRAILER"
      : (search === "" ? "?" : "&") + "type=TRACTOR&type=TRUCK";
  //(search === "" ? "?" : "&") + "type=LIGHT_COMMERCIAL,TRACTOR,TRAILER,TRUCK,BUS,CAR"
  return rest.get("vehicles/brands/" + search + type).json();
};
export const vehiclesSubtypes = (data: any) => {
  const search = data.search ? "?search=" + data.search : "";
  const type =
    data.type === "TRAILER" ? (search === "" ? "?" : "&") + "type=TRAILER" : "";
  return rest.get("vehicles/subtypes/" + search + type).json();
};
export const driversCreate = (json: any) => {
  return rest.post("company/profile/register/", { json }).json();
};

export const usersTag = (json: any) => {
  return rest.post("users/groups/", { json }).json();
};
export const vehiclesTag = (json: any) => {
  return rest.post("vehicles/groups/", { json }).json();
};

export const driversDelete = (json: any) => {
  return rest.delete("company/profiles/" + json.id + "/", { json }).json();
};

export const trailer = (data: any) => {
  const search = data.search ? "&search=" + data.search : "";
  const according = data.according === "-" ? data.according : "";
  const order = data.ordering ? "&ordering=" + according + data.ordering : "";

  return rest
    .get(
      "company-vehicles/?type=TRAILER" +
        "&limit=" +
        data.limit +
        "&offset=" +
        data.offset +
        search +
        order,
    )
    .json<Record<string, any>>();
};

export const canDevices = (data: any) => {
  const search = data.search ? "&search=" + data.search : "";
  const according = data.according === "-" ? data.according : "";
  const order = data.ordering ? "&ordering=" + according + data.ordering : "";

  return rest
    .get(
      "can-devices/?limit=" +
        data.limit +
        "&offset=" +
        data.offset +
        search +
        order,
    )
    .json<Record<string, any>>();
};

export const canDevicesEdit = (json: IVehicle) => {
  return rest.patch("can-devices/" + json.id + "/", { json }).json();
};
export const canDevicesDelete = (json: IVehicle) => {
  return rest.delete("can-devices/" + json.id + "/", { json }).json();
};
export const canDevicesCreate = (json: IVehicle) => {
  return rest.post("can-devices/", { json }).json();
};
