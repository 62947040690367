import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import OutsideClickHandler from "react-outside-click-handler";
import DatePicker from "./Layouts/Header/DatePicker";
import Widget from "./Widgets/WidgetCreator";
import "react-animated-slider/build/vertical.css";
import "react-animated-slider/build/vertical.css";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface Props {
  usersList?: Record<string, any>;
  GroupsList?: Record<string, any>;
  Ready: boolean;
  Breakpoint: string;
  OpenDateZindex: Record<string, any>;
  CompanyLogo: string;
  SliderIndex: number;
  Scale: number;
  Sections: any;
  RenameSection: any;
  Slider: boolean | true;
  Overview: boolean | true;
  SingleOverview: any;
  SectionTitle: string;
  setSectionTitle: (name: any) => void | undefined;
  SlideShowMode: boolean;
  HiddenSections: any;
  setSections: (sections: any) => void | undefined;
  loadedItem: (el: any) => void | undefined;
  setRenameSection: (el: any) => void | undefined;
  setHiddenSections: (el: any) => void | undefined;
  openSectionCalendar: (el: any) => void | undefined;
  SectionCalendar: string;
  setSectionCalendar: (el: any) => void | undefined;
  handleDateChange: (range: any, key: any) => void | undefined;
  SingleOverviewFunction: (el: any) => void | undefined;
  openSectionSetting: (el: any) => void | undefined;
  OpenSetting: string;
  setOpenSetting: (el: any) => void | undefined;
  deleteSection: (el: any) => void | undefined;
  showAddView: (el: any, key: any) => void | undefined;
  SectionMove: (el: any, key: any) => void | undefined;
  onDragStop: (layouts: any, oldItem: any, newItem: any) => void;
  onResizeStop: (layouts: any, oldItem: any, newItem: any) => void;
  onLayoutChange: (layouts: any) => void;
  onBreakpointChange: (breakpoint: any) => void;
  widgetDateChange: (range: any, section_key: any, i: any) => void | undefined;
  setOpenDateZindex: (state: boolean, el: any) => void | undefined;
  pinItem: (el: any) => void | undefined;
  infoItemAction: (el: any) => void | undefined;
  removeItem: (el: any) => void | undefined;
  SectionAction: () => void | undefined;
  t: Record<string, any>;
  api: Record<string, any>;
}

export default function SectionCreator({
  usersList,
  GroupsList,
  Ready,
  SectionAction,
  Breakpoint,
  t,
  SliderIndex,
  deleteSection,
  Scale,
  Sections,
  SingleOverviewFunction,
  Slider,
  openSectionSetting,
  SectionMove,
  Overview,
  showAddView,
  setOpenSetting,
  SlideShowMode,
  OpenSetting,
  HiddenSections,
  CompanyLogo,
  OpenDateZindex,
  SingleOverview,
  handleDateChange,
  setSections,
  SectionTitle,
  setHiddenSections,
  openSectionCalendar,
  RenameSection,
  setSectionTitle,
  SectionCalendar,
  setSectionCalendar,
  setRenameSection,
  onDragStop,
  onResizeStop,
  onLayoutChange,
  onBreakpointChange,
  widgetDateChange,
  setOpenDateZindex,
  loadedItem,
  pinItem,
  infoItemAction,
  removeItem,
  api,
}: Props) {
  function HideShowSection(index: any) {
    const section = [...Sections];
    section[index].show = !section[index].show;
    setSections(section);
  }
  const [CalendarDate, setCalendarDate] = React.useState<any>("");

  function itemWidegt(i: any, el: any) {
    try {
      return (
        <Widget
          SectionAction={() => SectionAction()}
          Breakpoint={Breakpoint}
          t={t}
          usersList={usersList ? usersList : []}
          GroupsList={GroupsList ? GroupsList : []}
          load={el.load}
          changeDate={(range) => {
            widgetDateChange(range, el.section_key, el.i);
          }}
          api={api}
          DateOpen={(state, el) => {
            setOpenDateZindex(state, el);
          }}
          start_date={el.start_date}
          end_date={el.end_date}
          loaded={() => {
            loadedItem(el);
          }}
          el={el}
          pin={() => {
            pinItem(el);
          }}
          info={() => {
            infoItemAction(el);
          }}
          remove={() => {
            removeItem(el);
          }}
        />
      );
    } catch {
      return <div>there is no data available</div>;
    }
  }

  function itemsHtml(i: any, el: any) {
    const widget = itemWidegt(i, el);
    return (
      <div
        key={i}
        data-grid={el}
        className={
          OpenDateZindex && OpenDateZindex.i === i
            ? "itemDrag zIndexHigh"
            : "itemDrag "
        }
      >
        {widget}
      </div>
    );
  }
  function createElement(el: any) {
    const i = el.add ? "+" : el.i;
    return itemsHtml(i, el);
  }

  function StateSection(id: any) {
    const array = [...HiddenSections];
    const index = array.indexOf(id);
    if (index !== -1) {
      array.splice(index, 1);
      setHiddenSections(array);
    } else {
      setHiddenSections((oldArray: any) => [...oldArray, id]);
    }
  }

  function sectionClass(id: any, key: any) {
    let className = "dashboardSection ";
    const section_id =
      OpenDateZindex && OpenDateZindex.i && OpenDateZindex.i.split("=")[1];
    if (SlideShowMode) {
      const si = SliderIndex !== key ? "HiddenSection" : "shownSlide";
      className += " SectionSlide  " + si;
    }
    if (Overview) {
      className += " overviewSection ";
    }
    if (!SlideShowMode && !Overview) {
      if (HiddenSections.indexOf(id) > -1) {
        className += " dashboardSectionHidden";
      }
    }
    if (SingleOverview === id) {
      className =
        "dashboardSection slider slideShowDashboard slideShowDashboard SingleOverview";
    }
    if (section_id === id) {
      className += " zIndexHighSection";
    }
    return className;
  }
  function handleChangeTitle(e: any) {
    setSectionTitle(e.target.value);
  }

  function ChangeTitle() {
    const section = [...Sections];
    section[RenameSection].title = SectionTitle;
    setSections(section);
    setRenameSection("");
  }
  function SectionMap() {
    const Secs: any = [];
    if (Slider) {
      Sections.map((section: any) => section.show && Secs.push(section));
    } else {
      Sections.map(
        (section: any) =>
          (Overview || (section.show && !Overview)) && Secs.push(section),
      );
    }

    return Secs.map(
      (section: any, key: any) =>
        section && (
          <div
            id={"section-" + key}
            key={key}
            className={sectionClass(section.id, key)}
          >
            {Overview && (
              <div className={"overviewColTitle"}>
                <span
                  className={
                    section.show
                      ? "overviewColCheck overviewColCheckActive"
                      : "overviewColCheck "
                  }
                  onClick={() => {
                    HideShowSection(key);
                  }}
                >
                  {section.show ? <img src={"./assets/check.svg"} /> : ""}
                </span>
                {section.title}
              </div>
            )}

            <div className={Overview ? "sectionHeaderHidden" : "sectionHeader"}>
              <h2 className={"sectionTitle"}>
                {RenameSection === key ? (
                  <div className={"sectionEditTitle"}>
                    <input
                      type={"text"}
                      onChange={(e) => {
                        handleChangeTitle(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          ChangeTitle();
                        }
                      }}
                      defaultValue={section.title}
                    />
                    <div
                      onClick={() => {
                        setRenameSection("");
                      }}
                    >
                      <img alt={""} src={"./assets/closeCircle.svg"} />
                    </div>
                    <div
                      onClick={() => {
                        ChangeTitle();
                      }}
                    >
                      <img alt={""} src={"./assets/icon-basic-drafts.svg"} />
                    </div>
                  </div>
                ) : (
                  <>
                    {section.title}
                    <div
                      onClick={() => {
                        StateSection(section.id);
                      }}
                      className={"sectionState"}
                    >
                      {HiddenSections.indexOf(section.id) > -1 ? (
                        <img
                          alt={""}
                          src={"./assets/icon-basic-arrow-right.svg"}
                        />
                      ) : (
                        <img
                          alt={""}
                          src={"./assets/icon-basic-arrow-down.svg"}
                        />
                      )}
                    </div>
                  </>
                )}
              </h2>
              <div className={"sectionHeaderSetting"}>
                {!SlideShowMode ? (
                  <>
                    <div
                      className={"sectionHeaderSettingItem"}
                      onClick={() => {
                        console.log("section.id", section.id);
                        console.log("SectionCalendar", section.id);
                        openSectionCalendar(section.id);
                      }}
                    >
                      <img alt={""} src={"./assets/icon-basic-calendar.svg"} />
                    </div>
                    {SectionCalendar === section.id && (
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setSectionCalendar("");
                        }}
                      >
                        <div className="datePickerChart datePickerSection">
                          <DatePicker
                            t={t}
                            defaultData={""}
                            onDateChange={(range: {
                              start_date: string;
                              end_date: string;
                            }) => {
                              setCalendarDate(range);
                            }}
                          />
                          <div className="DatePickerBtn">
                            <button
                              className="cancel"
                              onClick={() => {
                                setSectionCalendar(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              className="ok"
                              onClick={() => {
                                handleDateChange(CalendarDate, key);
                                setSectionCalendar("");
                              }}
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      </OutsideClickHandler>
                    )}

                    <div
                      className={"sectionHeaderSettingItem"}
                      onClick={() => {
                        SingleOverviewFunction(section.id);
                      }}
                    >
                      <img alt={""} src={"./assets/icon-basic-preview.svg"} />
                    </div>
                    <div
                      className={"sectionHeaderSettingItem"}
                      onClick={() => {
                        openSectionSetting(key);
                      }}
                    >
                      <img alt={""} src={"./assets/dots.svg"} />
                      {OpenSetting === key && (
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setOpenSetting("");
                          }}
                        >
                          <div className={"SettingItemSubMenu"}>
                            <div
                              onClick={() => {
                                showAddView(section.id, key);
                              }}
                              className={"SettingItemSubMenuItem"}
                            >
                              <img alt={""} src={"./assets/Plus.svg"} />
                              <span>{t.add_element}</span>
                            </div>
                            <div
                              onClick={() => {
                                SectionMove(true, key);
                              }}
                              className={
                                key === 0
                                  ? "SettingItemSubMenuItem SettingItemSubMenuItemDisable"
                                  : "SettingItemSubMenuItem"
                              }
                            >
                              {key === 0 ? (
                                <img
                                  alt={""}
                                  src={"./assets/ArrowUpDisable.svg"}
                                />
                              ) : (
                                <img alt={""} src={"./assets/ArrowUp.svg"} />
                              )}
                              <span>{t.move_section_up}</span>
                            </div>
                            <div
                              onClick={() => {
                                SectionMove(false, key);
                              }}
                              className={
                                key + 1 === Sections.length
                                  ? "SettingItemSubMenuItem SettingItemSubMenuItemDisable"
                                  : "SettingItemSubMenuItem"
                              }
                            >
                              {key + 1 === Sections.length ? (
                                <img
                                  alt={""}
                                  src={"./assets/ArrowDownDisable.svg"}
                                />
                              ) : (
                                <img alt={""} src={"./assets/ArrowDown.svg"} />
                              )}
                              <span>{t.move_section_down}</span>
                            </div>
                            <div
                              onClick={() => {
                                setRenameSection(key);
                              }}
                              className={"SettingItemSubMenuItem"}
                            >
                              <img
                                alt={""}
                                src={"./assets/icon-basic-drafts.svg"}
                              />
                              <span>{t.rename_section}</span>
                            </div>
                            <div
                              onClick={() => {
                                deleteSection(section.id);
                              }}
                              className={"SettingItemSubMenuItem"}
                            >
                              <img
                                alt={""}
                                src={"./assets/icon-basic-delete.svg"}
                              />
                              <span>{t.delete_section}</span>
                            </div>
                          </div>
                        </OutsideClickHandler>
                      )}
                    </div>
                  </>
                ) : (
                  <div>
                    <img src={CompanyLogo} className={"companyLogoSlideShow"} />
                  </div>
                )}
              </div>
            </div>
            <div
              id={"sectionIdScale"}
              className={Overview ? "ViewsItems" : "origin"}
              style={{
                transform: "scale(" + Scale + ")",
              }}
            >
              {Ready && (
                <ResponsiveReactGridLayout
                  onDragStop={onDragStop}
                  onResizeStop={onResizeStop}
                  onLayoutChange={onLayoutChange}
                  onBreakpointChange={onBreakpointChange}
                  //onResize={(a,b,c)=>{console.log(a,b,c)}}
                  rowHeight={100}
                  margin={[15, 15]}
                  //onDrop={onDrop}
                  isDroppable={true}
                  measureBeforeMount={false}
                  cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
                >
                  {section &&
                  section.items !== undefined &&
                  section.items.length ? (
                    section.items.map((el: any) => createElement(el))
                  ) : (
                    <div
                      onClick={() => showAddView(section.id, key)}
                      key={"add"}
                      data-grid={{
                        type: "add",
                        section_id: "section1",
                        title: "",
                        i: "add",
                        isResizable: false,
                        isDraggable: false,
                        static: false,
                        load: true,
                        x: 0,
                        y: 0,
                        w: 1,
                        h: 2,
                        text: "",
                        class: "addItem",
                      }}
                      className={"static itemDrag addItem"}
                    >
                      <img
                        alt={""}
                        src={"./assets/plus-sign.svg"}
                        className={"add-icon"}
                      />
                    </div>
                  )}
                </ResponsiveReactGridLayout>
              )}
            </div>
          </div>
        ),
    );
  }

  return SectionMap();
}
