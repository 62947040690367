import { AppDispatch } from "../store";
export function setParam(key: string, value: any) {
  if (key === undefined) {
    return (dispatch: AppDispatch) => {
      dispatch({ type: "TEMP", value: value });
    };
  }
  return (dispatch: AppDispatch) => {
    dispatch({ type: key, value: value });
  };
}
