import React, { useEffect, useState, useRef, useCallback } from "react";
import AddWidgetView from "./Layouts/Add/AddView";
import useFullscreen from "@rooks/use-fullscreen";
import SlideShow from "./../Package/Slideshow";
import SectionCreator from "./../Package/SectionCreator";
import DashboardHeader from "./Layouts/DashboardHeader";
import stringDefualt from "./strings.json";
import "./dashboard.css";

const simpleSection: any = {
  id: "section1",
  title: "Section 1",
  show: true,
  items: [],
};
interface Props {
  logo?: string;
  menuOpen: boolean | true;
  reload: boolean | false;
  headers?: Record<string, any>;
  usersList?: Record<string, any>;
  GroupsList?: Record<string, any>;
  WidgetsList: Record<string, any>;
  string?: any;
  saveSetting: (setings: any) => void | undefined;
  getSetting: any;
  api: Record<string, any>;
}
export default function ActionController({
  menuOpen,
  WidgetsList,
  string,
  getSetting,
  saveSetting,
  logo,
  usersList,
  GroupsList,
  api,
  reload,
}: Props) {
  const t = string ? string : stringDefualt;
  const container = useRef();
  const { request, exit } = useFullscreen();
  const [Sections, setSections] = useState<any>([]);
  const [HiddenSections, setHiddenSections] = useState([]);
  const [RenameSection, setRenameSection] = useState("");
  const [SectionCalendar, setSectionCalendar] = useState("");
  const [SectionTitle, setSectionTitle] = useState("");
  const [OpenSetting, setOpenSetting] = useState("");
  const [SizingReload, setSizingReload] = useState(false);
  const [SectionsAction, setSectionsAction] = useState(false);

  const [MenuSideState, setMenuSideState] = useState(menuOpen);
  const [SlideShowMode, setSlideShowMode] = useState(false);
  const [ShowSlideTimer, setShowSlideTimer] = useState(false);
  const [SlideTimer, setSlideTimer] = useState(30000);
  const [Scale, setScale] = useState(1);
  const [CountDown, setCountDown] = useState<any>(null);
  const [SingleOverview, setSingleOverview] = useState(null);
  const [SliderStop, setSliderStop] = useState(false);
  const [SliderIndex, setSliderIndex] = useState(0);
  const [Overview, setOverview] = useState(false);
  const [itemsReady, setItemsReady] = useState(false);
  const [Breakpoint, setBreakpoint] = useState("sm");
  const [OpenDateZindex, setOpenDateZindex] = useState({});
  const [, setTick] = useState(0);
  const [AddView, setAddView] = useState(false);
  const [SectionIdAddedElement, setSectionIdAddedElement] = useState("");
  const [SectionKeyAddedElement, setSectionKeyAddedElement] = useState("");
  const [FixStateItem, setFixStateItem] = useState(false);

  function CountdownRef(countdown: any) {
    if (countdown) {
      setCountDown(countdown.getApi());
    }
  }

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      return 1;
    } else {
      return (
        <span>
          {minutes < 10 ? "0" + minutes : minutes}:
          {seconds < 10 ? "0" + seconds : seconds}
        </span>
      );
    }
  };

  function loadedItem(el: any) {
    //console.log("loadedItem", el.type);
  }

  function saveItems(sections: any = null) {
    if (!sections) {
      sections = Sections;
    }
    if (sections.length) {
      saveSetting(sections);
    }
  }

  function removeItem(el: any) {
    const section_id = el.section_id;
    const sections: any = [...Sections];
    const section: any = Sections.filter(
      (section: any) => section.id === section_id,
    )[0];
    const sectionIndex = sections.findIndex(
      (section: any) => section.id === section_id,
    );
    if (section) {
      const index = section.items.findIndex((item: any) => item.i === el.i);
      section.items.splice(index, 1);
      sections[sectionIndex].items = section.items;
      setSections(sections);
      saveItems(sections);
    }
  }

  function closeAddView() {
    setAddView(!AddView);
  }

  function showAddView(id: any, key: any) {
    setAddView(true);
    setSectionIdAddedElement(id);
    setSectionKeyAddedElement(key);
  }

  async function onBreakpointChange(breakpoint: any) {
    if (breakpoint !== Breakpoint) {
      setBreakpoint(breakpoint);
      const s = [...Sections];
      await setSections([]);
      await setSections(s);
    }
  }

  async function setDefaultSetting() {
    await setSections(simpleSection); //setAdd ;
    await setSectionsAction(!SectionsAction);
  }

  function readItems() {
    if (getSetting !== undefined) {
      if (getSetting && getSetting.length && getSetting[0]) {
        if (getSetting[0].items) {
          let showsSection = false;
          getSetting.map((sec: any) => {
            if (sec.show) {
              showsSection = true;
            }
          });
          if (!showsSection) {
            getSetting[0].show = true;
          }
          setSections(getSetting);
          setItemsReady(true);
        } else {
          setDefaultSetting();
        }
      } else {
        setDefaultSetting();
      }
    }
  }
  async function fetchAPI() {
    await setSections([]);
    await readItems();
  }

  useEffect(() => {
    if (getSetting) {
      fetchAPI();
    }
  }, [reload]);

  useEffect(() => {
    readItems();
  }, [getSetting]);
  useEffect(() => {
    saveItems();
  }, [SectionsAction]);

  useEffect(() => {
    setMenuSideState(menuOpen);
    setItemsReady(false);
    setTimeout(() => {
      setItemsReady(true);
    }, 300);
  }, [menuOpen]);

  function makeId(length: any) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function widthValidator(item: any, width: any) {
    if (item !== undefined) {
      const ratio = item.w - item.h;
      const newHeight = width - ratio;
      if (width <= item.maxW) {
        const size = {
          width: width,
          height:
            newHeight >= item.maxH
              ? item.maxH
              : newHeight < item.minH
              ? item.minH
              : newHeight,
        };
        // console.log("size", size);
        return size;
      } else {
        return false;
      }
    }
  }

  function itemUpdate(
    layouts: any,
    DragItem: any,
    Dragged = false,
    resizing = false,
  ) {
    setFixStateItem(false);
    if (DragItem !== null) {
      const section_id = DragItem.i.split("=")[1];
      if (section_id !== undefined) {
        const section: any = Sections.filter(
          (section: any) => section.id === section_id,
        )[0];
        if (section) {
          const data = section.items.filter(
            (item: any) => item.i === DragItem.i,
          )[0];
          const newObject = DragItem;
          const itemIndex = section.items.findIndex(
            (item: any) => item.i === DragItem.i,
          );
          const sectionIndex = Sections.findIndex(
            (section: any) => section.id === section_id,
          );
          const widthValidation = widthValidator(data, DragItem.w);
          const sections = Sections;
          const newItem = { ...section.items[itemIndex] };
          //newItem.i = makeId(5) + "-" + newObject.i
          newItem.x = newObject.x;
          newItem.y = newObject.y;
          if (widthValidation && resizing) {
            if (
              widthValidation.height === parseInt(widthValidation.height, 10)
            ) {
              newItem.w = newObject.w;
              newItem.h = newObject.h;
            }
          }
          if (Dragged) {
            newItem.i = makeId(5) + "-" + newObject.i;
            newItem.isDraggable = !newObject.isDraggable;
            newItem.isResizable = !newObject.isResizable;
            newItem.static = !newObject.static;
          }
          sections[sectionIndex].items[itemIndex] = newItem;
          setSections(sections);
          setSizingReload(!SizingReload);
          saveItems(sections);
        }
      }
    }
  }

  function pinItem(el: any) {
    itemUpdate(Sections, el, true);
  }

  function infoItemAction(el: any) {
    el.info = !el.info;
    itemUpdate(Sections, el);
  }

  function onDragStop(layouts: any, oldItem: any, newItem: any) {
    itemUpdate(layouts, newItem);
  }

  function onLayoutChange(layouts: any) {
    if (FixStateItem && layouts) {
      itemUpdate(layouts, layouts[layouts.length - 1], false, true);
    }
  }

  function onResizeStop(layouts: any, oldItem: any, newItem: any) {
    itemUpdate(layouts, newItem, false, true);
  }

  function onAddItem(type: any, element: any) {
    element.i = makeId(5) + "-" + element.i;
    const sections = [...Sections];
    let section = sections[element.section_key];
    console.log("section", section);
    if (section.id !== element.section_id) {
      const sectionWithId = sections.findIndex(
        (sec: any) => sec.id === element.section_id,
      );
      section = sections[sectionWithId];
    }
    if (section.items) {
      let finalY = 0;
      section.items.map((w: any, key: any) => {
        console.log("w.y ", w.y);
        finalY += w.y;
      });
      console.log("finalY ", finalY);
      element.y = finalY + 1;
    }

    section.items = section.items.concat(element);
    sections[element.section_key] = section;
    setSections(Sections);
    setAddView(false);
    setFixStateItem(true);
  }
  function widgetDateChange(range: any, section_key: any, i: any) {
    const sections = [...Sections];
    const section = sections[section_key];
    if (section) {
      const index = section.items.findIndex((item: any) => item.i === i);
      if (section && range && section.items[index]) {
        section.items[index].start_date = range.start_date;
        section.items[index].end_date = range.end_date;
        setSections(sections);
      }
    }
  }

  async function deleteSection(id: any) {
    const array = [...Sections];
    const index = await array.findIndex((section) => section.id === id);
    if (index !== -1) {
      array.splice(index, 1);
      if (array.length === 0) {
        await setDefaultSetting();
      } else {
        await setSections(array);
        await setSectionsAction(!SectionsAction);
      }
    }
  }

  function openSectionSetting(id: any) {
    if (OpenSetting === id) {
      setOpenSetting("");
    } else {
      setOpenSetting(id);
    }
  }

  function openSectionCalendar(id: any) {
    if (SectionCalendar === id) {
      setSectionCalendar("");
    } else {
      setSectionCalendar(id);
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 10000, behavior: "smooth" });
  };

  async function AddSection() {
    const lastKey = Sections.length;
    let id: any = "1";
    if (lastKey) {
      id = parseInt(Sections[lastKey - 1].id.match(/\d+/)[0]) + 1;
    }

    const newSection = simpleSection;
    newSection.id = "Section" + id;
    newSection.title = "Section " + id;
    await setSections((oldArray: any) => [...oldArray, newSection]);
    await setSectionsAction(!SectionsAction);
    scrollTop();
  }

  function setSectionsNewKey(arr: any) {
    arr.map((section: any, key: any) => {
      section.items.map((item: any) => {
        item.section_key = key;
      });
    });
    setSections(arr);
  }

  function array_move(arr: any, old_index: any, new_index: any) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        //arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    setSectionsNewKey(arr);
    saveItems();
    return arr;
  }

  function SectionMove(up: any, key: any) {
    setRenameSection("");
    if (Sections.length > 1) {
      const newIndex = up ? key - 1 : key + 1;
      if (newIndex !== -1) {
        array_move(Sections, key, newIndex);
      }
    }
  }

  function handleDateChange(range: any, index: any) {
    const sections = [...Sections];
    const section = sections[index];
    section.items.map((item: any) => {
      item.start_date = range.start_date;
      item.end_date = range.end_date;
      item.i = makeId(5) + "-" + item.i;
    });
    setSections(sections);
    setSizingReload(!SizingReload);
  }

  function scaleSlide() {
    const windowHeight = window.innerHeight;
    const element = document.getElementById("sectionIdScale");
    if (element) {
      const height = element.clientHeight;
      if (height > windowHeight) {
        const sum = windowHeight / height;
        setScale(sum / 1.3);
      } else {
        setScale(1);
      }
    }
  }
  useEffect(() => {
    SlideShowMode &&
      setTimeout(() => {
        scaleSlide();
      }, 1000);
  }, [SliderIndex]);
  function SlideShowModeCall() {
    const Secs = [];
    Sections.map((section: any) => section.show && Secs.push(section));
    if (Secs.length) {
      request(container.current);
      setOverview(false);
      setSlideShowMode(true);
      scaleSlide();
    } else {
      alert("There are no items to show");
    }
  }

  function slideAction(next = true) {
    const Sec = [];
    Sections.map((section: any) => section.show && Sec.push(section));
    if (next) {
      if (SliderIndex + 1 < Sec.length) {
        setSliderIndex(SliderIndex + 1);
      } else {
        setSliderIndex(0);
      }
    } else {
      if (SliderIndex === 0) {
        setSliderIndex(Sec.length - 1);
      } else {
        setSliderIndex(SliderIndex - 1);
      }
    }
    CountDown && CountDown.start();
  }

  function SingleOverviewFunction(id: any) {
    if (SingleOverview !== id) {
      setSingleOverview(id);
      request();
    } else {
      setSingleOverview(null);
      exit();
    }
  }

  function SectionMap(Slider = false) {
    return (
      <SectionCreator
        SectionAction={() => {
          setSectionsAction(!SectionsAction);
        }}
        Ready={itemsReady}
        Breakpoint={Breakpoint}
        api={api}
        t={t}
        usersList={usersList ? usersList : []}
        GroupsList={GroupsList ? GroupsList : []}
        OpenDateZindex={OpenDateZindex}
        CompanyLogo={logo ? logo : ""}
        SliderIndex={SliderIndex}
        Scale={Scale}
        Sections={Sections}
        RenameSection={RenameSection}
        Slider={Slider}
        Overview={Overview}
        SingleOverview={SingleOverview}
        SectionTitle={SectionTitle}
        SlideShowMode={SlideShowMode}
        SectionCalendar={SectionCalendar}
        HiddenSections={HiddenSections}
        OpenSetting={OpenSetting}
        setSections={(sections) => {
          setSections(sections);
        }}
        loadedItem={(el) => {
          loadedItem(el);
        }}
        setRenameSection={(el) => {
          setRenameSection(el);
        }}
        setHiddenSections={(el) => {
          setHiddenSections(el);
        }}
        openSectionCalendar={(el) => {
          openSectionCalendar(el);
        }}
        setSectionCalendar={(el) => {
          setSectionCalendar(el);
        }}
        handleDateChange={(range, key) => {
          handleDateChange(range, key);
        }}
        SingleOverviewFunction={(el) => {
          SingleOverviewFunction(el);
        }}
        openSectionSetting={(el) => {
          openSectionSetting(el);
        }}
        setOpenSetting={(el) => {
          setOpenSetting(el);
        }}
        deleteSection={(el) => {
          deleteSection(el);
        }}
        showAddView={(el, key) => {
          showAddView(el, key);
        }}
        SectionMove={(el, key) => {
          SectionMove(el, key);
        }}
        widgetDateChange={(range, section_key, i) => {
          widgetDateChange(range, section_key, i);
        }}
        setOpenDateZindex={(state, el) => {
          setOpenDateZindex(el);
        }}
        pinItem={(el) => {
          pinItem(el);
        }}
        removeItem={(el) => {
          removeItem(el);
        }}
        infoItemAction={(el) => {
          infoItemAction(el);
        }}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
        setSectionTitle={(name) => {
          setSectionTitle(name);
        }}
      />
    );
  }
  function SlideShowMap() {
    return (
      <SlideShow
        t={t}
        container={container}
        CountdownRef={CountdownRef}
        SlideTimer={SlideTimer}
        renderer={renderer}
        ShowSlideTimer={ShowSlideTimer}
        SliderStop={SliderStop}
        CountDown={CountDown}
        exit={() => {
          exit();
        }}
        slideAction={(el) => {
          slideAction(el);
        }}
        setShowSlideTimer={(el) => {
          setShowSlideTimer(el);
        }}
        setSliderStop={(action) => {
          setSliderStop(action);
        }}
        setSlideShowMode={(action) => {
          setSlideShowMode(action);
        }}
        setScale={(action) => {
          setScale(action);
        }}
        setSlideTimer={(time: any) => {
          setSlideTimer(time);
        }}
      >
        {SectionMap(true)}
      </SlideShow>
    );
  }
  return (
    <>
      <DashboardHeader
        t={t}
        SlideShowModeCall={() => SlideShowModeCall()}
        MenuSideState={MenuSideState}
        AddSection={() => AddSection()}
        setOverview={(action) => setOverview(action)}
        Overview={Overview}
      />
      <div
        className={
          Overview
            ? "dashboardView dashboardViewOverViewState"
            : "dashboardView"
        }
      >
        {AddView && (
          <AddWidgetView
            t={t}
            WidgetsList={WidgetsList}
            sectionId={SectionIdAddedElement}
            sectionKey={SectionKeyAddedElement}
            add={(type, element) => onAddItem(type, element)}
            close={() => closeAddView()}
          />
        )}
        {itemsReady && SlideShowMode ? SlideShowMap() : SectionMap()}
      </div>
    </>
  );
}
