import * as React from "react";
import { useSelector } from "react-redux";
import FrontHeader from "../../utilities/components/FrontHeader";
import FrontFooter from "../../utilities/components/FrontFooter";
import { RootState } from "../../redux/reducers";
import { History } from "history";
// export default connect(mapStateToProps, mapDispatchToProps)(FrontMain);
interface FrontMainProps {
  history: History;
  page: string;
  withIcon?: boolean;
  children: React.ReactNode;
}
export default function FrontMain({
  history,
  page,
  withIcon,
  children,
}: FrontMainProps) {
  const token = useSelector((state: RootState) => state.auth.token);
  return (
    <div className="front-main-container main-container pale-grey-background">
      <FrontHeader token={token} history={history} page={page} />
      <div
        className="base-base"
        style={{ minHeight: 730, position: "relative" }}
      >
        <div style={{ width: "100%", position: "absolute", zIndex: 1 }}>
          <div style={{ zIndex: 1 }} className="front-main-background">
            {withIcon && (
              <img
                src={"/assets/images/front/Landingpath.png"}
                alt="landing-logo"
              />
            )}
          </div>
        </div>
        <div style={{ width: "100%", position: "relative", zIndex: 2 }}>
          {children}
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}
