import * as React from "react";

import { useTranslation, withTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import {
  paymentMethodEdit,
  inviteLink,
  inviteEmails,
} from "../../redux/actions/user.action";
import { useDispatch } from "../../redux/store";
import { DropDown } from "./FromUtils";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { LoadingButton } from "../../utilities/components/FromUtils";
interface Props {
  close: () => void | undefined;
}

export default function InviteTeammates({ close }: Props) {
  const dispatch = useDispatch();
  const [Role, setRole] = useState<Record<any, any>>({ title: "", name: "" });
  const [Link, setLink] = useState("");
  const [Emails, setEmails] = useState("");
  const [Response, setResponse] = useState<Record<any, any>>({});
  const [ResponseView, setResponseView] = useState(false);
  const [Error, setError] = useState<any>(false);
  const company = useSelector((state: RootState) => state.user.company);
  const Token = useSelector((state: RootState) => state.auth.token);
  const [t] = useTranslation();
  const [BtnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    dispatch(inviteLink(Token)).then((link: any) => {
      const title = t("invite.driver");
      setRole({ title: title, name: "DRIVER" });
      if (link.payload) {
        setLink(link.payload.url);
      }
    });
  }, []);

  function sendInvite() {
    setBtnLoading(true);
    const email = Emails.replaceAll(" ", "").split(",").filter(Boolean);
    const data = {
      role: Role.name,
      emails: email,
    };
    dispatch(inviteEmails(data)).then((response: any) => {
      setBtnLoading(false);

      if (!response.error) {
        //close();
        //console.log("HIHIHIHI", response.payload);
        setResponse(response.payload);
        setResponseView(true);
        if (response.payload.emails) {
          setError(response.payload.emails);
        }
      } else {
        if (response.payload && response.payload.invalid) {
          setError("pending invite");
        } else {
          console.log(response.error);
          setError(response.error);
        }
      }
    });
  }

  function isEmptyOrSpaces(str: any) {
    return str === null || str.match(/^ *$/) !== null;
  }

  function emailsAdd(e: any) {
    const val = e.target.value.replaceAll(" ", "");

    setEmails(val.trim());
  }

  return ResponseView ? (
    <div className={"inviteView"}>
      <div className={"inviteViewContent"}>
        <div className="account-modal-close d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              close();
            }}
          >
            <img src="/assets/closeCircle.svg" alt="close-icon" />
          </button>
        </div>
        <div className={"title"}>{t("invite.Invitations_Sent")}</div>
        {Error && (
          <span className={"ErrorSpan"}>
            {console.log("Object.keys(Error)", Object.keys(Error))}
            {typeof Error === "object"
              ? Object.keys(Error).map((key, index) => (
                  <>
                    <span>{key} </span>
                    <span>{Error[key]}</span>
                  </>
                ))
              : Error}
          </span>
        )}
        <div className={"inviteForm"}>
          <div className={"inviteResTable customScrollBar"}>
            <div className={"inviteResTableRow"}>
              <div className={"inviteResTableRowData"}>
                <div className={"divCol"}>{t("invite.Email")}</div>
                {/*<div className={"divCol"}>Role</div>*/}
                <div className={"divCol"}>{t("invite.Status")}</div>
              </div>
            </div>
            {Response.valid &&
              Response.valid.map((inviteItem: any, key: any) => (
                <div className={"inviteResTableRow"} key={key}>
                  <div className={"inviteResTableRowData"}>
                    <div className={"divCol"}>{Object.keys(inviteItem)[0]}</div>
                    <div className={"divCol"}>
                      {inviteItem[Object.keys(inviteItem)[0]]}
                    </div>
                    <div className={""}>
                      <div className={"Accepted inviteResTableRowStatus"}>
                        <img src={"/assets/check.svg"} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            {Response.invalid &&
              Response.invalid.map((inviteItem: any, key: any) => (
                <div className={"inviteResTableRow"} key={key}>
                  <div className={"inviteResTableRowData"}>
                    <div className={"divCol"}>{Object.keys(inviteItem)[0]}</div>
                    <div className={"divCol"}>
                      {inviteItem[Object.keys(inviteItem)[0]]}
                    </div>
                    <div className={""}>
                      <div className={"Pending inviteResTableRowStatus"}>
                        <img src={"/assets/icon-basic-cross-white.svg"} />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div
          className={"sendInvite"}
          style={{ float: "right", display: "block", marginTop: 20 }}
          onClick={() => {
            close();
          }}
        >
          Ok
        </div>
      </div>
    </div>
  ) : (
    <div className={"inviteView"}>
      <div className={"inviteViewContent"}>
        <div className="account-modal-close d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              close();
            }}
          >
            <img src="/assets/closeCircle.svg" alt="close-icon" />
          </button>
        </div>
        <div className={"title"}>
          {t("invite.invite_to")} {company && company.name}
        </div>
        {Error && (
          <span className={"ErrorSpan"}>
            {typeof Error === "object"
              ? Object.keys(Error).map((key, index) => (
                  <>
                    <span>{key}</span>
                    <span>{Error[key]}</span>
                  </>
                ))
              : Error}
          </span>
        )}

        <div className={"inviteForm"}>
          <span>{t("invite.to")}</span>
          <textarea
            onChange={(event) => {
              emailsAdd(event);
            }}
            value={Emails}
            placeholder={"name@<company_email>"}
          >
            {" "}
          </textarea>
          <strong>{t("invite.enter_email")}</strong>
          <span>{t("invite.role")}</span>
          <DropDown
            selectedItem={Role}
            textEntity={"title"}
            preText={""}
            items={[
              { name: "DRIVER", title: t("invite.driver") },
              { name: "MANAGER", title: t("invite.manager") },
              { name: "OFFICER", title: t("invite.officer") },
            ]}
            pngSource={"/assets/icon-basic-user.svg"}
            placeholder={t("invite.select_role")}
            onChange={(selected: any) => {
              setRole({
                ...Role,
                title: selected.title,
                name: selected.name,
              });
            }}
          />
        </div>
        <div className={"inviteLinkFa"}>
          <span>{t("invite.invite_link")}:</span>
          <div className="LinkInvite">{Link}</div>
        </div>
        <div className={"inviteAction"}>
          <div className={"copyLink"}>
            <span>
              <CopyToClipboard text={Link}>
                <button>
                  {" "}
                  <img src={"/assets/icon-basic-link.svg"} />{" "}
                  {t("invite.copy_link")}
                </button>
              </CopyToClipboard>
            </span>
          </div>

          <LoadingButton
            className={"sendInvite"}
            loading={BtnLoading}
            onClick={() => {
              sendInvite();
            }}
            text={t("invite.Send")}
          />
        </div>
      </div>
    </div>
  );
}
