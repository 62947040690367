import * as React from "react";
import { useImmer } from "use-immer";
import { useTranslation, withTranslation } from "react-i18next";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { createRequest } from "../../redux/actions/calendar.action";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Moment from "moment";
import LoadingView from "../../utilities/components/Loading";

interface Props {
  close: () => void | undefined;
}

export default function InviteTeammates({ close }: Props) {
  const dispatch = useDispatch();
  const [Error, setError] = useState<any>(false);
  const [File, setFile] = React.useState(null);
  const [t] = useTranslation();
  const [EndDate, setEndDate] = useState(new Date());
  const [StartDate, setStartDate] = useState(new Date());
  const [ShowEndDate, setShowEndDate] = useState(false);
  const [ShowStartDate, setShowStartDate] = useState(false);
  const [Diff, setDiff] = useState(1);
  const [Loading, setLoading] = useState(false);
  const [state, setState] = useImmer({
    start_date: "",
    end_date: "",
    requester_note: "",
    type: "",
    valid: "",
  });

  function handleFileChange(event: any) {
    if (event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  }

  function requestType(type: any) {
    setState((state) => {
      state.type = type;
    });
  }

  function handelChange(e: any) {
    const name: any = e.target.name;
    const value: any = e.target.value;

    if (name === "requester_note") {
      setState((state) => {
        state.requester_note = value;
      });
    }
  }

  useEffect(() => {
    const start_date = Moment(Moment(StartDate).format("YYYY-MM-DD")).startOf(
      "day",
    );
    const end_date = Moment(Moment(EndDate).format("YYYY-MM-DD")).endOf("day");

    const dateDiff = Math.max(
      Math.round(Moment.duration(end_date.diff(start_date)).asDays()),
      0,
    );
    setDiff(dateDiff);

    if (dateDiff <= 0) {
      setError(t("calendar.error_end_date_b4_start_date"));
    } else {
      setError(false);
    }
  }, [StartDate, EndDate]);

  function sendRequest() {
    const data: any = {
      start_date: Moment(StartDate).format("YYYY-MM-DD"),
      end_date: Moment(EndDate).format("YYYY-MM-DD"),
      requester_note: state.requester_note,
      type: state.type,
    };
    if (data.type === "SICK") {
      data.document = File;
    }

    let fail = false;
    if (!data.start_date) {
      setError(t("calendar.error_select_start_date"));
      fail = true;
    }
    if (!data.end_date) {
      setError(t("calendar.error_select_end_date"));
      fail = true;
    }
    if (!data.type) {
      setError(t("calendar.error_select_request_type"));
      fail = true;
    }
    // if (data.type === "SICK" && !File) {
    //   setError(t("calendar.error_attach_file"));
    //   fail = true;
    // }
    if (!fail) {
      console.log(data);
      setError(false);
      setLoading(true);

      dispatch(createRequest(data)).then((res: any) => {
        setLoading(false);

        if (!res.error) {
          close();
        }
      });
    }
  }

  return (
    <div className={"inviteView"}>
      <div className={"inviteViewContent"}>
        {Loading && <LoadingView />}
        <div className="account-modal-close d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              close();
            }}
          >
            <img src="/assets/closeCircle.svg" alt="close-icon" />
          </button>
        </div>
        <div className={"title"}>{t("calendar.new_request")}</div>
        {Error && (
          <span className={"ErrorSpan"}>
            {typeof Error === "object"
              ? Object.keys(Error).map((key, index) => (
                  <>
                    <span>{key}</span>
                    <span>{Error[key]}</span>
                  </>
                ))
              : Error}
          </span>
        )}

        <div className={"inviteForm"}>
          <span>{t("calendar.request_type")}</span>
          <div className={"RequestTypeFa"}>
            <div
              className={"RequestTypeItem"}
              onClick={() => requestType("PAID")}
            >
              <i className={state.type === "PAID" ? "active" : ""}></i>
              {t("calendar.paid")}
            </div>
            <div
              className={"RequestTypeItem"}
              onClick={() => requestType("UNPAID")}
            >
              <i className={state.type === "UNPAID" ? "active" : ""}></i>
              {t("calendar.unpaid")}
            </div>
            <div
              className={"RequestTypeItem"}
              onClick={() => requestType("SPECIAL")}
            >
              <i className={state.type === "SPECIAL" ? "active" : ""}></i>
              {t("calendar.special")}
            </div>
            <div
              className={"RequestTypeItem"}
              onClick={() => requestType("SICK")}
            >
              <i className={state.type === "SICK" ? "active" : ""}></i>
              {t("calendar.sick")}
            </div>
          </div>
          <div className={"DateInfo"}>
            <div className="DateInfoCol flex1">
              <span>{t("calendar.start_date")}</span>
              <div
                className={"DateInfoColData"}
                onClick={() => {
                  setShowStartDate(true);
                  setShowEndDate(false);
                }}
              >
                <div className={"DatePickerRequest"}>
                  {ShowStartDate && (
                    <Calendar
                      onChange={(data: any) => {
                        setStartDate(data);
                        setShowStartDate(false);
                      }}
                      value={StartDate}
                    />
                  )}
                </div>
                {Moment(StartDate).format("YYYY-MM-DD")}
              </div>
            </div>
            <div className="DateInfoCol flex1">
              <span>{t("calendar.end_date")}</span>

              <div
                className={"DateInfoColData"}
                onClick={() => {
                  setShowEndDate(true);
                  setShowStartDate(false);
                }}
              >
                <div className={"DatePickerRequest"}>
                  {ShowEndDate && (
                    <Calendar
                      onChange={(data: any) => {
                        setEndDate(data);
                        setShowEndDate(false);
                      }}
                      value={EndDate}
                    />
                  )}
                </div>
                {Moment(EndDate).format("YYYY-MM-DD")}
              </div>
            </div>
            <div className="DateInfoCol num_days_input">
              <span>{t("calendar.days")}</span>
              <div className={"DateInfoColData"}>{Diff}</div>
            </div>
          </div>
          {state.type === "SICK" && (
            <div className={"fileDocument"}>
              <input
                onChange={(e) => handleFileChange(e)}
                type={"file"}
                name={"sickDocument"}
              />
            </div>
          )}
          <span>{t("calendar.note")}</span>
          <textarea
            name={"requester_note"}
            onChange={(e: any) => {
              handelChange(e);
            }}
          ></textarea>
        </div>
        <div
          className={"sendRequest"}
          onClick={() => {
            sendRequest();
          }}
        >
          {t("calendar.request_button")}
        </div>
      </div>
    </div>
  );
}
