import * as React from "react";
import { useState, useEffect } from "react";
import SideBar from "../../utilities/components/SideBar";
import HeaderAndMain from "../../utilities/components/HeaderAndMain";
import { useTranslation } from "react-i18next";
export default function MainContainer(props) {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const [load, setLoad] = useState(false);
  const [sideState, setSideState] = useState(true);
  const [sideMenuItems, setSideMenuItems] = useState([
    {
      title: "menu.dashboard",
      name: "Dashboard",
      value: true,
      notifCount: 0,
      page: "/dashboard",
      icon: "/assets/images/SideBars/Dashboard.svg",
      child: [],
    },
    {
      title: "menu.fleet",
      name: "Fleet",
      url: "fleet",
      value: true,
      disable: props.role === "MANAGER" ? false : true,
      notifCount: 0,
      icon: "/assets/images/SideBars/TruckBox.svg",
      child: [
        {
          title: "menu.vehicles",
          parent: "fleet",
          name: "Vehicles",
          page: "/fleet/vehicles",
          value: false,
        },
        {
          title: "menu.trailers",
          parent: "fleet",
          name: "Trailers",
          page: "/fleet/trailers",
          value: false,
        },
        {
          title: "menu.can_devices",
          parent: "fleet",
          name: "CanDevices",
          page: "/fleet/CanDevices",
          value: false,
        },

        // {
        //   parent : 'fleet',
        //   name: 'Routes',
        //   value: false,
        // },
        // {
        //   parent : 'fleet',
        //   name: 'Locations',
        //   value: false,
        // },
      ],
    },
    {
      title: "menu.users",
      name: "Users",
      page: "/users",
      disable: props.role === "MANAGER" ? false : true,
      value: false,
      notifCount: 0,
      icon: "/assets/images/SideBars/User.svg",
      child: [],
    },
    {
      title: "menu.calendar",
      name: "Calendar",
      page: "/calendar",
      value: false,
      notifCount: 0,
      icon: "/assets/images/SideBars/Calendar.svg",
      child: [],
    },
    // {
    //   name: "Messages",
    //   value: false,
    //   notifCount: 20,
    //   icon: "/assets/images/SideBars/Message.svg",
    //   child: [],
    // },
    // {
    //   name: "Reports",
    //   value: false,
    //   notifCount: 0,
    //   icon: "/assets/images/SideBars/Charts.svg",
    //   child: [],
    // },
    {
      title: "menu.settings",
      name: "Settings",
      url: "settings",
      value: false,
      notifCount: 0,
      icon: "/assets/images/SideBars/Settings.svg",
      child: [
        {
          title: t("menu.profile"),
          parent: "settings",
          name: "Profile",
          page: "/settings/profile",
          value: false,
        },
        {
          title: t("menu.account"),
          parent: "settings",
          name: "Account",
          disable: props.role === "MANAGER" ? false : true,
          page: "/settings/account",
          value: false,
        },
        {
          title: "menu.billing",
          parent: "settings",
          name: "Billing",
          disable: props.role === "MANAGER" ? false : true,

          page: "/settings/billing",
          value: false,
        },
        {
          title: "menu.dashboard",
          parent: "settings",
          name: "Dashboard",
          disable: props.role === "MANAGER" ? false : true,
          page: "/settings/dashboard",
          value: false,
        },

        // {
        //   parent: "settings",
        //   name: "Notifications",
        //   value: false,
        // },
        // {
        //   parent: "settings",
        //   name: "Permissions",
        //   value: false,
        // },
      ],
    },
  ]);
  const [subPage, setSubPage] = useState("/");
  const [page, setPage] = useState("/");

  function toggleMenus(oldPage, page, subPage) {
    const items = sideMenuItems;
    if (items.find((item) => item.value === true).child.length > 0) {
      const indexOfParent = items.findIndex((item) => item.name === oldPage);
      if (indexOfParent !== -1) {
        if (items[indexOfParent].child.find((item) => item.value === true)) {
          items[indexOfParent].child.find(
            (item) => item.value === true,
          ).value = false;
        }
      }
    }
    items.find((item) => item.value === true).value = false;
    items.find((item) => item.name === page).value = true;
    if (items.find((item) => item.name === page).child.length > 0) {
      const indexOfParent = items.findIndex((item) => item.name === page);
      if (items[indexOfParent].child.find((item) => item.value === true)) {
        items[indexOfParent].child.find(
          (item) => item.value === true,
        ).value = false;
      }
      items[indexOfParent].child.find(
        (item) => item.name === subPage,
      ).value = true;
    }
    setSideMenuItems(items);
  }
  useEffect(() => {
    if (subPage !== props.subPage) {
      toggleMenus(page, props.page, props.subPage);
      setSubPage(props.subPage);
    }
    if (page !== props.page) {
      toggleMenus(page, props.page, props.subPage);
      setPage(props.page);
    }
  });

  return (
    <div className="main-container pale-grey-background">
      <div className="full-section">
        <div className="header-side-container d-flex ">
          <SideBar
            setSideMenuItems={(sideMenuItems) => {
              setSideMenuItems(sideMenuItems);
            }}
            sideMenuItems={sideMenuItems}
            sideState={sideState}
            setSideState={(sideState) => {
              setSideState(sideState);
            }}
          />
          <HeaderAndMain
            sideState={sideState}
            setSideState={(sideState) => setSideState(sideState)}
          >
            {props.children}
          </HeaderAndMain>
        </div>
      </div>
    </div>
  );
}
