import * as React from "react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import OutsideClickHandler from "react-outside-click-handler";

const specialChars = /[`^&*()=\[\]{};':"\\|,<>\/?~]/;

export class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show !== undefined ? this.props.show : false,
      items: this.props.items,
      itemChoose: this.props.selectedItem,
      selectedItem: this.props.selectedItem,
      inputText: "",
      SearchKey: "",
      SearchRes: this.props.items,
    };
  }
  search() {
    const lowercasedFilter = this.state.SearchKey.toLowerCase();
    const res = [];
    if (this.state.items && this.state.items[0]) {
      this.state.items.filter((item) => {
        Object.keys(item).some((key) => {
          if (item[key].toString().toLowerCase().includes(lowercasedFilter)) {
            res.push(item);
            return item;
          }
        });
      });
    }
    if (
      this.state.SearchKey.length === 1 ||
      this.state.SearchKey.length === 0
    ) {
      this.setState({ SearchRes: this.props.items });
    } else {
      this.setState({ SearchRes: res });
    }
  }
  componentDidUpdate() {
    if (this.state.selectedItem !== this.props.selectedItem) {
      this.setState({ selectedItem: this.props.selectedItem });
    }
    if (this.state.items !== this.props.items) {
      this.setState({ items: this.props.items, SearchRes: this.props.items });
    }
    if (this.props.show !== undefined && this.state.show !== this.props.show) {
      this.setState({ show: this.props.show });
    }
  }

  textGenartor() {
    const {
      placeholder,
      onChange,
      textEntity,
      preText,
      pngSource,
      open,
      withInput,
      search,
    } = this.props;
    let res = "";

    if (this.state.selectedItem !== null) {
      if (
        this.state.selectedItem[textEntity] &&
        this.state.selectedItem[textEntity].toString().length
      ) {
        res = (
          <>
            {this.state.itemChoose && this.state.itemChoose.flag && (
              <img src={this.state.itemChoose.flag} className={"langFlag"} />
            )}
            {preText + this.state.selectedItem[textEntity]}
          </>
        );
      } else {
        if (textEntity) {
          res = this.props.placeholder;
        } else {
          if (this.props.selectedItem) {
            res = this.props.selectedItem;
          } else {
            res = this.props.placeholder;
          }
        }
      }
    } else {
      res = this.props.placeholder;
    }
    if (withInput) {
      const input = (
        <input
          type="text"
          placeholder={this.props.placeholder}
          value={this.state.inputText}
          onChange={(e) => {
            this.setState({ inputText: e.target.value });
            this.props.onChange(e.target.value);
          }}
        />
      );
      return input;
    } else if (search) {
      const input = (
        <input
          className="dropDownSearchInput"
          type="text"
          placeholder={this.props.placeholder}
          value={this.state.SearchKey}
          onChange={(e) => {
            this.setState({ SearchKey: e.target.value });
            this.props.onChange(e.target.value);
            this.search();
          }}
        />
      );
      return input;
    } else {
      return (
        <small
          style={{
            color: this.state.selectedItem !== null ? "#44566c" : "#7a7a7a",
          }}
        >
          {res}
        </small>
      );
    }
  }
  selectAction(item) {
    let slItem = null;
    let acItem = null;
    let sampleSelect = this.state.selectedItem;
    if (this.props.textEntity) {
      slItem = this.state.selectedItem[this.props.textEntity];
      acItem = item[this.props.textEntity];
      sampleSelect[this.props.textEntity] = null;
    } else {
      slItem = this.state.selectedItem;
      acItem = item;
      sampleSelect = null;
    }

    if (slItem === acItem) {
      //('unSelect')
      this.setState({
        selectedItem: sampleSelect,
        itemChoose: sampleSelect,
        inputText: item,
        SearchKey: item[this.props.textEntity],
      });
      this.props.onChange(sampleSelect);
    } else {
      //('select')
      this.setState({
        selectedItem: item,
        itemChoose: item,
        inputText: item,
        SearchKey: item[this.props.textEntity],
      });
      this.props.onChange(item);
    }
  }
  LinkIcon(item, link, textEntity) {
    if (item && link) {
      if (item[textEntity] === this.state.selectedItem[textEntity]) {
        return (
          <img src={"/assets/icon-basic-un-link.svg"} className={"langFlag"} />
        );
      } else {
        return (
          <img src={"/assets/icon-basic-link.svg"} className={"langFlag"} />
        );
      }
    }
  }

  render() {
    const {
      placeholder,
      onChange,
      textEntity,
      preText,
      pngSource,
      open,
      link,
      withInput,
      search,
    } = this.props;
    return (
      <div
        onClick={() => {
          open(!this.state.show);
          this.setState({ show: !this.state.show });
        }}
        className={
          pngSource !== null
            ? "drop-down-menu input-with-icon"
            : "drop-down-menu  input-with-icon left-10"
        }
      >
        {pngSource !== null && <img src={pngSource} alt="drop-down-icon" />}
        <OutsideClickHandler
          onOutsideClick={() => {
            this.setState({ show: false });
          }}
        >
          <div
            className="dropdown-menu customScrollBar"
            style={{ display: this.state.show ? "unset" : "none" }}
          >
            {this.state.items.length > 0 && !search
              ? this.state.items.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      this.selectAction(item);
                      // if(this.state.selectedItem === item){
                      //   this.setState({ selectedItem : item, itemChoose: item });
                      //////   onChange(item);
                      // }else{
                      //   this.setState({ selectedItem: null, itemChoose: null });
                      //   onChange(null);
                      // }
                    }}
                    className="dropdown-item"
                  >
                    {item && item.flag && (
                      <img src={item.flag} className={"langFlag"} />
                    )}
                    {item && item.icon && !link && (
                      <img src={item.icon} className={"langFlag"} />
                    )}
                    {this.LinkIcon(item, link, textEntity)}
                    {link ? (item.name ? item.name : "" + " - ") : ""}
                    {textEntity ? preText + item[textEntity] : preText + item}
                  </button>
                ))
              : ""}

            {this.state.items.length > 0 && search
              ? this.state.SearchRes.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      this.selectAction(item);
                    }}
                    className="dropdown-item"
                  >
                    {item && item.flag && (
                      <img src={item.flag} className={"langFlag"} />
                    )}
                    {item && item.icon && !link && (
                      <img src={item.icon} className={"langFlag"} />
                    )}
                    {this.LinkIcon(item, link, textEntity)}
                    {link ? (item.name ? item.name : "" + " - ") : ""}
                    {textEntity ? preText + item[textEntity] : preText + item}
                  </button>
                ))
              : ""}
            {!this.state.SearchRes.length && this.state.items.length > 0 && (
              <div className="dropDownLoading">Empty...</div>
            )}
          </div>
        </OutsideClickHandler>

        {this.textGenartor()}
        <img
          className="drop-down-icon"
          src={"/assets/images/header/ArrowDown@2x.png"}
          alt="drop-down-icon"
        />
      </div>
    );
  }
}

DropDown.defaultProps = {
  pngSource: null,
  items: [],
  selectedItem: null,
  placeholder: "Select a Item",
  textEntity: "name",
  preText: "",
  onChange: () => null,
  open: () => null,
};

export class InputWithIcon extends React.Component {
  onChangeHandler(val) {
    if (!specialChars.test(val)) {
      this.props.onChange(val);
    }
  }

  render() {
    const {
      pngSource,
      value,
      placeholder,
      type,
      onChange,
      onKeyDown,
      multiline,
      name,
    } = this.props;
    return (
      <div
        className="input-with-icon"
        style={{ height: multiline ? "unset" : "" }}
      >
        {multiline !== true && pngSource !== null && (
          <img src={pngSource} alt="input-icon" />
        )}
        {!multiline && (
          <input
            type={type}
            placeholder={placeholder}
            value={value}
            name={name}
            id={name}
            onKeyDown={(event) => onKeyDown(event)}
            onChange={(event) => this.onChangeHandler(event.target.value)}
          />
        )}
        {multiline && (
          <textarea
            onChange={(event) => onChange(event.target.value)}
            type={type}
            placeholder={placeholder}
            value={value}
          />
        )}
      </div>
    );
  }
}

InputWithIcon.defaultProps = {
  pngSource: null,
  value: "",
  multiline: false,
  type: "text",
  placeholder: "",
  onChange: () => null,
  onKeyDown: () => null,
};

export class CheckboxWithTitle extends React.Component {
  render() {
    const { text, value, linkText, link, onChange } = this.props;
    return (
      <div
        className="checkbox-with-title d-flex align-items-center"
        onClick={() => {
          onChange(!value);
        }}
      >
        <input
          checked={value}
          type="checkbox"
          onChange={(event) => onChange(event.target.checked)}
        />
        <small>
          {text}{" "}
          <Link to={link} className="deep-sky-blue-text">
            {linkText}
          </Link>
        </small>
      </div>
    );
  }
}

CheckboxWithTitle.defaultProps = {
  text: "",
  value: "",
  linkText: "",
  link: "/",
  blueText: () => null,
};

export class LoadingButton extends React.Component {
  render() {
    const { text, disabled, onClick, loading, className, icon } = this.props;
    return (
      <div>
        <button
          className={"LoadingButton " + className}
          disabled={loading ? true : disabled}
          onClick={() => onClick()}
          style={{
            boxShadow: !disabled ? "" : "unset",
            backgroundColor: !disabled ? "" : "#c5c7c7",
          }}
        >
          {loading && (
            <Loader type="Watch" color="#ffffff" height={25} width={25} />
          )}
          {icon && !loading && <img src={icon} />}
          {!loading ? text : ""}
        </button>
      </div>
    );
  }
}

LoadingButton.defaultProps = {
  loading: false,
  disabled: false,
  text: "test",
  onClick: () => null,
};

export class PageContainer extends React.Component {
  //this.props.tableView ? "page-container d-flex flex-column justify-content-center tableViewContainer":'page-container d-flex flex-column justify-content-center'
  render() {
    return (
      <div
        className={"page-container d-flex flex-column justify-content-center"}
      >
        {this.props.children}
      </div>
    );
  }
}

export class FormHeader extends React.Component {
  render() {
    const { text } = this.props;
    return <h1 className="form-header">{text}</h1>;
  }
}

FormHeader.defaultProps = {
  text: "Header",
};

export class FormInformationSection extends React.Component {
  render() {
    const {
      items,
      headerText,
      headerDisable,
      onClick,
      containerClassName,
    } = this.props;
    return (
      <div
        className={`sub-card-containers col-md-5 col-xl-auto mb-3 mb-md-0 ${containerClassName}`}
      >
        {!headerDisable && (
          <div className="form-information-section-header  d-flex align-items-center justify-content-between">
            <small>{headerText}</small>
            <button onClick={() => onClick()}>
              <img
                src={"/assets/images/settings/Task@2x.png"}
                alt="edit-logo"
              />
            </button>
          </div>
        )}
        {Object.keys(items).map((key, index) =>
          key !== "picture" &&
          key !== "id" &&
          key !== "country" &&
          key !== "logo" ? (
            <div
              key={index}
              className="form-information-section d-flex align-items-center justify-content-between"
            >
              <small>{key.replaceAll("_", " ")}</small>
              <small className="information-value">{items[key]}</small>
            </div>
          ) : (
            ""
          ),
        )}
      </div>
    );
  }
}

FormInformationSection.defaultProps = {
  items: [],
  headerText: "headerText",
  onClick: () => null,
};

export class PaymentCard extends React.Component {
  render() {
    const { packageName, items, onClick, paid } = this.props;
    return (
      <div className="payment-card-container d-flex col-sm-12 col-md-4 mb-3 mb-md-0 p-0">
        <div className="payment-card-image d-flex align-items-center justify-content-center">
          <img
            src={"/assets/images/settings/Photo_transp@2x.png"}
            alt="drag-n-drop-logo"
          />
        </div>
        <div className="payment-card-detail d-flex flex-column">
          <small className="header">{packageName}</small>
          {items.map((item, index) => (
            <small key={index}>
              {item.text} <span>{item.value}</span>
            </small>
          ))}
          <button
            onClick={() => onClick()}
            disabled={paid}
            className={!paid ? "pay" : ""}
          >
            {paid ? "Paid" : "Pay"}
          </button>
        </div>
      </div>
    );
  }
}

PaymentCard.defaultProps = {
  packageName: "Package number",
  items: [],
  paid: false,
  onClick: () => null,
};

export class FromInputWithHeader extends React.Component {
  onChangeHandler(val) {
    if (!specialChars.test(val)) {
      this.props.onChange(val);
    }
  }

  render() {
    const {
      value,
      placeholder,
      type,
      onKeyDown,
      label,
      important,
    } = this.props;
    return (
      <div className="input-with-label-container">
        <small>
          {label} <span>{important ? "*" : ""}</span>
        </small>
        <div className="input-with-label">
          <input
            type={type}
            placeholder={placeholder}
            value={value}
            onKeyDown={(event) => onKeyDown(event)}
            onChange={(event) => this.onChangeHandler(event.target.value)}
          />
        </div>
      </div>
    );
  }
}

FromInputWithHeader.defaultProps = {
  important: false,
  label: "Label",
  value: "",
  type: "text",
  placeholder: "",
  onChange: () => null,
  onKeyDown: () => null,
};

export class ImagePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if (this.props.image && this.props.image !== !this.state.file) {
    //     this.setState({file: this.props.image})
    // }
  }

  handleChange(event) {
    if (event.target.files[0]) {
      this.setState({
        file: URL.createObjectURL(event.target.files[0]),
      });
      this.props.imageFile(event.target.files[0]);
    }
  }

  render() {
    const { disabled } = this.props;
    return (
      <div className="image-picker-container ml-auto mr-auto account-image-section d-flex justify-content-center align-items-center">
        {/*{(this.state.file === null) && (*/}
        {/*    <div className="imagePickerText">*/}
        {/*        <img*/}
        {/*            src={"/assets/images/settings/Photo_transp@2x.png"}*/}
        {/*            alt="drag-n-drop-logo"*/}
        {/*        />*/}
        {/*    </div>*/}
        {/*)}*/}
        {/*{this.state.file !== null && (*/}
        <div className="picked-image-container">
          <img
            src={this.state.file === null ? this.props.image : this.state.file}
            alt={this.state.file}
          />
        </div>
        {/*)}*/}
        {!disabled && (
          <div className="image-picker-button-container d-flex justify-content-center align-items-center">
            <label className="custom-file-upload d-flex flex-column   justify-content-center align-items-center">
              <input
                type="file"
                style={{ display: "none" }}
                onChange={this.handleChange}
              />
              <img
                src={"/assets/images/settings/Camera.png"}
                alt="camera-icon"
              />
              <small>Click to change photo</small>
            </label>
          </div>
        )}
      </div>
    );
  }
}

ImagePicker.defaultProps = {
  label: "Picture",
  width: 200,
  height: 200,
  image: null,
  disabled: false,
};
