import * as React from "react";
export default class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "21 March 2020",
    };
  }

  render() {
    return (
      <div className="front-overal-paper fadeIn privacy-policy-container">
        <div className="last-modified-header d-flex align-items-center">
          <img
            src={"/assets/images/front/Calendar@2x.png"}
            alt="calendar-icon"
          />
          <small>
            Last Modified: <span>{this.state.date}</span>.
          </small>
        </div>
        <h2>Privacy Policy</h2>
        <p>
          At FleetSpark UG we believe privacy policies should help you
          understand how your data is being used and provide you with a fair
          choice to control it. A privacy policy is a document outlining any
          information we collect from you across our website
          https://fleetspark.ai and other sites we own and operate.
        </p>
        <h3>Automatic logs</h3>
        <p>
          When you visit our site, our servers may be automatically logging the
          standard data sent to us by your web browser. This data is essential
          to responding to requests your browser sends us and usually includes
          your computers IP address, your browser type and version, the pages
          you visit, the time and date of your visit, the amount of time spent
          on each page, and other details.
        </p>
        <h3>Device data</h3>
        <p>
          When you make a request to our servers your device attaches some extra
          information to the request. This usually includes information like the
          device’s type (e.g. laptop, tablet, phone), device OS (e.g. Windows,
          macOS, Android), unique device identifiers, and sometimes location
          data, depending on the device. To find out exactly what’s sent we
          recommend you check your device settings.
        </p>
        <h3>Personal data</h3>
        <p>
          We might ask you for information like:
          <ul>
            <li>Company name </li>
            <li>Name</li>
            <li>Email address</li>
            <li>Payment information</li>
          </ul>
          This data will only be requested if you access our services or require
          features that need this data to work.
        </p>
        <h3>Legal basis for processing</h3>
        <p>
          We collect and process data about you only where we have a legal basis
          for doing so. The legal bases for processing are when:
          <ul>
            <li>
              It’s necessary for a contract you enter with us (for example, when
              you request a service that we provide and we give it to you);
            </li>
            <li>
              It satisfies a legitimate interest - such as for research and
              development, to market and promote our services, and to protect
              our legal rights and interests;
            </li>
            <li>
              You give us consent to do so (for example if you give us
              permission to email you a newsletter or product update);
            </li>
            <li>
              We need to process your data to comply with a legal obligation;
            </li>
          </ul>
          <p>
            You can withdraw consent at any time, however this will not affect
            any processing that has already happened.
          </p>
          <p>
            We endeavour not to keep personal data for longer than we need it.
            While we have your data, we protect it within acceptable means to
            prevent loss, theft and unauthorised access, disclosure, copying,
            use or modification. If necessary we may retain your data for our
            compliance with legal obligation, or in order to protect your vital
            interests. We cannot guarantee absolute data security and advise
            that no method of electronic transmission or storage is 100% secure.
          </p>
        </p>
        <h3>Collection and use of information</h3>
        <p>
          We may collect, hold, use and disclose data to:
          <ul>
            <li>Enable you to customize your experience of our website;</li>
            <li>
              Enable you to access and use our website, associated applications,
              services and associated social media platforms;
            </li>
            <li>Contact and communicate with you;</li>
            <li>Records keep and/or for administrative purposes;</li>
            <li>Run competitions and or offer additional benefits to you;</li>
            <li>
              Advertise and/or market, including sending you promotional
              information as and when we have your consent;
            </li>
            <li>
              Comply with legal obligations and resolve any disputes that we may
              have;
            </li>
            <li>Consider your employment application with us;</li>
          </ul>
        </p>
        <h3>Disclosure of personal data to third-parties</h3>
        <p>
          We might share personal information to a third-party to enable them to
          provide their services to us.
          <ul>
            <li>
              IT service providers, data storage, hosting and server providers,
              ad networks, analytics, error loggers, debt collectors,
              maintenance or problem-solving providers, marketing or advertising
              providers, professional advisors and payment systems operators;
            </li>
            <li>our employees, contractors and/or related entities;</li>
          </ul>
        </p>
        <h3>International data transfer</h3>
        <p>
          Any personal information we collect is stored and processed in Berlin,
          Germany, and where our partners, affiliates and third-party providers
          maintain facilities. When you give us your information you consent to
          it’s transfer overseas.
        </p>
        <p>
          If this transfer overseas results in transfer outside of the European
          Economic Area (EEA) we shall ensure that it’s protected by appropriate
          safeguards. These safeguards include those approved by the European
          Commission, binding corporate rules, and other legally acceptable
          means.
        </p>
        <p>
          Transfers overseas may result in data you give us being held in
          jurisdiction which are not subject to similar data privacy laws as
          ours, therefore if a third party engages in acts or practices which
          would contravene our laws this may mean you will not be able to seek
          redress.
        </p>
        <h3>Your rights around and controls of your data</h3>
        <p>
          By providing personal data to us, you’re providing consent to us to
          collect, hold, disclose and use your personal data in accordance with
          this privacy policy.
        </p>
        <p>
          If you’re under 16, you must have your parent or guardian’s consent to
          share your personal data prior to the access of our website or use of
          our services.
        </p>
        <p>
          You do not have to provide personal data to us, and can withdraw
          consent at any time. However, if you do not provide certain
          information we may not be able to provide a complete experience for
          our website, products or services.
        </p>
        <h3>Information from third-parties</h3>
        <p>
          If we receive personal information from a third-party, we will protect
          in line with this privacy policy.
        </p>
        <p>
          If you are a third-party providing us personal data, you represent and
          warrant that you have the person’s consent prior to disclosure to us.
        </p>
        <h3>Restriction of processing</h3>
        <p>
          You can restrict the collection and processing of your personal data
          where consent was given in the past. If you have agreed to us using
          your personal data for marketing purposes, you can opt out by
          contacting us.
        </p>
        <h3>Access and data portability</h3>
        <p>
          You may request the personal data we hold on you, and where possible
          we will return this to you in a generally accepted, easily readable
          machine format format such as JSON or CSV. You may also request that
          we transfer this personal information to a third-party. We may request
          verification of your identification in case we doubt the authenticity
          of the request.
        </p>
        <h3>Rectification</h3>
        <p>
          If you believe the information we hold on you is incorrect or
          inaccurate in any way, you have the right to request rectification.
        </p>
        <h3>Notification of data breaches</h3>
        <p>
          In any instance where there is evidence that your personal data is
          breached from our systems, we will openly and transparently notify you
          of it, and take proactive steps to ensure such a breach does not occur
          in the future. We comply with all laws applicable to us in the respect
          of a data breach.
        </p>
        <h3>Complaints</h3>
        <p>
          If you believe that we have breached a relevant data protection law or
          have evidence of any breach, you may contact the Data Protection
          Officer at using the details below and we will investigate your
          complaint.You also have the right to contact any regulatory body or
          data protection authority in relation to your complaint.
        </p>
        <h3>Unsubscribe</h3>
        <p>
          To unsubscribe from our email databases or opt-out of communications,
          please contact us using the opt-out facilities provided in the footer
          of the emails.
        </p>
        <h3>Business transfers</h3>
        <p>
          If FleetSpark UG’s assets are acquired, or if we go out of business,
          we would include data amongst the assets to transfer to any parties
          who acquire us. You acknowledge such transfers might occur, and that
          any parties who acquire us may continue to use your personal
          information according to this policy. In an acquisition context we may
          also have a new data controller and data protection officer, and their
          details would be passed onto you.
        </p>
        <h3>Limits of our policy</h3>
        <p>
          Our site, social media profiles and product/service offerings may link
          to external sites that are not operated by us. In such an event please
          be aware that we cannot control the content or policies of those
          sites, and do not accept responsibility or liability for their privacy
          practices.
        </p>
        <h3>Changes to this policy</h3>
        <p>
          We may update our privacy policy at our discretion if our privacy
          practices change. We will take reasonable steps to let you know of any
          such changes via our website or email communications, etc. Your
          continued use of this site after any changes to our privacy policy and
          practices will be viewed regarded as acceptance of such changes.
        </p>
        <p>
          If we make significant changes to this privacy policy, we will ask for
          your consent again to the amended privacy policy and communicate
          changes.
        </p>
        <p>
          <h6>FleetSpark UG Data Protection Officer</h6>
          <h6>Dr. Navid Nourani Vatani</h6>
          <h6>contact@fleetspark.ai</h6>
        </p>

        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://privacy.labs.metomic.io"
          >
            Powered by metomic
          </a>
        </div>
      </div>
    );
  }
}
