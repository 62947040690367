import React, { useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  Popup,
  Circle,
  FeatureGroup,
} from "react-leaflet";

interface CCMapProps {
  Data: any;
}

export default function CCMap({ Data }: CCMapProps) {
  return (
    <MapContainer
      center={[53.1681801, 12.365584]}
      zoom={4}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {Data &&
        Data.map((route: any, key: any) => (
          <FeatureGroup key={key}>
            <Popup>Popup in FeatureGroup</Popup>
            {route.coordinate &&
              route.coordinate.map((point: any, keyItem: any) => (
                <Circle
                  center={point}
                  key={keyItem}
                  radius={30}
                  pathOptions={{ color: route.properties.color }}
                />
              ))}
          </FeatureGroup>
        ))}
    </MapContainer>
  );
}
