import { rest, aws } from "../apiGateway";
import ky from "ky";

export const logout = async () => {
  const refresh = await localStorage.getItem("refresh_token");
  return rest
    .post("auth/logout/", { body: JSON.stringify({ refresh: refresh }) })
    .json();
};
export const signIn = (json: IloginInput) => {
  return rest
    .post("auth/login/", { json, headers: { Authorization: "" } })
    .json<IloginResponse>();
};
export const signUp = (json: {}) => {
  return rest
    .post("auth/registration/", { json, headers: { Authorization: "" } })
    .json();
};
export const inviteVerify = (json: {}) => {
  return rest
    .post("invite/verify/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const inviteAccept = async ({
  json,
  key,
}: {
  json: any;
  key: string;
}) => {
  return rest
    .post("invite/accept/" + key + "/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const resetPassword = (json: {}) => {
  return rest
    .post("auth/password/reset/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const VerifyEmailResend = (json: {}) => {
  return rest
    .post("auth/registration/resend-verification-email/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const VerifyEmail = (json: {}) => {
  return rest
    .post("auth/registration/account-confirm-email/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const ResetConfirm = (json: {}) => {
  return rest
    .post("auth/password/reset/confirm/", {
      json,
      headers: {
        Authorization: "",
      },
    })
    .json();
};
export const profile = () => {
  return rest.get("profile/").json<IUser>();
};

export const mobileSms = (data: any) => {
  return aws
    .post("two-factor-auth/", {
      json: data,
    })
    .json();
  // return ky
  //   .post("https://stats.fleetspark.ai/two-factor-auth/", {
  //     json: data,
  //        })
  //   .json();
};
