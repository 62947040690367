import React from "react";
import { Route, Redirect } from "react-router-dom";

export function PrivateRoute({ children, user, manager, ...rest }) {
  function access(location) {
    if (rest.token) {
      if (manager) {
        if (user.role === "MANAGER") {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: location },
              }}
            />
          );
        }
      } else {
        return children;
      }
    } else {
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      );
    }
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        rest.beforeRender();
        return access(location);
      }}
    />
  );
}
