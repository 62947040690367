import { Component } from "react";
import { API } from "./BaseService";
import Param from "../../redux/Param";
import { setParam } from "../../redux/actions/param.action";
import store from "../../redux/store";
// import history from '../../redux/history'

export default class BaseDomainService extends Component {
  constructor(props) {
    super(props);
    this.api = new API();
  }

  post_data(url, data, callback) {
    store.dispatch(setParam(Param.LOADING, true));
    this.api
      .POST(url, data)
      .then((response) => {
        callback(response);
        store.dispatch(setParam(Param.LOADING, false));
      })
      .catch((ex) => {
        this.handleErrorAuth(ex);
      });
  }

  post_data_auth(url, data, callback) {
    store.dispatch(setParam(Param.LOADING, true));
    this.api
      .POST_AUTH(url, data)
      .then((response) => {
        callback(response);
        store.dispatch(setParam(Param.LOADING, false));
      })
      .catch((ex) => {
        this.handleErrorAuth(ex);
      });
  }

  get_auth(url, callback) {
    store.dispatch(setParam(Param.LOADING, true));
    this.api
      .GET_AUTH(url)
      .then((response) => {
        callback(response);
        store.dispatch(setParam(Param.LOADING, false));
      })
      .catch((ex) => {
        this.handleErrorAuth(ex);
      });
  }

  get(url, callback) {
    store.dispatch(setParam(Param.LOADING, true));
    this.api
      .GET(url)
      .then((response) => {
        callback(response);
        store.dispatch(setParam(Param.LOADING, false));
      })
      .catch((ex) => {
        this.handleErrorAuth(ex);
      });
  }

  handleErrorAuth(error) {
    store.dispatch(setParam(Param.LOADING, false));
    try {
      if (error.response.status === 403) {
        // clean('token');
        // window.location.replace('/');
      } else {
        let newError = "",
          er = error.response.data;
        while (typeof er !== "string") {
          newError = er[Object.keys(er)[0]];
          er = er[Object.keys(er)[0]];
        }
        if (newError !== null) {
          console.log("newError Base", newError);
        }
      }
    } catch (ex) {
      console.log("newError catch", ex);
    }
  }
}
