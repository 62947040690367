import React, { useEffect } from "react";
import tt from "@tomtom-international/web-sdk-maps";
import { useImmer } from "use-immer";
import WidgetError from "./../Layouts/WidgetError";

interface TomTomMapProps {
  Data: any;
  t: Record<string, any>;
}

export default function TomTomMap({ Data, t }: TomTomMapProps) {
  let vehicleMap: any;
  const [SearchKey, setSearchKey] = React.useState("");
  const [SearchRes, setSearchRes] = React.useState([]);
  const [Error, setError] = React.useState(false);
  const [Map, setMap] = React.useState();
  const [state, setState] = useImmer({
    mapLoad: false,
    showInfo: false,
    feature: {},
    markersOnTheMap: {} as Record<string, any>,
    activeInfo: {
      id: 1,
      name: false,
      vehicle: "-",
      driver: "-",
      location: "-",
      last_seen: "-",
      destination: "-",
      eta: "-",
    } as Record<string, any>,
  });
  const showInfo = (feature: any) => {
    setState((state) => {
      state.showInfo = true;
      state.activeInfo = feature.properties;
      state.feature = feature;
    });
  };
  function ZoomItem(resItem: any, v: any) {
    showInfo(resItem);
    v.easeTo({
      center: resItem.coordinate,
      zoom: 13,
    });
    setSearchRes([]);
  }

  function search() {
    const lowercasedFilter = SearchKey.toLowerCase();
    const res: any = [];
    if (Data && Data[0] && Data[0].properties) {
      Data.filter((item: any) => {
        Object.keys(item.properties).some((key) => {
          if (
            item.properties[key]
              .toString()
              .toLowerCase()
              .includes(lowercasedFilter)
          ) {
            res.push(item);
            return item;
          }
        });
      });
    }
    if (SearchKey === "") {
      setSearchRes([]);
    } else {
      setSearchRes(res);
    }
  }

  useEffect(() => {
    search();
  }, [SearchKey]);
  const createMarker = (feature: Record<string, any>, position: any) => {
    const markerElement = document.createElement("div");
    markerElement.id = "markerId";
    markerElement.className = "marker";
    markerElement.onclick = function () {
      showInfo(feature);
      vehicleMap.easeTo({
        center: feature._geometry.coordinates,
        zoom: vehicleMap.getZoom() > 8 ? 12 : 8,
      });
    };
    const markerContentElement = document.createElement("div");
    markerContentElement.className = "marker-content";
    markerContentElement.style.transform =
      "rotate(" + feature.properties.heading + "deg)";
    markerElement.appendChild(markerContentElement);
    const iconElement = document.createElement("div");
    iconElement.className = "marker-icon";
    let iconName = "";
    switch (feature.properties.driver_status) {
      case "REST":
        iconName = "ChevronCircleRest.svg";
        break;
      case "WORK":
        iconName = "ChevronCircleWork.svg";
        break;
      case "DRIVE":
        iconName = "ChevronCircleDrive.svg";
        break;
      case "HOLIDAY":
        iconName = "ChevronCircleHoliday.svg";
        break;
      case "AVAILABLE":
        iconName = "ChevronCircleAvailable.svg";
        break;
      default:
        iconName = "ChevronCircleUnknown.svg";
    }
    iconElement.style.backgroundImage = "url(./assets/" + iconName + ")";
    markerContentElement.appendChild(iconElement);
    const name = feature.properties.license_plate
      ? feature.properties.license_plate
      : "-";
    const driverName = feature.properties.driver_first_name
      ? feature.properties.driver_first_name +
        " " +
        feature.properties.driver_last_name
      : " - ";
    const popup = new tt.Popup({ offset: 33 }).setHTML(
      t.vehicle_name +
        " : " +
        name +
        "<br>" +
        t.driver_name +
        " : " +
        driverName,
    );
    return new tt.Marker({ element: markerElement, anchor: "bottom" })
      .setLngLat(position)
      .setPopup(popup)
      .addTo(vehicleMap)
      .togglePopup();
  };
  const markersOnTheMap: any = {};

  function refreshMarkers() {
    Object.keys(markersOnTheMap).forEach(function (id) {
      markersOnTheMap[id].remove();
      try {
        delete markersOnTheMap[id];
      } catch (e) {
        console.log("Error delete markersOnTheMap");
      }
    });

    vehicleMap
      .querySourceFeatures("point-source")
      .forEach(function (feature: any) {
        if (feature.properties && !feature.properties.cluster) {
          const id = parseInt(feature.properties.id, 10);
          if (!markersOnTheMap[id]) {
            const newMarker = createMarker(
              feature,
              feature.geometry.coordinates,
            );
            markersOnTheMap[id] = newMarker;
          }
        }
      });
  }
  function ToLocalDate(time: any) {
    const d = new Date(time * 1000);
    return d.toLocaleString();
  }

  const createMap = (points: any[]) => {
    if (tt) {
      setMap(vehicleMap);
      vehicleMap.addControl(new tt.FullscreenControl());
      vehicleMap.addControl(new tt.NavigationControl());
      let eventListenersAdded = true;

      const geoJson = {
        type: "FeatureCollection",
        features: points.map((point) => ({
          type: "Feature",
          id: point.properties.imei,
          geometry: {
            type: "Point",
            coordinates: point.coordinate,
          },
          properties: point.properties,
        })),
      };
      vehicleMap.on("load", function () {
        vehicleMap.addSource("point-source", {
          type: "geojson",
          data: geoJson,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });

        vehicleMap.addLayer({
          id: "clusters",
          type: "circle",
          source: "point-source",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#0081ff",
              4,
              "#0081ff",
              7,
              "#0081ff",
            ],
            "circle-radius": ["step", ["get", "point_count"], 15, 4, 20, 7, 25],
            "circle-stroke-width": 3,
            "circle-stroke-color": "white",
            "circle-stroke-opacity": 1,
          },
        });
        vehicleMap.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "point-source",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-size": 16,
          },
          paint: {
            "text-color": "white",
          },
        });
        vehicleMap.on("click", "clusters", function (e: any) {
          const features = vehicleMap.queryRenderedFeatures(e.point, {
            layers: ["clusters"],
          });
          const clusterId = features[0].properties?.cluster_id;
          vehicleMap
            .getSource("point-source")
            .getClusterExpansionZoom(
              clusterId,
              function (err: any, zoom: number) {
                if (err) {
                  return;
                }
                vehicleMap.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom + 0.5,
                });
              },
            );
        });
        vehicleMap.on("data", function (e: any) {
          // if (e.sourceId !== 'point-source' || !vehicleMap.getSource('point-source').loaded()) {
          //     return;
          // }

          refreshMarkers();

          if (!eventListenersAdded) {
            vehicleMap.on("move", refreshMarkers);
            vehicleMap.on("moveend", refreshMarkers);
            eventListenersAdded = true;
          }
        });

        vehicleMap.on("mouseenter", "clusters", function () {
          vehicleMap.getCanvas().style.cursor = "pointer";
        });
        vehicleMap.on("mouseleave", "clusters", function () {
          vehicleMap.getCanvas().style.cursor = "";
        });
      });
    }
  };
  useEffect(() => {
    console.log("useEffect TT");
  }, []);
  useEffect(() => {
    console.log("useEffect TT DATA");

    if (vehicleMap) {
      vehicleMap.remove();
    }

    if (Data && Data[0] && Data[0].properties) {
      setError(false);
      vehicleMap = tt.map({
        key: "W0JoJKtsRvNSOYqSlWfGRSITaL3Q9u0I",
        container: "TomTomMap",
        center: [13.27683, 52.500395],
        zoom: 3,
      });
      createMap(Data);
    } else {
      setError(true);
    }
  }, [Data]);

  return (
    <>
      <div className={"SearchVehicleStatus"}>
        <div className="input-with-icon" style={{ height: "" }}>
          <img src={"./assets/icon-basic-search.svg"} alt="input-icon" />

          <input
            type={"text"}
            placeholder={t.Search}
            value={SearchKey}
            onChange={(event) => setSearchKey(event.target.value)}
          />
        </div>
        <div className={"searchResFa"}>
          {SearchRes.map(
            (resItem: any, key: any) =>
              resItem.properties && (
                <div
                  className={"searchResItem"}
                  onClick={() => {
                    ZoomItem(resItem, Map);
                  }}
                >
                  <div>
                    <div className={"imgSearchFa"}>
                      <img
                        src={
                          resItem.properties.profile_picture
                            ? resItem.properties.profile_picture
                            : "./assets/User.svg"
                        }
                      />
                      <i className={resItem.properties.driver_status}></i>
                    </div>
                  </div>
                  <div className={"searchResItemInfo"}>
                    <div>
                      <div>
                        <span>{t.Driver}:</span>
                        {resItem.properties.driver_first_name +
                          " " +
                          resItem.properties.driver_last_name}
                      </div>
                      <div>
                        <span>{t.Last_Seen}:</span>
                        {ToLocalDate(resItem.properties.last_seen)}
                      </div>
                      <div>
                        <span>{t.Location}:</span>
                        {resItem.properties.address}
                      </div>
                    </div>
                    <div>
                      <div>
                        <span>{t.Vehicle}:</span>
                        {resItem.properties.license_plate}
                      </div>
                      <div>
                        <span>{t.ETA}:</span>
                        {resItem.properties.eta}
                      </div>
                      <div>
                        <span>{t.Destination}:</span>
                        {resItem.properties.destination}
                      </div>
                    </div>
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
      <div className={"mapViewData"}>
        <div id="TomTomMap"></div>
        {Error ? (
          <WidgetError title={t.no_data_available} />
        ) : (
          <>
            {state.showInfo && (
              <div className={"mapViewInfo"}>
                <div
                  className={"mapViewInfoClose"}
                  onClick={() =>
                    setState((state) => {
                      state.showInfo = false;
                    })
                  }
                >
                  <img src={"./assets/icon-basic-cross.svg"} />
                </div>
                <div className={"driverDrawerHeader"}>
                  {/*<img src={"./assets/profile.jpg"} />*/}
                  <div className={"driverDrawerHeaderImgBox"}>
                    <img
                      src={
                        state.activeInfo.profile_picture
                          ? state.activeInfo.profile_picture
                          : "./assets/User.svg"
                      }
                    />
                  </div>
                  <div className={"driverDrawerHeaderInfo"}>
                    <span>
                      {state.activeInfo.driver_first_name
                        ? state.activeInfo.driver_first_name +
                          " " +
                          state.activeInfo.driver_last_name
                        : " - "}
                    </span>
                    <strong>{state.activeInfo.license_plate}</strong>
                  </div>
                </div>
                <div className={"driverDrawerStatus"}>
                  <span>{t.Status}:</span>
                  <div className={"driverDrawerStatusText"}>
                    <i className={state.activeInfo.driver_status}></i>
                    {state.activeInfo.driver_status
                      ? state.activeInfo.driver_status
                      : "-"}
                  </div>
                </div>
                <div className={"driverDrawerViewFa customScrollBar"}>
                  <div className={"driverDrawerView "}>
                    <div className={"driverDrawerData"}>
                      <div className={"title"}>{t.Trip}</div>
                      <div className={"driverDrawerDataCol"}>
                        <div className={"driverDrawerDataColItem"}>
                          <img src={"./assets/LastSeen.svg"} />
                          <div className={"driverDrawerDataColItemInfo"}>
                            <strong>{t.Last_Seen}</strong>
                            <span>
                              {state.activeInfo.last_seen
                                ? ToLocalDate(state.activeInfo.last_seen)
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className={"driverDrawerDataColItem"}>
                          <img src={"./assets/ETA.svg"} />
                          <div className={"driverDrawerDataColItemInfo"}>
                            <strong>{t.ETA}</strong>
                            <span>
                              {state.activeInfo.eta
                                ? state.activeInfo.eta
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className={"driverDrawerDataColItem"}>
                          <img src={"./assets/Chevron.svg"} />
                          <div className={"driverDrawerDataColItemInfo"}>
                            <strong>{t.Location}</strong>
                            <span>
                              {state.activeInfo.address
                                ? state.activeInfo.address
                                : " - "}
                            </span>
                          </div>
                        </div>
                        <div className={"driverDrawerDataColItem"}>
                          <img src={"./assets/Location.svg"} />
                          <div className={"driverDrawerDataColItemInfo"}>
                            <strong>{t.Destination}</strong>
                            <span>
                              {state.activeInfo.destination
                                ? state.activeInfo.destination
                                : "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"driverDrawerData"}>
                      <div className={"title"}>{t.Today}</div>
                      <div className={"driverDrawerDataCol"}>
                        <div className={"driverDrawerDataColItem"}>
                          <img src={"./assets/Trip.svg"} />
                          <div className={"driverDrawerDataColItemInfo"}>
                            <strong>{t.Trip_Counts}</strong>
                            <span>
                              {state.activeInfo.trip_counts
                                ? state.activeInfo.trip_counts
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className={"driverDrawerDataColItem"}>
                          <img src={"./assets/Distance.svg"} />
                          <div className={"driverDrawerDataColItemInfo"}>
                            <strong>{t.Distance}</strong>
                            <span>
                              {state.activeInfo.driven_distance
                                ? state.activeInfo.driven_distance
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className={"driverDrawerDataColItem"}>
                          <img src={"./assets/Clock.svg"} />
                          <div className={"driverDrawerDataColItemInfo"}>
                            <strong>{t.Driving_Time}</strong>
                            <span>
                              {state.activeInfo.driving_time
                                ? state.activeInfo.driving_time
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className={"driverDrawerDataColItem"}>
                          <img src={"./assets/Remaining.svg"} />
                          <div className={"driverDrawerDataColItemInfo"}>
                            <strong>{t.Remaining_Time}</strong>
                            <span>
                              {state.activeInfo.driving_remaining_time
                                ? state.activeInfo.driving_remaining_time
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className={"driverDrawerDataColItem"}>
                          <img src={"./assets/ClockPause.svg"} />
                          <div className={"driverDrawerDataColItemInfo"}>
                            <strong>{t.Next_Break}</strong>
                            <span>
                              {state.activeInfo.next_break
                                ? ToLocalDate(state.activeInfo.next_break)
                                : "-"}
                            </span>
                          </div>
                        </div>
                        <div className={"driverDrawerDataColItem"}>
                          <img src={"./assets/Rest.svg"} />
                          <div className={"driverDrawerDataColItemInfo"}>
                            <strong>{t.Next_Rest_Period}</strong>
                            <span>
                              {state.activeInfo.next_rest_period
                                ? state.activeInfo.next_rest_period
                                : "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
