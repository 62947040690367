import React, { useEffect, useRef, useLayoutEffect } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import LayoutHeader from "./../Layouts/Header/LayoutHeader";
import TTMAP from "./TTMap";
import { apiAction } from "./ApiCall";
import CCMap from "./CCMap";
import LoadingView from "./../Layouts/Loading";
import Table from "./Table/Table";
import { Bar, Line, Radar, Doughnut, HorizontalBar } from "react-chartjs-2";
import * as annotationPlugin from "chartjs-plugin-annotation";
import WidgetError from "../Layouts/WidgetError";
import {
  ArrowWidgetEquel,
  ArrowWidgetUp,
  ArrowWidgetDown,
} from "../../../../Components/svg";
//import {Context} from 'chartjs-plugin-datalabels';
//import "chartjs-plugin-datalabels";
interface ChartProps {
  pin: () => void | undefined;
  info: () => void | undefined;
  SectionAction: () => void | undefined;
  remove: () => void | undefined;
  el: Record<string, any>;
  load: boolean | true;
  loaded?: () => void | undefined;
  DateOpen?: (v: any, id: any) => void | undefined;
  changeDate: (range: object) => void | undefined;
  start_date: string;
  end_date: string;
  usersList?: Record<string, any>;
  GroupsList?: Record<string, any>;
  t: Record<string, any>;
  api: Record<string, any>;
  Breakpoint: string;
}

export default function WidgetCreator({
  el,
  pin,
  Breakpoint,
  info,
  remove,
  load,
  loaded,
  start_date,
  end_date,
  changeDate,
  DateOpen,
  usersList,
  GroupsList,
  SectionAction,
  api,
  t,
}: ChartProps) {
  const [ChartConfig, setChartConfig] = React.useState<Record<string, any>>();
  const [Response, setResponse] = React.useState<Record<string, any>>({});
  const [Loading, setLoading] = React.useState(true);
  const [Request, setRequest] = React.useState(false);
  const [Date, setDate] = React.useState<Record<string, any>>({
    start_date: null,
    end_date: null,
  });
  const [Error, setError] = React.useState(false);
  const [User, setUser] = React.useState<Record<string, any>>({});
  const [Group, setGroup] = React.useState("");

  const [Data, setData] = React.useState<Record<string, any>>({});

  const commaNumber = require("comma-number");
  React.useEffect(() => {
    if (load) {
      setLoading(true);
      setData([]);
    }
  }, [start_date, end_date]);

  function DataGenerate(chartData: any) {
    //console.log("DataGenerate", chartData);
    if (chartData.status !== 200 && chartData.status !== undefined) {
      setError(true);
      setLoading(false);
    } else {
      if (!el.response) {
        if (chartData && chartData.status !== 401) {
          setError(false);
          setResponse(chartData);

          if (chartData.user) {
            setUser(chartData.user);
          } else if (el && el.user) {
            //setUser(el.user);
          }
          if (el && el.group) {
            setGroup(el.group);
          }

          if (chartData.data && chartData.data.labels) {
            setChartConfig(chartData.options);
            const res = {
              labels: chartData.data.labels,
              datasets: chartData.data.datasets,
            };
            setData(res);
          } else {
            if (chartData.data && chartData.data.bars) {
              setData(chartData.data);
            } else {
              if (typeof chartData !== "string") {
                setData(chartData);
              }
            }
          }
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      } else {
        setResponse(el.response);
        setData(el.response && el.response.data);
        setChartConfig(el.response && el.response.options);
      }
    }
  }

  function request(Params: any) {
    apiAction(el.url, api, Params)
      .then((chartData: any) => {
        DataGenerate(chartData);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }
  const handleDateChange = (range: {
    start_date: string;
    end_date: string;
  }) => {
    setDate(range);
    setLoading(true);
    setRequest(false);
    changeDate(range);
    let user_id = undefined;
    if (el.user) {
      user_id = el.user.id;
    }
    if (User && (User.id || User.user_id)) {
      user_id = User.id;
    }
    let user_group = undefined;
    if (Group) {
      user_group = Group;
    }
    const params = {
      start_date: range.start_date,
      end_date: range.end_date,
      user_id,
      user_group,
    };
    request(params);
  };
  function SmallWidget() {
    const Width = window.innerWidth - 340;
    let widgetWidth = 0;
    if (Breakpoint === "lg" || Breakpoint === "md") {
      widgetWidth = (Width / 3) * el.w;
    } else {
      widgetWidth = (Width / 2) * el.w;
    }
    if (widgetWidth < 475) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    if (Data && Loading && !Request) {
      loaded && loaded();
      setRequest(true);
      setDate({ start_date, end_date });
      //const params = { start_date, end_date };
      let user_id = undefined;
      if (el.user_select && el.user) {
        user_id = el.user && el.user.id;
      }
      let user_group = undefined;
      if (el.group) {
        user_group = el.group;
        //setGroup(user_group)
      }
      const params = {
        start_date: start_date,
        end_date: end_date,
        user_id,
        user_group,
      };

      request(params);
    } else {
      setError(false); //checking error;
    }
  }, []);

  function ArrowsChart(op: any) {
    const data = op && op.details && op.details.rows;
    let Arrows: any = null;

    Arrows =
      data &&
      data.map((row: any, key: any) => {
        const number = 360 * (row.data.replace("%", "") / 100);
        const res = (
          <span
            key={key}
            className="ChartArrow"
            style={{
              transform:
                "rotate(" + number + "deg) translate(0px) rotate(0deg)",
            }}
          >
            <img src={row.icon} />
          </span>
        );
        return res;
      });
    return <div className="ArrowsChartFa">{Arrows}</div>;
  }

  function customLegend(Response: any) {
    if (Response) {
      const info = el.info === undefined ? true : el.info;
      if (info && !SmallWidget()) {
        const leg: any = Response.details && Response.details.rows;
        const average: any = Response.details && Response.details.average;
        const description: any =
          Response.details && Response.details.description;
        const short_description: any =
          Response.details && Response.details.short_description;
        let res = null;
        let row = null;
        let HaveLegend = false;
        if (average) {
          HaveLegend = true;
          row = (
            <div className="PieAverage">
              <div className={"PieAverage-iconFa"}>
                <div className="PieAverage-icon">
                  {average.trend === "up" ? (
                    <ArrowWidgetUp color={average.trend_color} />
                  ) : average.trend === "equal" ? (
                    <ArrowWidgetEquel color={average.trend_color} />
                  ) : average.trend === "down" ? (
                    <ArrowWidgetDown color={average.trend_color} />
                  ) : (
                    ""
                  )}
                </div>
                {average.change ? (
                  <div
                    className={"PieAverage-number"}
                    style={{
                      backgroundColor: average.color
                        ? average.color
                        : "#FF3D57",
                    }}
                  >
                    {average.change} %
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={"PieAverageValue"}>
                {commaNumber(average.current_value)}
                <span>{average.current_value_type}</span>
              </div>
            </div>
          );
        }
        if (leg && leg[0]) {
          HaveLegend = true;
          res = leg.map((row: any, key: any) => {
            return (
              <div className={"legendRow"} key={key}>
                {row.icon ? (
                  <img
                    src={row.icon}
                    style={{ transform: "rotate(" + row.rotation + "deg)" }}
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor: row.color ? row.color : "#8697a8",
                    }}
                    className={"legBox"}
                  ></div>
                )}
                <span>{row.name}</span>
                <strong>{row.data}</strong>
              </div>
            );
          });
        }
        if (HaveLegend) {
          return (
            <div
              className={
                el.type === "none" ? "CustomLegendFa w100" : "CustomLegendFa "
              }
            >
              <div className="short_description">{short_description}</div>
              <>{row}</>
              <div className="CustomLegend">{res}</div>
              <div className="CustomLegendDescription">{description}</div>
            </div>
          );
        }
      }
    }
  }

  function ChartRender(el: any) {
    //try {
    //console.log("elelelel", el);
    //console.log("res", Response.empty);
    if (!el) {
      return (
        <div className={"widgetError"}>
          <h3>Server Error</h3>
        </div>
      );
    }
    if (Response.empty) {
      //console.log("YYYYYY");

      return (
        <div className={"widgetError"}>
          <h3>{Response.text}</h3>
        </div>
      );
      if (el.type === "none") {
        return null;
      }
    }
    if (el.type === "horizontalBar") {
      return (
        <div className={"chartsContainer"}>
          {Data && (
            <div className="HorizintlBar">
              <div className="HorizintlBarArrows">
                {Data.arrows &&
                  Data.arrows.map(
                    (arrow: any) =>
                      arrow && (
                        <span style={{ left: arrow.left }}>
                          <img src={arrow.icon} />
                        </span>
                      ),
                  )}
              </div>
              <div className="HorizintlBarBars">
                {Data.bars &&
                  Data.bars.map(
                    (bar: any) =>
                      bar && (
                        <div
                          style={{
                            backgroundColor: bar.color,
                            width: bar.percentage,
                          }}
                        ></div>
                      ),
                  )}
              </div>
              <div className="HorizintlBarNubmers">
                {Data.numbers &&
                  Data.numbers.map((number: any, key: any) => (
                    <span key={key} style={{ left: number && number.left }}>
                      {number && number.number}
                    </span>
                  ))}
              </div>
              <div className="HorizintlBarDesc">{Data && Data.description}</div>
            </div>
          )}
        </div>
      );
    }
    if (el.type === "BarChart") {
      return (
        <div className={"chartsContainer"}>
          <Bar data={Data} options={ChartConfig} plugins={[annotationPlugin]} />
        </div>
      );
    }
    if (el.type === "LineChart") {
      return (
        <div className={"chartsContainer"}>
          <Line
            data={Data}
            options={ChartConfig}
            plugins={[annotationPlugin]}
          />
        </div>
      );
    }
    if (el.type === "PieChart") {
      return (
        <div className={"chartsContainer PieChartContainer"}>
          <Doughnut
            data={Data}
            options={ChartConfig}
            plugins={[annotationPlugin]}
          />
        </div>
      );
    }
    if (el.type === "DoughnutChart") {
      return (
        <div className={"chartsContainer"}>
          <div className={"PieChartGrid"}>
            {ArrowsChart(Response)}
            <Doughnut
              data={Data}
              options={ChartConfig}
              plugins={[annotationPlugin]}
            />
            <div className={"DoughnutPercent"}>
              <div>
                {Response.details && Response.details.percentage.data}
                {Response.details && Response.details.percentage.type}
              </div>
              <span>
                {Response.details && Response.details.percentage.label}
              </span>
            </div>
          </div>
        </div>
      );
    }
    if (el.type === "RadarChart") {
      return (
        <div className={"chartsContainer"}>
          <Radar
            data={Data}
            options={ChartConfig}
            plugins={[annotationPlugin]}
          />
        </div>
      );
    }
    if (el.type === "CCPoint") {
      const legends: any = Data && Data.legends;
      return (
        <div className={"CCPointContainer"}>
          <CCMap Data={Data.data} />
          {legends && (
            <div className="MapLegend">
              {legends.map((row: any, key: any) => (
                <div className={"legendRow"} key={key}>
                  <div
                    style={{
                      backgroundColor: row.color ? row.color : "#8697a8",
                    }}
                    className={"legBox"}
                  ></div>
                  <span>{row.name}</span>
                  <strong>{row.value}</strong>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
    if (el.type === "Table") {
      return (
        <div className="ranking itemRow customScrollBar">
          <Table
            getData={(Params: any) => {
              request(Params);
            }}
            tableData={Data.data}
            tableType={Data.title}
            t={t}
          />
        </div>
      );
    }
    if (el.type === "VehicleStatus") {
      return (
        <div className={"TTMapContainer"}>
          <TTMAP Data={Data.data} t={t} />
        </div>
      );
    }
    // } catch {
    //   return "there is no data available";
    // }
  }

  function userChange(u: any) {
    // //console.log("user", user);
    // //console.log("User", User);
    if (u.id !== User.user_id) {
      if (el.user_select) {
        const user = u === User ? {} : u;

        el.user = user;
        setLoading(true);
        setRequest(true);
        SectionAction();
        const user_id = user.id;
        const user_group = Group;
        const params = { start_date, end_date, user_id, user_group };
        setUser(user);
        changeDate({ start_date, end_date });
        request(params);
      }
    }
  }

  function groupSelect(gp: any) {
    if (gp) {
      if (el.group_select) {
        const user_group = gp === Group ? "" : gp;
        el.group = user_group;
        setLoading(true);
        setRequest(true);
        SectionAction();
        let user_id = null;
        if (User.user_id) {
          user_id = User.user_id;
        } else if (el.user) {
          user_id = el.user.id;
        }
        const params = { start_date, end_date, user_id, user_group };
        setGroup(user_group);
        changeDate({ start_date, end_date });
        request(params);
      }
    }
  }
  useEffect(() => {
    //console.log("useEffect User", User);
  }, [User]);

  return (
    <div className={"itemRow"}>
      {Loading && <LoadingView />}
      {Error && <WidgetError />}
      <LayoutHeader
        t={t}
        userSelect={(d: any) => {
          userChange(d);
        }}
        Groups={GroupsList}
        GroupSelect={(group: any) => {
          groupSelect(group);
        }}
        SmallWidget={SmallWidget()}
        usersList={usersList ? usersList : []}
        userSelected={User}
        groupSelected={Group}
        title={Response && Response.title ? Response.title : el.title}
        onDateChange={(range) => handleDateChange(range)}
        defaultData={{ start_date, end_date }}
        el={el}
        userOption={el.user_select}
        pin={() => pin()}
        info={() => info()}
        remove={() => remove()}
        DateToggle={(state: any, el: any) => {
          DateOpen && DateOpen(state, el);
        }}
        calendarDisable={el.type === "VehicleStatus" ? true : false}
      />
      <div className="Chart-data">
        {customLegend(Response)}
        {!Loading && !Error && ChartRender(el)}
      </div>
    </div>
  );
}
