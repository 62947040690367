import * as React from "react";
import { useSelector } from "react-redux";
import Param from "../../redux/Param";
import { useDispatch } from "../../redux/store";
import {
  InputWithIcon,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import { signUpValidator } from "../../utilities/functions/authValidator";
import { signUp } from "../../redux/actions/auth.action";
import { History } from "history";
import { useTranslation } from "react-i18next";
interface HomeProps {
  history: History;
}
export default function Home({ history }: HomeProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const myRef: any = React.useRef(null);
  const loading = useSelector((state: any) => state.param[Param.LOADING]);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    if (history.action === "PUSH") {
      myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });
  const signUpHandler = () => {
    const data = {
      username: email,
      email: email,
      password1: password,
      password2: password,
    };
    if (signUpValidator(data)) {
      dispatch(signUp(data)).then(() => {
        history.push("/login");
      });
    }
  };
  return (
    <div ref={myRef}>
      <div className="front-main-container-overlay">
        <h1>{t("home.heading")}</h1>
        <h2>{t("home.sub-heading")}</h2>
        <div className="front-landing-from">
          <InputWithIcon
            value={email}
            placeholder={t("home.email")}
            pngSource={"/assets/images/auth/@2x.png"}
            onChange={(email: string) => setEmail(email)}
          />
          <InputWithIcon
            value={password}
            type="password"
            placeholder={t("home.password")}
            pngSource={"/assets/images/auth/Padlock2x.png"}
            onChange={(password: string) => setPassword(password)}
          />
          <LoadingButton
            loading={loading}
            onClick={() => signUpHandler()}
            text={t("home.get-started")}
          />
        </div>
        <div className="front-landing-information-section">
          <div>
            <small>{t("home.advanced-navigation")}</small>
            <p>{t("home.advanced-navigation-detail")}</p>
          </div>
          <div className="line" />
          <div>
            <small>{t("home.realtime-monitoring")}</small>
            <p>{t("home.realtime-monitoring-detail")}</p>
          </div>
          <div className="line" />
          <div>
            <small>{t("home.active-learning")}</small>
            <p>{t("home.active-learning-detail")}</p>
          </div>
        </div>

        <div className="front-landing-information-section-individual">
          <div>
            <small>{t("home.one-on-one-coaching")}</small>
            <p>{t("home.one-on-one-coaching-detail")}</p>
          </div>
          <div>
            <small>{t("home.software")}</small>
            <p>{t("home.software-detail")}</p>
          </div>
        </div>
      </div>
      <div className="platoon-ready-section">
        <div>
          <h2>{t("home.fleet-ready")}</h2>
          <h3>{t("home.fleet-ready-detail")}</h3>
        </div>
        <button
          onClick={() => window.location.replace("/contact-us/book-call")}
        >
          {t("home.free-consultation")}
        </button>
      </div>
    </div>
  );
}
