import { aws, rest } from "../apiGateway";

export const getVehicleStatus = async () => {
  return await aws.get("fleet-status/").json();
  //return await aws.get("vehicles/status/").json<IVehicleStatusResponse>();
};
export const getCompanyCC = async ({
  start_date,
  end_date,
}: {
  start_date: string;
  end_date: string;
}) => {
  return await aws
    .get(`companies/cc/`, { searchParams: { start_date, end_date } })
    .json<ITrendsResponse<ICompanyCCTrends>>();
};
export const getWidgets = async () => {
  return await aws.get(`list-api/`).json();
};
export const groups = async () => {
  return await rest.get(`users/locations/`).json();
};

export const widgetsPermissions = async (json: any) => {
  try {
    return await aws.post(`widgets/permissions/`, { json }).json();
  } catch (e: any) {
    //console.log("E",e.response)
    return e.response;
  }
};
export const apiCallWidget = async (url: string) => {
  try {
    return await aws.get(`${url}`).json();
  } catch (e: any) {
    //console.log("E",e.response)
    return e.response;
  }
};
export const getUsersCC = async ({
  start_date,
  end_date,
  params,
}: {
  start_date: string;
  end_date: string;
  params?: any;
}) => {
  return await aws
    .get(`users/cc/`, { searchParams: { start_date, end_date } })
    .json<ITrendsResponse<IUserCCTrends>>();
};
//New

export const getCompaniesBraking = async ({
  start_date,
  end_date,
  params,
}: {
  start_date: string;
  end_date: string;
  params?: any;
}) => {
  return await aws
    .get(`companies/braking/`, {
      searchParams: { start_date, end_date },
    })
    .json();
};
export const TableWidgetCall = async ({
  start_date,
  end_date,
  params,
  url,
}: {
  start_date: string;
  end_date: string;
  url: string;
  params?: any;
}) => {
  return await aws
    .get(`${url}/`, {
      searchParams: { start_date, end_date },
    })
    .json();
};

export const getUsersFuelConsumption = async ({
  start_date,
  end_date,
  params,
}: {
  start_date: string;
  end_date: string;
  params?: any;
}) => {
  return await aws
    .get(`users/fuel-consumption/`, {
      searchParams: { start_date, end_date },
    })
    .json();
};

export const getUsersRankingQuarterly = async ({
  start_date,
  end_date,
  params,
}: {
  start_date: string;
  end_date: string;
  params?: any;
}) => {
  return await aws.get(`users/ranking/quarterly/`).json();
};

export const getUsersPoint = async ({
  start_date,
  end_date,
  params,
}: {
  start_date: string;
  end_date: string;
  params?: any;
}) => {
  return await aws
    .get(`users/point/`, { searchParams: { start_date, end_date } })
    .json();
};

//End New

export const getUsersBreaksDaily = async ({
  start_date,
}: {
  start_date: string;
}) => {
  return await aws
    .get(`users/braking/daily`, { searchParams: { start_date } })
    .json<any>();
};
export const getUsersIdlingDaily = async ({
  start_date,
  end_date,
}: {
  start_date: string;
  end_date: string;
}) => {
  return await aws
    .get(`users/idling/daily`, { searchParams: { start_date, end_date } })
    .json<any>();
};
export const getUserIdlingAvg = async ({
  start_date,
}: {
  start_date: string;
}) => {
  return await aws
    .get(`users/idling/avg`, { searchParams: { start_date } })
    .json<any>();
};
export const getCompanyIdlingDaily = async ({
  start_date,
}: {
  start_date: string;
}) => {
  return await aws
    .get(`companies/idling/daily`, { searchParams: { start_date } })
    .json<any>();
};

export const getUsersIdlingWeekly = async ({
  start_date,
  params,
}: {
  start_date: string;
  params?: any;
}) => {
  return await aws
    .get(`users/idling/`, {})
    .json<ITrendsResponse<IUserCCTrends>>();
};
export const mapTrendCC = async ({
  start_date,
  imei,
  company_id,
}: {
  start_date: any;
  imei: any;
  company_id: any;
}) => {
  return await aws
    .get(`users/cc/daily/map-trend`, {
      searchParams: { start_date, imei, company_id },
    })
    .json();
};

export const saveSetting = async ({
  sections,
  Token,
}: {
  sections: any;
  Token: any;
}) => {
  return rest
    .put("web/setting/", {
      headers: {
        Authorization: "Bearer " + Token,
      },
      body: JSON.stringify({ setting: sections }),
    })
    .json();
};

export const getSetting = (Token: any) => {
  return rest
    .get("web/setting/", {
      headers: {
        Authorization: "Bearer " + Token,
      },
    })
    .json();
};
