import * as React from "react";
import { connect, useSelector } from "react-redux";
import {
  PageContainer,
  FormHeader,
  ImagePicker,
  DropDown,
} from "../../../utilities/components/FromUtils";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { languages, languageAdd } from "../../../redux/actions/user.action";
import { useDispatch } from "../../../redux/store";
import { RootState } from "../../../redux/reducers";
import InputWrapper from "../../../Components/InputWrapper/InputWrapper";
import { useForm } from "react-hook-form";
import Calendar from "react-calendar";
import Moment from "moment";

const commaNumber = require("comma-number");
interface Props {
  close: () => void | undefined;
}
export default function Language({ close }: Props) {
  const { register } = useForm();
  const dispatch = useDispatch();
  const [Error, setError] = useState("");
  const [Langs, setLangs] = useState("");
  const [Data, setData] = useState({
    language: "",
    proficiency: "",
    selectedLang: { name: "" },
  });

  const [DriverLicenses, setDriverLicenses] = React.useState([]);
  const [ShowLangDrop, setShowLangDrop] = React.useState(false);
  const [ShowProDrop, setShowProDrop] = React.useState(false);

  function read() {
    dispatch(languages()).then((response: any) => {
      setLangs(response.payload.results);
    });
  }

  function save() {
    dispatch(languageAdd(Data)).then((response: any) => {
      if (response.payload && !response.payload.error) {
        close();
      } else {
        setError(response.payload.errorMassage);
      }
    });
  }

  const [t] = useTranslation();
  useEffect(() => {
    read();
  }, []);

  return (
    <div className={"popupBg"}>
      <div className={"popup-content popup-content-language"}>
        <div className="account-modal-close d-flex align-items-center justify-content-center">
          <button
            onClick={() => {
              close();
            }}
          >
            <img src={"/assets/closeCircle.svg"} alt="close-icon" />
          </button>
        </div>
        <div className={"title"}>Add Language</div>
        <div className={"ErrorMassage"}>{Error}</div>

        <InputWrapper label={t("account.language") + ":"} important>
          <DropDown
            selectedItem={Data.selectedLang}
            textEntity={"name"}
            preText={""}
            items={Langs}
            pngSource={null}
            show={ShowLangDrop}
            open={(open: any) => {
              setShowLangDrop(open);
              if (open) {
                setShowProDrop(false);
              }
            }}
            placeholder="Select Language"
            onChange={(selected: any) => {
              setData({
                ...Data,
                language: selected.id,
                selectedLang: selected,
              });
            }}
          />
        </InputWrapper>
        <InputWrapper label={t("account.Proficiency") + ":"} important>
          <DropDown
            selectedItem={{ name: Data.proficiency }}
            textEntity={"name"}
            preText={""}
            show={ShowProDrop}
            open={(open: any) => {
              setShowProDrop(open);

              if (open) {
                setShowLangDrop(false);
              }
            }}
            items={[
              { name: "BASIC" },
              { name: "INDEPENDENT" },
              { name: "PROFICIENT" },
            ]}
            pngSource={null}
            placeholder="Select proficiency"
            onChange={(selected: any) => {
              setData({
                ...Data,
                proficiency: selected.name,
              });
            }}
          />
        </InputWrapper>
        <div
          className={"add"}
          onClick={() => {
            save();
          }}
        >
          <img src={"/assets/plus-white.svg"} />
          {t("account.add")}
        </div>
      </div>
    </div>
  );
}
