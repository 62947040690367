import * as React from "react";
import { connect, useSelector } from "react-redux";
import Param from "../../redux/Param";
import {
  PageContainer,
  FormHeader,
} from "../../utilities/components/FromUtils";
import Breadcrumbs from "../../utilities/components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import i18n from "i18next";
import {
  ChartLoadingSvg,
  MapLoadingSvg,
} from "./../dashboard/Package/assets/icons";

import {
  getWidgets,
  widgetsPermissions,
} from "../../redux/actions/dashboard.action";
import { useDispatch } from "../../redux/store";
import { RootState } from "../../redux/reducers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const commaNumber = require("comma-number");

export default function WidgetPermission() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const user = useSelector((state: RootState) => state.user.profile);

  const [Loading, setLoading] = useState(true);
  const [Widgets, setWidgets] = useState([]);
  const [Error, setError] = useState(false);
  const [SelectAllOfficers, setSelectAllOfficers] = useState(true);
  const [SelectAllDrivers, setSelectAllDrivers] = useState(true);
  useEffect(() => {
    console.log("user", user.role);
    if (user && user.role !== "MANAGER") {
      window.location.replace("/dashboard");
    }
  }, []);
  function allow(index: any, key: any, widget: any, action: any, type: any) {
    const newW: any = [...Widgets];
    if (type === "driver") {
      newW[index].items[key].element.allow_driver = action;
    }
    if (type === "officer") {
      newW[index].items[key].element.allow_officer = action;
    }
    setWidgets(newW);
  }

  function read() {
    dispatch(getWidgets()).then((response: any) => {
      if (response && response.payload) {
        setWidgets(response.payload.widgets);
        setLoading(false);
        const w = response.payload.widgets;
        w.map((wi: any) => {
          if (wi) {
            wi.items.map((ws: any) => {
              if (!ws.element.allow_officer && SelectAllOfficers) {
                setSelectAllOfficers(false);
              }
              if (!ws.element.allow_driver && SelectAllDrivers) {
                setSelectAllDrivers(false);
              }
            });
          }
        });
      }
    });
  }

  function send() {
    const data: any = [];
    Widgets.map((section: any) => {
      if (section) {
        section.items.map((widget: any) => {
          const it = {
            widget_id: "",
            allow_officer: false,
            allow_driver: false,
          };
          it.widget_id = widget.element.widget_id;
          it.allow_officer = widget.element.allow_officer;
          it.allow_driver = widget.element.allow_driver;
          data.push(it);
        });
      }
    });
    dispatch(widgetsPermissions(data)).then((response: any) => {
      //setData(response);
      const title = t("account.saved");
      toast.success(title, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    });
  }

  useEffect(() => {
    read();
  }, []);
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    read();
  }, [i18n.language]);

  function selectAll(type: any, action: any) {
    const newW: any = [...Widgets];
    if (type === "drivers") {
      setSelectAllDrivers(action);
    }
    if (type === "officers") {
      setSelectAllOfficers(action);
    }
    newW.map((section: any) => {
      section.items.map((widget: any) => {
        console.log("a", widget);
        if (type === "drivers") {
          widget.element.allow_driver = action;
        }
        if (type === "officers") {
          widget.element.allow_officer = action;
        }
      });
    });
    setWidgets(newW);
  }

  return (
    <PageContainer>
      <FormHeader text={t("account.Dashboard_Setting")} />
      <ToastContainer />

      <div className={"ErrorMassage"}>{Error}</div>
      <div className="widgetsPermissionList">
        <div className="widgetsPermissionSelect">
          <h2>{t("dashboard.widgetPermission")}</h2>
          <span>{t("dashboard.choose")}</span>
          <div className="selects">
            <div className="selectRow">
              <span>{t("dashboard.Officers")} :</span>{" "}
              <span className="title">
                {!SelectAllOfficers
                  ? t("account.SelectAll")
                  : t("account.DeselectAll")}
              </span>{" "}
              <input
                type="checkbox"
                checked={SelectAllOfficers}
                onChange={(e) => {
                  selectAll("officers", e.target.checked);
                }}
              />
            </div>
            <div className="selectRow">
              <span>{t("dashboard.Drivers")} :</span>{" "}
              <span className="title">
                {!SelectAllDrivers
                  ? t("account.SelectAll")
                  : t("account.DeselectAll")}
              </span>{" "}
              <input
                checked={SelectAllDrivers}
                type="checkbox"
                onChange={(e) => {
                  selectAll("drivers", e.target.checked);
                }}
              />
            </div>
          </div>
        </div>

        {Loading && <ChartLoadingSvg />}
        {Widgets.map((section: any, index: any) => (
          <div className="widgetsPermissionSection" key={index}>
            <h2>{section && section.title}</h2>
            <div className="widgetsPermissionWidgets">
              {section &&
                section.items.map(
                  (widget: any, key: any) =>
                    widget && (
                      <div className="widget" key={key}>
                        <img src={widget.icon} />
                        <div className="title">{widget.title}</div>
                        <div className="allows">
                          <div
                            onClick={() => {
                              const action = widget.element.allow_officer
                                ? false
                                : true;
                              allow(index, key, widget, action, "officer");
                            }}
                          >
                            <span
                              className={
                                widget.element.allow_officer
                                  ? "iconAllow iconAllowCheck"
                                  : "iconAllow"
                              }
                            ></span>{" "}
                            {t("dashboard.Officers")}
                          </div>
                          <div
                            onClick={() => {
                              const action = widget.element.allow_driver
                                ? false
                                : true;
                              allow(index, key, widget, action, "driver");
                            }}
                          >
                            <span
                              className={
                                widget.element.allow_driver
                                  ? "iconAllow iconAllowCheck"
                                  : "iconAllow"
                              }
                            ></span>{" "}
                            {t("dashboard.Drivers")}
                          </div>
                        </div>
                      </div>
                    ),
                )}
            </div>
          </div>
        ))}
        <div>
          <button
            className="LoadingButton updateBtn"
            onClick={() => {
              send();
            }}
          >
            <img src="/assets/icon-basic-refresh.svg" />
            Update
          </button>
        </div>
      </div>
    </PageContainer>
  );
}
