import * as React from "react";

interface Props {
  remove: () => void | undefined;
  pin: () => void | undefined;
  info: () => void | undefined;
  el: Record<any, any>;
  t: Record<string, string>;
  SmallWidget: boolean;
}

export default function Action({
  el,
  remove,
  pin,
  t,
  info,
  SmallWidget,
}: Props) {
  return (
    <div className={"actionBtnItem"}>
      <div className={"actionBtnItemAct"} onClick={() => remove()}>
        <img src={"./assets/remove.svg"} />
        <span>{t.remove}</span>
      </div>
      <div className={"actionBtnItemAct"} onClick={() => pin()}>
        <img src={"./assets/pin.svg"} />
        {el.isDraggable ? <span>{t.pin}</span> : <span>{t.unpin}</span>}
      </div>
      <div
        className={
          SmallWidget
            ? "actionBtnItemAct actionBtnItemActDisable"
            : "actionBtnItemAct"
        }
        onClick={() => {
          !SmallWidget && info();
        }}
      >
        <img src={"./assets/info.svg"} />

        {SmallWidget ? (
          <span>Show Info</span>
        ) : el.info ? (
          <span>Hide Info</span>
        ) : (
          <span>Show Info</span>
        )}
      </div>
    </div>
  );
}
