import * as React from "react";
import ColFilter from "./ColFilter";
interface Props {
  tableData: Record<string, any>;
  title?: string | "";
  tableType: string | "";
  getData: (Params: Record<string, any>) => void | undefined;
  t: Record<string, string>;
}

export default function Table({
  tableData,
  tableType,
  title,
  getData,
  t,
}: Props) {
  const [Offset, setOffset] = React.useState(0);
  const [PageCount, setPageCount] = React.useState(0);
  const [Search, setSearch] = React.useState("");
  const [Ordering, setOrdering] = React.useState("");
  const [OrderingWidget, setOrderingWidget] = React.useState("");
  const [According, setAccording] = React.useState("+");
  const [WidgetAccording, setWidgetAccording] = React.useState("+");
  const [Data, setData] = React.useState<Array<Record<string, any>>>([]);
  const [Cols, setCols] = React.useState<Array<string>>([]);
  const [SelectedRows, setSelectedRows] = React.useState<Array<string>>([]);
  const [SelectedAll, setSelectedAll] = React.useState(false);
  const [FilterCol, setFilterCol] = React.useState<Array<string>>([
    "id",
    "company",
  ]);
  const [Limit, setLimit] = React.useState(10);
  const [load, setLoad] = React.useState(false);
  const [ColFilterView, setColFilterView] = React.useState(false);

  function insertRows(response: any) {
    let data = [];

    if (response) {
      setLoad(true);

      data = response;
      if (data && data.length > 0) {
        const keys = Object.keys(data[0]);
        setData(data);
        setCols(keys);
      } else {
        setData([]);
        setPageCount(0);
      }
    }
  }

  async function request() {
    const Params = {
      offset: Offset,
      limit: Limit,
      search: Search,
      ordering: Ordering,
      according: According,
    };
    return getData(Params);
  }

  React.useEffect(() => {
    const filters = localStorage.getItem(tableType + "_filter");
    if (filters) {
      setFilterCol(JSON.parse(filters));
    } else {
      setFilterCol(["company", "id"]);
    }
    insertRows(tableData);
  }, [tableData]);

  React.useEffect(() => {
    localStorage.setItem(tableType + "_filter", JSON.stringify(FilterCol));
  }, [FilterCol]);

  function selectAll() {
    if (SelectedAll) {
      setSelectedRows([]);
      setSelectedAll(false);
    } else {
      setSelectedAll(true);
      const newIds = Data.map((el) => el.id);
      setSelectedRows(newIds);
    }
  }

  function selectRow(val: string) {
    if (!SelectedRows.includes(val)) {
      setSelectedRows([...SelectedRows, val]);
    } else {
      setSelectedRows(SelectedRows.filter((item) => item !== val));
    }
  }

  async function search(key: string) {
    key = key.toLowerCase();
    await setSearch(key);
    await request();
  }

  function filterCol(val: string) {
    if (!FilterCol.includes(val)) {
      setFilterCol([...FilterCol, val]);
    } else {
      setFilterCol(FilterCol.filter((item) => item !== val));
    }
  }

  function OrderChecker(col: any) {
    if (OrderingWidget === col) {
      return true;
    } else {
      return false;
    }
  }

  function sortBy(col: any) {
    // According === "+"
    //   ? [...Data].sort((a: any, b: any) => (a[col] > b[col] ? 1 : -1))
    //   : [...Data].sort((a: any, b: any) => (a[col] < b[col] ? 1 : -1));
    const sortData = [...Data].slice(0);
    if (According === "+") {
      sortData.sort(function (a, b) {
        const x = a[col].toLowerCase().split(".")[0];
        const y = b[col].toLowerCase().split(".")[0];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    } else {
      sortData.sort(function (a, b) {
        const x = a[col].toLowerCase().split(".")[0];
        const y = b[col].toLowerCase().split(".")[0];
        return x < y ? 1 : x > y ? -1 : 0;
      });
    }

    setData(sortData);
    setOrderingWidget(col);
    setAccording(According === "+" ? "-" : "+");
    setWidgetAccording(WidgetAccording === "+" ? "-" : "+");
  }

  function CustomColsGenerator(data: any, key: any, index: number) {
    if (key === "status") {
      return (
        <div className={"flexCol"}>
          {data === "active" && <i className={"vehicleStatus active"} />}
          {data === "deactivate" && (
            <i className={"vehicleStatus deactivate"} />
          )}
          {data === "pending" && <i className={"vehicleStatus pending"} />}
          {data}
        </div>
      );
    }
    if (key === "color") {
      return (
        <div className={"flexCol"}>
          <div className={"color-item"} style={{ background: data }}></div>
        </div>
      );
    }

    if (key === "vehicle_brand") {
      return <div className={"flexCol"}>{data ? data.name : " - "}</div>;
    }
    if (key === "obj_groups") {
      return (
        <div className={"flexCol"}>
          <div className={"row-profile-item"}>
            {data &&
              data[0] &&
              data.map((tag: any, key: number) => (
                <span key={key} className={"spanTagTable"}>
                  {tag}
                </span>
              ))}
          </div>
        </div>
      );
    }
    if (key === "picture") {
      return (
        <div className={"flexCol"}>
          <div className={"row-profile-item"}>
            <img alt={""} src={data} />
          </div>
        </div>
      );
    }
    if (key === "icon") {
      return (
        <div className={"flexCol"}>
          <div className={"row-vehicle-icon"}>
            {data ? <img src={data} alt={""} /> : "-"}
          </div>
        </div>
      );
    }
    if (key === "id" || key === "user") {
      return <div className={"flexCol"}>{index + 1 + Offset}</div>;
    }

    if (data === null) {
      return " - ";
    }
    if (typeof data !== "object") {
      return data ? data : " - ";
    } else {
      return "Object Given";
    }
  }

  function idSelector(row: Record<string, any>) {
    if (row.user) {
      return row.user;
    } else {
      return row.id;
    }
  }

  function colGeneratorName(col: string) {
    return col.replaceAll("_", " ");
  }

  return (
    <>
      <div className={"page-container d-flex flex-column"}>
        <h1 className="form-header">{title ? title : tableType}</h1>
        <div className={"tableSearch"}>
          <div className="search-input-table">
            <div className="input-with-icon" style={{ height: "" }}>
              <img src={"./assets/icon-basic-search.svg"} alt="input-icon" />

              <input
                type={"text"}
                placeholder={t.Search}
                value={Search}
                onChange={(event) => search(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="tableViewContainer customScrollBar">
          <div className="tableContainer ">
            {load && Data.length ? (
              <table className="table tableVehicle">
                <thead>
                  <tr>
                    <th scope="col">
                      <input
                        type="checkbox"
                        onClick={() => selectAll()}
                        checked={SelectedAll}
                      />
                    </th>
                    {Cols.map(
                      (col: string, index) =>
                        !FilterCol.includes(col) && (
                          <th scope="col" key={index}>
                            <div className={"thCol"}>
                              {colGeneratorName(col)}
                              <div
                                className={"sortByItem"}
                                onClick={() => {
                                  sortBy(col);
                                }}
                              >
                                {OrderChecker(col) ? (
                                  WidgetAccording === "+" ? (
                                    <img
                                      alt={""}
                                      src={
                                        "./assets/images/front/sortByArrow.svg"
                                      }
                                    />
                                  ) : (
                                    <img
                                      alt={""}
                                      src={
                                        "./assets/images/front/sortByArrowUp.svg"
                                      }
                                    />
                                  )
                                ) : (
                                  <img
                                    alt={""}
                                    src={
                                      "./assets/images/front/sortByArrow.svg"
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </th>
                        ),
                    )}
                    <th scope="col" className={"thDetails "}>
                      <div
                        className={"colDetails"}
                        onClick={() => {
                          setColFilterView(!ColFilterView);
                        }}
                      />
                      {ColFilterView && (
                        <div className={"colEditPopup"}>
                          <ColFilter
                            close={() => setColFilterView(false)}
                            filter={(val: any) => filterCol(val)}
                            cols={Cols}
                            active={FilterCol}
                          />
                        </div>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Data.map((rowItem, index) => (
                    <tr
                      className={
                        SelectedRows.includes(idSelector(rowItem))
                          ? "tableRow tableRowActive"
                          : "tableRow"
                      }
                      key={index}
                    >
                      <th scope="row">
                        <input
                          type="checkbox"
                          onChange={() => selectRow(idSelector(rowItem))}
                          checked={SelectedRows.includes(idSelector(rowItem))}
                        />
                      </th>

                      {Cols.map((key, indexKey) => (
                        <>
                          {!FilterCol.includes(key) && (
                            <td key={indexKey}>
                              {CustomColsGenerator(rowItem[key], key, index)}
                            </td>
                          )}
                        </>
                      ))}
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className={"emptyTable"}>{t.no_data_available}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
