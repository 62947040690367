import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
//import 'moment/locale/es';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  holidays,
  holidayEdit,
  myRequests,
} from "../../redux/actions/calendar.action";
import AddHolidayRequest from "./AddHolidayRequest";
export default function CalendarManager({ history }: any) {
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [event, setEvent] = React.useState([]);
  const [requestsShow, setRequestsShow] = React.useState(true);
  const [RowOpen, setRowOpen] = React.useState(0);
  const [OpenCount, setOpenCount] = React.useState(0);
  const [manager_note, setManager_note] = React.useState("");
  const [AddRequest, setAddRequest] = React.useState(false);

  const holidaysList = useSelector(
    (state: RootState) => state.calendar.holidays,
  );
  // const holidaysOpenList = useSelector(
  //   (state: RootState) => state.calendar.holidaysOpen,
  // );
  function NameWeek(date: any, culture: any) {
    return t("calendar." + [localizer.format(date, "dddd", culture)]);
  }
  const formats = {
    weekdayFormat: (date: any, culture: any) => NameWeek(date, culture),
  };
  function getFilename(longname: any) {
    let shortname = longname.replace(/^.*[\\\/]/, "");
    shortname = shortname.substr(0, shortname.indexOf("?"));
    return shortname;
  }
  function RequestStatus(request_status: any, request: any) {
    const data = {
      id: request.id,
      request_status: request_status,
      manager_note: manager_note,
    };
    setRowOpen(0);

    dispatch(holidayEdit(data)).then((res) => {
      dispatch(holidays());
    });
  }
  function eventStyleGetter(
    event: any,
    start_date: any,
    end_date: any,
    isSelected: any,
  ) {
    // console.log("event", event);
    const backgroundColor = event.color; //
    const style = {
      backgroundColor: isSelected ? "#000" : backgroundColor,
      color: "#fff",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
      title: "-",
    };
  }
  React.useEffect(() => {
    dispatch(holidays());
  }, []);
  React.useEffect(() => {
    //console.log("holidaysList", holidaysList);
    const events: any = [];
    if (holidaysList !== null) {
      const count = holidaysList.results.filter((val: any) => {
        if (val.request_status === "OPEN") {
          return val.id;
        }
      });
      setOpenCount(count.length);
      holidaysList.results.map((holiday: any, key: any) => {
        const e: any = {};
        e.title =
          holiday.user.first_name +
          " " +
          holiday.user.last_name +
          " - " +
          t("calendar." + holiday.type);
        e.type = holiday.type;
        e.status = holiday.request_status;
        if (e.status === "OPEN") {
          e.color = "#fdbf5e";
        } else if (e.status === "REJECTED") {
          e.color = "#ff3d57";
        } else {
          e.color = "#09b66d";
        }

        e.start_date = new Date(holiday.start_date);
        e.end_date = new Date(holiday.end_date);
        e.end = holiday.end_date;
        e.start = holiday.start_date;

        if (e.status !== "REJECTED") {
          events.push(e);
        }
      });
      //console.log("events", events);
      setEvent(events);
    }
  }, [holidaysList]);
  const CustomToolbar = (toolbar: any) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate("next");
    };

    const goToDayView = () => {
      toolbar.onViewChange("day");
    };
    const goToWeekView = () => {
      toolbar.onViewChange("week");
    };
    const goToMonthView = () => {
      toolbar.onViewChange("month");
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate("current");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          {/* <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span> */}
        </span>
      );
    };

    return (
      <div className={"toolbar-container"}>
        <label className={"label-date"}>{label()}</label>

        <div className={"back-next-buttons"}>
          <button className={"btn-back"} onClick={goToBack}>
            &#8249;
          </button>
          <button className={"btn-current"} onClick={goToCurrent}>
            today
          </button>
          <button className={"btn-next"} onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={"calendarFa"}>
      {AddRequest && (
        <AddHolidayRequest
          close={() => {
            setAddRequest(false);
            dispatch(myRequests());
          }}
        />
      )}
      <div
        className="createBtn addRequestBtn"
        onClick={() => {
          setAddRequest(true);
        }}
      ></div>
      <div
        className={"requestLink"}
        onClick={() => {
          setRequestsShow(!requestsShow);
        }}
      >
        <span>
          <strong className={"CountOfOpenReq"}>{OpenCount}</strong>
          {t("calendar.open_requests")}
        </span>
        <i className={requestsShow ? "" : "closeItem"}></i>
      </div>
      <div
        className={
          requestsShow ? "CalendarView " : "CalendarView CalendarViewBarOpen"
        }
      >
        <Calendar
          localizer={localizer}
          formats={formats}
          events={event}
          components={
            {
              //toolbar: CustomToolbar,
            }
          }
          eventPropGetter={eventStyleGetter}
          startAccessor="start_date"
          endAccessor="end_date"
          style={{}}
          messages={{
            month: t("calendar.month"),
            agenda: t("calendar.agenda"),
            allDay: t("calendar.allDay"),
            day: t("calendar.day"),
            today: t("calendar.today"),
            previous: t("calendar.previous"),
            next: t("calendar.next"),
            date: t("calendar.date"),
            noEventsInRange: t("calendar.noEventsInRange"),
            time: t("calendar.time"),
            tomorrow: t("calendar.tomorrow"),
            week: t("calendar.week"),
            work_week: t("calendar.work_week"),
            yesterday: t("calendar.yesterday"),
          }}
        />
      </div>
      <div
        className={
          requestsShow
            ? "OpenRequests customScrollBar"
            : "OpenRequests customScrollBar none"
        }
      >
        <div className="RequestsList">
          {holidaysList &&
            holidaysList.results &&
            holidaysList.results.map(
              (e: any, key: any) =>
                e.request_status === "OPEN" && (
                  <div className="RequestsItem" key={key}>
                    <div
                      className="RequestsItemHeader"
                      onClick={() => {
                        RowOpen === key + 1
                          ? setRowOpen(0)
                          : setRowOpen(key + 1);
                      }}
                    >
                      <div className={"imgBox"}>
                        <img src="" />
                        <div
                          className={
                            "requestIconStatus  requestIconStatusManager " +
                            e.request_status
                          }
                        >
                          {e.request_status === "REJECTED" && (
                            <img src={"/assets/icon-basic-cross-white.svg"} />
                          )}
                          {e.request_status === "ACCEPTED" && (
                            <img src={"/assets/check.svg"} />
                          )}
                          {e.request_status === "OPEN" && "?"}
                        </div>
                      </div>
                      <div className="RequestTitle">
                        <div>{e.user.first_name + " " + e.user.last_name}</div>
                        <div className="RequestType">
                          {t("calendar." + e.type)}
                        </div>
                        <div className="RequestDate">
                          {e.start_date + " - " + e.end_date}
                        </div>
                      </div>
                      <div
                        className={
                          RowOpen === key + 1
                            ? "requestArrow requestArrowDown"
                            : "requestArrow"
                        }
                      ></div>
                    </div>
                    <div
                      className={
                        RowOpen === key + 1 ? "requestData" : "requestData none"
                      }
                    >
                      <div className="requestDataTitle">
                        {t("calendar.request")}
                      </div>
                      <div className={"DateInfo"}>
                        <div className="DateInfoCol flex1">
                          <span>{t("calendar.start_date")}</span>
                          <div>{e.start_date}</div>
                        </div>
                        <div className="DateInfoCol flex1">
                          <span>{t("calendar.end_date")}</span>
                          <div>{e.end_date}</div>
                        </div>
                        <div className="DateInfoCol">
                          <span>{t("calendar.days")}</span>
                          <div>{e.num_days}</div>
                        </div>
                      </div>
                      {e.document && (
                        <div className="requestDataTitle">
                          {t("calendar.attachment")}
                        </div>
                      )}
                      {e.document && (
                        <a href={e.document} className={"requestFile"}>
                          <strong>{getFilename(e.document)}</strong>
                        </a>
                      )}
                      <div className="requestDataTitle">
                        {t("calendar.note")}
                      </div>

                      <div className="requestDataNote">{e.requester_note}</div>

                      <div className="requestDataTitle">
                        {t("calendar.status")}
                      </div>
                      <div className={"DateInfo"}>
                        <div className="DateInfoCol flex1">
                          <span>{t("calendar.paid")}</span>
                          {console.log("AA", e)}
                          <div>{e.leave_status && e.leave_status.paid} </div>
                        </div>
                        <div className="DateInfoCol flex1">
                          <span>{t("calendar.unpaid")}</span>
                          <div>{e.leave_status && e.leave_status.unpaid}</div>
                        </div>
                        <div className="DateInfoCol flex1">
                          <span>{t("calendar.special")}</span>
                          <div>{e.leave_status && e.leave_status.special}</div>
                        </div>
                        <div className="DateInfoCol flex1">
                          <span>{t("calendar.sick")}</span>
                          <div>{e.leave_status && e.leave_status.sick}</div>
                        </div>
                      </div>
                      <div className="requestDataTitle">
                        {t("calendar.response")}
                      </div>
                      <textarea
                        className="requestDataNote requestDataNoteInput"
                        onChange={(event: any) => {
                          setManager_note(event.target.value);
                        }}
                      >
                        {e.manager_note}
                      </textarea>
                      <div className={"RequestBtns"}>
                        <div
                          className={"RequestBtn RequestBtnAccept"}
                          onClick={() => {
                            RequestStatus("ACCEPTED", e);
                          }}
                        >
                          {t("calendar.accept")}
                        </div>
                        <div
                          className={"RequestBtn RequestBtnReject"}
                          onClick={() => {
                            RequestStatus("REJECTED", e);
                          }}
                        >
                          {t("calendar.reject")}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
            )}
        </div>
      </div>
    </div>
  );
}
