import { rest } from "../apiGateway";
import ky from "ky";

export const cantactUs = (json: string) => {
  return rest.post("webcontact/", { json }).json();
};

export const paymentMethods = () => {
  return rest.get("payment-methods/").json();
};
