import * as React from "react";
import { connect, useSelector } from "react-redux";
import Param from "../../redux/Param";
import {
  PageContainer,
  FormHeader,
  DropDown,
} from "../../utilities/components/FromUtils";
import InputWrapper from "../../Components/InputWrapper/InputWrapper";
import { useTranslation, withTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import {
  profile,
  company,
  permissions,
  permissionChange,
  impersonateChange,
  impersonate,
} from "../../redux/actions/user.action";
import { useDispatch } from "../../redux/store";
import { RootState } from "../../redux/reducers";
interface Props {
  close: () => void | undefined;
}

export default function Switch({ close }: Props) {
  const dispatch = useDispatch();
  const [Error, setError] = useState(false);
  const [Company, setCompany] = useState<Record<string, any>>({});
  const [MyCompany, setMyCompany] = useState<Record<string, any>>({});
  const [User, setUser] = useState<Record<string, any>>({});
  const [Role, setRole] = useState({ display_name: User.role, id: null });
  const [Permissions, setPermissions] = useState<Record<string, any>>({});
  const [UsersList, setUsersList] = useState([]);

  async function read(company_id?: string) {
    const token = `${localStorage.getItem("token")}`;
    let c_id = "";
    await dispatch(profile(token)).then(async (profile: any) => {
      setUser(profile.payload);
      setRole({ display_name: profile.payload.role, id: null });
    });
    await dispatch(company(token)).then((response: any) => {
      if (response.payload) {
        setMyCompany(response.payload);
        setCompany(response.payload);
        c_id = response.payload.id;
      }
    });

    await dispatch(permissions()).then(async (response: any) => {
      if (response.payload) {
        const roles = response.payload.role;
        let users: any = [];
        await dispatch(impersonate(company_id ? company_id : c_id)).then(
          async (res: any) => {
            users = res.payload.results;
          },
        );
        roles.map((rol: any) => {
          if (rol) {
            const r = { display_name: rol, id: undefined };
            users && users.unshift(r);
          }
        });
        setPermissions(response.payload);
        setUsersList(users);
      }
    });
  }

  async function changeCompany(company_id: string) {
    await dispatch(permissions()).then(async (response: any) => {
      if (response.payload) {
        const roles = response.payload.role;
        let users: any = [];
        await dispatch(impersonate(company_id)).then(async (res: any) => {
          users = res.payload.results;
        });
        roles.map((rol: any) => {
          if (rol) {
            const r = { display_name: rol, id: undefined };
            users && users.unshift(r);
          }
        });
        setPermissions(response.payload);
        setUsersList(users);
      }
    });
  }
  function newUser(res: any) {
    if (res.payload.refresh_token) {
      dispatch(profile(res.payload.access_token)).then(async (profile: any) => {
        setUser(profile);
        await localStorage.setItem("refresh_token", res.payload.refresh_token);
        await localStorage.setItem("token", res.payload.access_token);
        await window.location.reload();
      });
    } else {
      if (res.payload.error) {
        setError(res.payload.errorMassage);
      }
    }
  }
  async function switchRole() {
    if (Role.id && Role.id !== null) {
      dispatch(impersonateChange(Role.id)).then(async (res: any) => {
        newUser(res);
      });
    } else {
      dispatch(
        permissionChange({ company: Company.id, role: Role.display_name }),
      ).then(async (res: any) => {
        newUser(res);
      });
    }
  }

  const [t] = useTranslation();
  useEffect(() => {
    read();
  }, []);

  return (
    <div className="switchRoleFA section-containers d-md-flex justify-content-between justify-content-md-around flex-md-row flex-sm-column flex-wrap">
      <div className="col-sm-12 col-md-7 mb-3 mb-md-0  p-0 d-flex flex-column">
        <div className="switchRole card-white-section d-flex justify-content-center flex-column">
          <div className="account-modal-close d-flex align-items-center justify-content-center">
            <button
              onClick={() => {
                close();
              }}
            >
              <img src={"/assets/closeCircle.svg"} alt="close-icon" />
            </button>
          </div>
          <div className={"ErrorMassage"}>{Error}</div>

          <div className="title">{t("account.Switch_Role")}</div>

          <div>
            <span>{t("account.Switch_Role_desc")}</span>
            <InputWrapper label={t("account.company")}>
              <DropDown
                selectedItem={{
                  name: Company
                    ? Company.name
                      ? Company.name
                      : MyCompany
                      ? MyCompany.name
                      : ""
                    : "",
                }}
                textEntity={"name"}
                preText={""}
                items={Permissions.company}
                pngSource={"/assets/icon-basic-company.svg"}
                placeholder={t("account.select_company")}
                onChange={(selected: any) => {
                  setCompany({
                    ...Company,
                    name: selected.name,
                    id: selected.id,
                  });
                  changeCompany(selected.id);
                }}
              />
            </InputWrapper>
            <InputWrapper label={t("account.role")}>
              <DropDown
                selectedItem={Role}
                textEntity={"display_name"}
                preText={""}
                items={UsersList}
                pngSource={"/assets/icon-basic-user.svg"}
                placeholder={t("account.Select_Role")}
                onChange={(selected: any) => {
                  console.log("selected", selected);
                  console.log("Roles", UsersList);
                  setRole(selected);
                  //setRoles(Roles);
                }}
              />
            </InputWrapper>
            <div
              className="updateBtn"
              onClick={() => {
                switchRole();
              }}
            >
              <img
                src="/assets/icon-basic-refresh.svg"
                alt="drag-n-drop-logo"
              />
              {t("account.Switch")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
