import * as React from "react";
import { Link } from "react-router-dom";
import {
  InputWithIcon,
  CheckboxWithTitle,
  LoadingButton,
} from "../../utilities/components/FromUtils";
import { signUp } from "../../redux/actions/auth.action";
import { signUpValidator } from "../../utilities/functions/authValidator";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { History } from "history";

export default function SignUp({ history }: { history: History }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [Fisrt_name, setFisrt_name] = React.useState("");
  const [Last_name, setLast_name] = React.useState("");
  const [EmailSend, setEmailSend] = React.useState(false);
  const [Error, setError] = React.useState<any>(null);
  const [password, setPassword] = React.useState("");
  const [terms, setTerms] = React.useState(false);
  const signUpHandler = () => {
    const data = {
      email: email,
      first_name: Fisrt_name,
      last_name: Last_name,
      password1: password,
      password2: password,
    };
    if (signUpValidator(data)) {
      dispatch(signUp(data)).then((response: any) => {
        if (response.payload && !response.payload.error) {
          //history.push("/login");
          setError(false);
          setEmailSend(true);
        } else {
          setError(
            t("auth.credentials_not_valid") + response.payload.errorMassage,
          );
          setEmailSend(false);
        }
      });
    } else {
      setError(t("auth.credentials_not_valid"));
    }
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      signUpHandler();
    }
  };
  return (
    <div className="main-container d-flex">
      {/* <div className="login-desktop-information-section remove-under-1440 ">
        <div className="full-section d-flex justify-content-center align-items-center">
          <img className="login-desktop-information-img" src={'/assets/images/auth/drag-n-drop-img.png'} alt="drag-n-drop-logo" />
        </div>
        <div className="login-desktop-information">
          <h2 className="login-information-h">
            Welcome to <span className="deep-sky-blue-text">FleetSpark</span>
          </h2>
          <p className="login-information-p">
            Duis aute irure dolor in reprehenderit in voluptate, qui in ea voluptate velit esse, quam nihil molestiae consequatur, vel illum, obcaecati cupiditate nons.
          </p>
        </div>
      </div> */}
      <div className="login-form-section full-section d-flex justify-content-center align-items-center fadeIn">
        <div className="login-form-inputs-container d-flex justify-content-center flex-column login-form-inputs-margin">
          <div className="login-form-inputs-header">
            <Link to="/login">
              <img
                className="login-form-inputs-header-img"
                src={"/assets/images/logo/Logox2.png"}
                alt="fleetspark-logo"
              />
            </Link>
          </div>

          <small className="body-1-text signup-form-inputs-header-text remove-under-1440">
            {t("home.create-account")}
          </small>
          <small className="signInText show-above-1440">
            {t("home.create-account")}
          </small>
          {EmailSend && (
            <div className="alert alert-success" role="alert">
              {t("auth.Verification_email")}
            </div>
          )}
          {Error && (
            <div className="alert alert-danger" role="alert">
              {Error}
            </div>
          )}
          <InputWithIcon
            value={Fisrt_name}
            placeholder={t("auth.first_name")}
            pngSource={"/assets/images/auth/Profile2x.png"}
            onChange={(v: string) => setFisrt_name(v)}
          />
          <InputWithIcon
            value={Last_name}
            placeholder={t("auth.last_name")}
            pngSource={"/assets/images/auth/Profile2x.png"}
            onChange={(v: string) => setLast_name(v)}
          />
          <InputWithIcon
            value={email}
            placeholder={t("home.email")}
            pngSource={"/assets/images/auth/@2x.png"}
            onChange={(email: string) => setEmail(email)}
          />
          <InputWithIcon
            value={password}
            placeholder={t("home.password")}
            type="password"
            onKeyDown={(event: KeyboardEvent) => {
              if (terms) {
                handleKeyDown(event);
              }
            }}
            pngSource={"/assets/images/auth/Padlock2x.png"}
            onChange={(password: string) => setPassword(password)}
          />
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <CheckboxWithTitle
                value={terms}
                text={t("home.accept-terms")}
                linkText={t("home.terms-condition")}
                link="/terms-conditions"
                onChange={(terms: boolean) => setTerms(terms)}
              />
            </div>
          </div>
          <div className="login-button">
            <LoadingButton
              disabled={!terms}
              onClick={() => signUpHandler()}
              text={t("home.create-account")}
            />
          </div>
          <small className="login-form-switch-part body-2-text">
            {t("home.already-have-account")}
            <Link to="/login" className="deep-sky-blue-text">
              {t("home.login")}
            </Link>
          </small>
        </div>
      </div>
    </div>
  );
}
