import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import { DropDown } from "../FromUtils";

interface Props {
  cols: Record<string, any>;
  data: Record<string, any>;
  SelectedRows: Record<string, any>;
  editable: boolean;
  multiple: boolean;
  edit: (type: boolean) => void | undefined;
  group: (tag: any) => void | undefined;
  close: () => void | undefined;
}

export default function ColEdit({
  edit,
  SelectedRows,
  close,
  multiple,
  editable,
  data,
  group,
}: Props) {
  const [t] = useTranslation();
  const [Detail] = React.useState<Array<Record<string, any>>>([
    {
      name: "Analytics",
      icon: "/assets/images/front/analytics.svg",
      disable: true,
    },
    {
      name: "Message",
      icon: "/assets/images/front/message.svg",
      disable: multiple ? false : true,
    },
    {
      name: "Group",
      icon: "/assets/images/front/plus.svg",
      prop: "group",
      disable: multiple ? false : true,
    },
    {
      name: "Edit",
      icon: "/assets/images/front/edit.svg",
      prop: "edit",
      disable: editable ? false : true, //editable ? false : true
    },
  ]);
  const [GroupView, setGroupView] = React.useState(false);
  const [GroupName, setGroupName] = React.useState("");
  const [Tags, setTags] = React.useState([]);

  React.useEffect(() => {
    const obj_groups: any = [];
    data.map((item: any) => {
      item.obj_groups &&
        item.obj_groups.map((tag: string) => {
          if (obj_groups.indexOf(tag) === -1) {
            obj_groups.push(tag);
          }
        });
    });
    setTags(obj_groups);
  }, []);
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        close();
      }}
    >
      <div
        className={
          GroupView
            ? "colFilter customScrollBar tagsView"
            : "colFilter customScrollBar "
        }
      >
        {!GroupView ? (
          Detail.map((detail: any, key: number) => (
            <div
              className={
                detail.disable ? "filterRow filterRowDisable" : "filterRow"
              }
              key={key}
              onClick={() => {
                !detail.disable
                  ? detail.prop === "edit"
                    ? edit(true)
                    : detail.prop === "group" && setGroupView(true)
                  : console.log("");
              }}
            >
              <img src={detail.icon} className={"colFilterIcon"} />
              <span>{detail.name}</span>
            </div>
          ))
        ) : (
          <div className="editRow groupView">
            <div className="editRowTitle groupViewTitle">
              {t("table.createNewGroup")}
            </div>
            <DropDown
              withInput={true}
              selectedItem={GroupName}
              textEntity={null}
              preText={""}
              items={Tags}
              pngSource={null}
              placeholder={t("table.NameOfGroup")}
              onChange={(selected: any) => {
                console.log("input selected", selected);
                setGroupName(selected);
              }}
            />
            <div
              className={"editFormSave"}
              style={{ marginTop: 20, justifyContent: "space-between" }}
            >
              <div
                style={{ marginLeft: 0 }}
                className={"editAction editActionCancel"}
                onClick={() => {
                  setGroupView(false);
                  close();
                }}
              >
                {t("table.Cancel")}
              </div>
              <div
                onClick={() => {
                  group(GroupName);
                  setGroupView(false);
                }}
                className={"editAction editActionSave"}
              >
                <img src="/assets/images/SideBars/Cats/plus.svg" />
                {t("table.Save")}
              </div>
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}
