import * as React from "react";
import { useTranslation } from "react-i18next";

export default function Impressum() {
  const [date] = React.useState("7 November 2019");
  const [t] = useTranslation();
  return (
    <div className="front-overal-paper fadeIn">
      <div className="last-modified-header d-flex align-items-center">
        <img src={"/assets/images/front/Calendar@2x.png"} alt="calendar-icon" />
        <small>
          {t("Impressum.last-modify")}: <span>{date}</span>.
        </small>
      </div>
      <h2>{t("Impressum.last-modify")}</h2>
      <h3>{t("Impressum.address-1")}</h3>
      <h6>{t("Impressum.address-2")}</h6>
      <h6>{t("Impressum.address-3")}</h6>
      <h6>{t("Impressum.address-4")}</h6>
      <h6>{t("Impressum.address-5")}</h6>
      <h6>{t("Impressum.contact")}</h6>
      <h6>{t("Impressum.tel")}</h6>
      <h6>{t("Impressum.email")}</h6>

      <p></p>

      <h3>{t("Impressum.representative")}</h3>
      <h6>{t("Impressum.representative-name")}</h6>
      <h6>{t("Impressum.register-court")}</h6>
      <h6>{t("Impressum.registration-number")}</h6>

      <p></p>

      <p>{t("Impressum.value-added-tax")}</p>

      <h3>{t("Impressum.responsible-person")}</h3>
      <h6>{t("Impressum.responsible-person-name")}</h6>
      <h6>{t("Impressum.responsible-person-address1")}</h6>
      <h6>{t("Impressum.responsible-person-address2")}</h6>
      <p>{t("Impressum.responsible-person-country")}</p>

      <h2>{t("Impressum.exclusion-of-liability")}:</h2>
      <h3>{t("Impressum.exclusion-of-liability-title1")}</h3>
      <p>{t("Impressum.exclusion-of-liability-title1-detail")}</p>

      <h3>{t("Impressum.exclusion-of-liability-title2")}</h3>
      <p>{t("Impressum.exclusion-of-liability-title2-detail")}</p>
      <h3>{t("Impressum.exclusion-of-liability-title3")}</h3>
      <p>{t("Impressum.exclusion-of-liability-title3-detail")}</p>

      <p>{t("Impressum.imprint")}</p>
    </div>
  );
}
