import React, { PureComponent, Dispatch } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { PrivateRoute } from "./pages/Auth/PrivateRoute";
import history from "./redux/history";
import Param from "./redux/Param";
import { setParam } from "./redux/actions/param.action";

//Main Container
import MainContainer from "./pages/Main/MainContainer";
import FrontMain from "./pages/Front/FrontMain";
// import NotFound from './pages/Main/NotFound'

//Dashboard Pages
import DashboardMain from "./pages/dashboard/DashboardMain";

//Profile Pages
import SwitchRole from "./pages/Setting/SwitchRole";
import AccountSetting from "./pages/Setting/AccountSetting";
import Billing from "./pages/Setting/Billing";
import Profile from "./pages/Setting/Profile";
import WidgetPermission from "./pages/Setting/WidgetPermission";
import Users from "./pages/Fleet/Users/Users";

//Auth Pages
import Invite from "./pages/Auth/Invite";
import VerifyEmail from "./pages/Auth/VerifyEmail";
import MobileVerify from "./pages/Auth/MobileVerify";
import RestPassword from "./pages/Auth/RestPassword";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import ForgotPassword from "./pages/Auth/ForgotPassword";

//Front Pages
import Home from "./pages/Front/Home";
import Calendar from "./pages/Calendar/Calendar";
import LegalCondition from "./pages/Front/LegalCondition";
import ContactUs from "./pages/Front/ContactUs";

//Fleet
import CanDevices from "./pages/Fleet/CanDevices/CanDevices";
import Vehicles from "./pages/Fleet/Vehicles/Vehicles";
import Trailers from "./pages/Fleet/Trailers/Trailers";

interface AppProps {
  token: string;
  user: any;
  setParam: (key: string, value: any) => void;
}

interface AppState {
  page: string;
  subPage: string;
  frontPage: string;
}

class App extends PureComponent<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      page: "/",
      subPage: "/",
      frontPage: "Home",
    };
  }

  onRouteChanged(page: string, subPage: string) {
    if (this.state.page !== page) {
      this.setState({ page });
    }
    if (this.state.subPage !== subPage) {
      this.setState({ subPage });
    }
  }

  onRouteChangedFront(page: string) {
    if (this.state.frontPage !== page) {
      this.setState({ frontPage: page });
    }
  }

  render() {
    const { token, user } = this.props;
    return (
      <Router history={history}>
        <Switch>
          <Route
            exact={true}
            path="/login"
            render={(props) => {
              return <Login {...props} />;
            }}
          />
          <Route
            exact={true}
            path="/mobile/verify/"
            render={(props) => {
              return <MobileVerify {...props} />;
            }}
          />
          <Route
            exact={true}
            path="/invite/accept/"
            render={(props) => {
              return <Invite {...props} />;
            }}
          />
          <Route
            exact={true}
            path="/verify/email/"
            render={(props) => {
              return <VerifyEmail {...props} />;
            }}
          />
          <Route
            exact={true}
            path="/password-reset/confirm/:uid/:token/"
            render={(props) => {
              return <RestPassword {...props} />;
            }}
          />
          <Route
            exact={true}
            path="/sign-up"
            render={(props) => {
              return <SignUp {...props} />;
            }}
          />
          <Route
            exact={true}
            path="/forgot-password"
            render={(props) => {
              return <ForgotPassword {...props} />;
            }}
          />
          <Route exact={true} path="/">
            <FrontMain
              withIcon={true}
              history={history}
              page={this.state.frontPage}
            >
              <Route
                render={(props) => {
                  this.onRouteChangedFront("Home");
                  return <Home {...props} />;
                }}
              />
            </FrontMain>
          </Route>
          <Route exact={true} path="/impressum/:tab">
            <FrontMain history={history} page={this.state.frontPage}>
              <Route
                render={(props) => {
                  this.onRouteChangedFront("Impressum");
                  return <LegalCondition {...props} />;
                }}
              />
            </FrontMain>
          </Route>
          <Route exact={true} path="/contact-us/:tab">
            <FrontMain history={history} page={this.state.frontPage}>
              <Route
                render={(props) => {
                  this.onRouteChangedFront("Contact");
                  return <ContactUs {...(props as any)} />;
                }}
              />
            </FrontMain>
          </Route>
          <MainContainer
            history={history}
            page={this.state.page}
            subPage={this.state.subPage}
            role={user.role}
          >
            <Route>
              <Switch>
                <PrivateRoute
                  exact={true}
                  user={user}
                  token={token}
                  path="/dashboard"
                  manager={false}
                  beforeRender={() => {
                    this.onRouteChanged("Dashboard", "Dashboard");
                  }}
                >
                  <DashboardMain />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  user={user}
                  token={token}
                  manager={false}
                  path="/Calendar"
                  beforeRender={() => {
                    this.onRouteChanged("Calendar", "Calendar");
                  }}
                >
                  <Calendar />
                </PrivateRoute>

                <PrivateRoute
                  user={user}
                  exact={true}
                  token={token}
                  manager={true}
                  path="/Fleet/Vehicles"
                  beforeRender={() => {
                    this.props.setParam(Param.BREADCRUMBS, [
                      { name: "Fleet", page: null },
                      { name: "Vehicles", page: null },
                    ]);
                    this.onRouteChanged("Fleet", "Vehicles");
                  }}
                >
                  <Vehicles />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  user={user}
                  token={token}
                  manager={true}
                  path="/Fleet/Trailers"
                  beforeRender={() => {
                    this.props.setParam(Param.BREADCRUMBS, [
                      { name: "Fleet", page: null },
                      { name: "Trailers", page: null },
                    ]);
                    this.onRouteChanged("Fleet", "Trailers");
                  }}
                >
                  <Trailers />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  user={user}
                  token={token}
                  manager={true}
                  path="/Fleet/CanDevices"
                  beforeRender={() => {
                    this.props.setParam(Param.BREADCRUMBS, [
                      { name: "Fleet", page: null },
                      { name: "CanDevices", page: null },
                    ]);
                    this.onRouteChanged("Fleet", "CanDevices");
                  }}
                >
                  <CanDevices />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  user={user}
                  token={token}
                  manager={true}
                  path="/Users"
                  beforeRender={() => {
                    this.props.setParam(Param.BREADCRUMBS, [
                      { name: "Users", page: null },
                    ]);
                    this.onRouteChanged("Users", "Users");
                  }}
                >
                  <Users />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  token={token}
                  manager={false}
                  user={user}
                  path="/settings/profile"
                  beforeRender={() => {
                    this.props.setParam(Param.BREADCRUMBS, [
                      { name: "Settings", page: null },
                      { name: "Profile", page: null },
                    ]);
                    this.onRouteChanged("Settings", "Profile");
                  }}
                >
                  <Profile />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  token={token}
                  manager={false}
                  user={user}
                  path="/settings/dashboard"
                  beforeRender={() => {
                    this.props.setParam(Param.BREADCRUMBS, [
                      { name: "Settings", page: null },
                      { name: "Dashboard", page: null },
                    ]);
                    this.onRouteChanged("Settings", "Dashboard");
                  }}
                >
                  <WidgetPermission />
                </PrivateRoute>
                <PrivateRoute
                  exact={true}
                  user={user}
                  token={token}
                  manager={true}
                  path="/settings/account"
                  beforeRender={() => {
                    this.props.setParam(Param.BREADCRUMBS, [
                      { name: "Settings", page: null },
                      { name: "Account", page: null },
                    ]);
                    this.onRouteChanged("Settings", "Account");
                  }}
                >
                  <AccountSetting />
                </PrivateRoute>

                <PrivateRoute
                  user={user}
                  exact={true}
                  token={token}
                  manager={true}
                  path="/settings/billing"
                  beforeRender={() => {
                    this.props.setParam(Param.BREADCRUMBS, [
                      { name: "Settings", page: null },
                      { name: "Billing", page: null },
                    ]);
                    this.onRouteChanged("Settings", "Billing");
                  }}
                >
                  <Billing />
                </PrivateRoute>
                {/* <Route component={NotFound} /> */}
              </Switch>
            </Route>
          </MainContainer>
          {/* <Route path="404" component={NotFound} /> */}
        </Switch>
      </Router>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  setParam: (key: string, value: any) => dispatch(setParam(key, value)),
});
const mapStateToProps = (state: any) => ({
  token: state.auth.token,
  user: state.user.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
