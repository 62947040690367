import * as React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useEffect } from "react";
import { useDispatch } from "../../../redux/store";
import {
  vehiclesBrands,
  vehicles,
  vehiclesCAN,
  vehiclesSubtypes,
} from "../../../redux/actions/table.action";
import ColorSelect from "./ColorSelect";
import { useTranslation } from "react-i18next";
import { DropDown, LoadingButton } from "../FromUtils";
interface Props {
  Type: string;
  Title: string;

  RowData: Record<string, any>;
  ActionResult: Record<string, any>;
  TableRowsData: Record<string, any>;
  close: () => void | undefined;
  deleteRow: (type: any) => void | undefined;
  save: (type: any) => void | undefined;
}

export default function EditView({
  RowData,
  Title,
  TableRowsData,
  close,
  save,
  Type,
  deleteRow,
  ActionResult,
}: Props) {
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const [Done, setDone] = React.useState(false);
  const [BtnLoading, setBtnLoading] = React.useState(false);
  const [Data, setData] = React.useState<Record<string, any>>({});
  const [NewData, setNewData] = React.useState<Record<string, any>>({});
  const [Vehicels, setVehicels] = React.useState<Record<string, any>>({});
  const [Error, setError] = React.useState<boolean>(false);
  const [SearchRes, setSearchRes] = React.useState<Record<string, any>>([]);

  const [SearchKey, setSearchKey] = React.useState<Record<string, any>>([]);

  function SearchChange(e: any, col: any) {
    const value = e.target.value;
    const SearchData = { search: value, type: Type };
    //alert(col.search);
    setSearchKey({
      ...SearchKey,
      [col.search]: value,
    });
    if (col.search === "vehiclesBrands") {
      dispatch(vehiclesBrands(SearchData)).then((res: any) => {
        if (res && res.payload) {
          setSearchRes({
            ...SearchRes,
            [col.search]: res.payload.results,
          });
        }
      });
    }

    if (col.search === "vehiclesSubtypes") {
      dispatch(vehiclesSubtypes(SearchData)).then((res: any) => {
        if (res && res.payload) {
          //setSearchRes(res.payload.results);
          setSearchRes({
            ...SearchRes,
            [col.search]: res.payload.results,
          });
        }
      });
    }
    if (col.search === "vehicle") {
      dispatch(vehicles(SearchData)).then((res: any) => {
        if (res && res.payload) {
          setSearchRes({
            ...SearchRes,
            [col.search]: res.payload.results,
          });
        }
      });
    }
  }

  function setSearchObject(col: any, id: any, name: string) {
    setData({
      ...Data,
      [col.name]: id,
    });
    setNewData({
      ...NewData,
      [col.name]: id,
    });
    setSearchKey({
      ...SearchKey,
      [col.search]: name,
    });
  }

  React.useEffect(() => {
    if (RowData.id && !Done) {
      TableRowsData.map((input: any) => {
        if (input.type === "dropDown") {
          if (input.search === "vehicles") {
            dispatch(vehicles({})).then((res: any) => {
              if (res && res.payload) {
                setVehicels(res.payload.results);
              }
            });
          }
          if (input.search === "vehiclesCAN") {
            dispatch(vehiclesCAN({})).then((res: any) => {
              if (res && res.payload) {
                setVehicels(res.payload.results);
              }
            });
          }
        }
      });

      setDone(true);
      setData(RowData);
      setNewData({
        ...NewData,
        id: RowData.id,
        obj_groups: RowData.obj_groups === "" ? [] : RowData.obj_groups,
      });
      setSearchKey({
        ...SearchKey,
        vehiclesBrands: RowData.vehicle_brand && RowData.vehicle_brand.name,
        vehiclesSubtypes:
          RowData.vehicle_brand &&
          RowData.vehicle_brand.subtype &&
          RowData.vehicle_brand.subtype.name,
      });
    }
  }, []);

  function readyForPatch() {
    setBtnLoading(true);
    save(NewData);
  }

  useEffect(() => {
    if (ActionResult) {
      setBtnLoading(false);
      if (
        (ActionResult.payload && !ActionResult.payload.error) ||
        ActionResult.delete
      ) {
        close();
      } else {
        ActionResult.payload && setError(ActionResult.payload.errorMassage);
      }
    }
  }, [ActionResult]);

  function change(event: any, col: any) {
    const name = event.target.name;
    let value = event.target.value;
    if (col === "mobile_number" || col === "mobile_cc") {
      value = parseInt(value);
    }
    if (Data !== undefined) {
      setNewData({
        ...NewData,
        [name]: value,
      });
    }
  }

  function tagDelete(key: number) {
    const obj_groups = [...NewData.obj_groups];
    obj_groups.splice(key, 1);
    setNewData({
      ...NewData,
      obj_groups,
    });
  }

  function handleKeyDown(e: any) {
    if (e.key === "Enter") {
      const value = e.target.value;
      const name = e.target.name;
      let obj_groups = NewData && NewData.obj_groups && [...NewData.obj_groups];
      if (!obj_groups) {
        obj_groups = [];
      }
      // console.log("Tags", tags);
      // console.log("value", value);
      obj_groups.push(value);
      setNewData({
        ...NewData,
        obj_groups,
        [name]: "",
      });
    }
  }

  function inputType(col: Record<string, any>, key: number) {
    if (col.editable) {
      let res = null;
      if (
        col.type !== "select" &&
        col.type !== "dropDown" &&
        col.type !== "array" &&
        col.type !== "color" &&
        col.type !== "search"
      ) {
        res = (
          <input
            value={
              Object.prototype.hasOwnProperty.call(NewData, col.name)
                ? NewData[col.name]
                : Data[col.name]
            }
            type={col.type}
            name={col.name}
            onChange={(value) => change(value, col)}
          />
        );
      } else {
        if (col.type === "color") {
          res = (
            <ColorSelect
              colorsSet={(value) => {
                setNewData({
                  ...NewData,
                  color: value,
                });
              }}
              InputColor={NewData.color ? NewData.color : Data.color}
            ></ColorSelect>
          );
        }
        if (col.type === "select") {
          res = (
            <select
              className={"form-control"}
              name={col.name}
              onChange={(value) => change(value, col)}
            >
              {col.options.map((op: any, key: any) => (
                <option
                  key={key}
                  value={op.name}
                  selected={Data[col.name] === op.name ? true : false}
                >
                  {op.label}
                </option>
              ))}
            </select>
          );
        }
        if (col.type === "dropDown") {
          res = (
            <DropDown
              selectedItem={{ license: Data["vehicle"] }}
              textEntity={"license"}
              preText={""}
              items={
                !Vehicels.length
                  ? Data["vehicle"]
                    ? [{ license: Data["vehicle"] }]
                    : []
                  : Vehicels
              }
              link={true}
              pngSource={
                Data["vehicle"]
                  ? "/assets/icon-basic-link.svg"
                  : "/assets/icon-basic-un-link.svg"
              }
              placeholder="Select Vehicle"
              onChange={(selected: any) => {
                setNewData({
                  ...NewData,
                  [col.name]: selected.id ? selected.id : null,
                  vehicle_name: selected.license,
                });
                setData({
                  ...Data,
                  [col.name]: selected.id ? selected.id : null,
                  vehicle: selected.license,
                });
              }}
            />
          );
        }
        if (col.type === "array") {
          res = (
            <input
              type="text"
              value={NewData["tagInput"] ? NewData["tagInput"] : ""}
              name={"tagInput"}
              onChange={(value) => change(value, col)}
              onKeyDown={(value) => handleKeyDown(value)}
            />
          );
        }
        if (col.type === "search") {
          res = (
            <div>
              <input
                value={SearchKey[col.search]}
                type={col.type}
                name={col.name}
                onChange={(value) => SearchChange(value, col)}
              />
              {SearchKey && SearchRes[col.search] && (
                <div className={"searchResBox"}>
                  {SearchRes[col.search] &&
                    SearchRes[col.search].map((searchRow: any, key: any) => (
                      <div
                        className={
                          Data[col.name] === searchRow.id
                            ? "searchResBoxItem searchResBoxItemActive"
                            : "searchResBoxItem"
                        }
                        key={key}
                        onClick={() => {
                          setSearchObject(col, searchRow.id, searchRow.name);
                        }}
                      >
                        {searchRow.name}
                      </div>
                    ))}
                </div>
              )}
            </div>
          );
        }
      }

      const row = (
        <div className={"editRow"} key={key}>
          <div className={"editRowTitle"}>
            {col.label}{" "}
            {col.label === "obj_groups" ? (
              <strong>{t("table.groupsDesc")}</strong>
            ) : (
              ""
            )}{" "}
            {col.required && <span className={"required"}>*</span>}
          </div>
          {res}
          {col.type === "array" &&
            NewData.obj_groups &&
            NewData.obj_groups[0] &&
            NewData.obj_groups.map((tag: any, key: any) => (
              <div className={"tagTable"} key={key}>
                {tag}
                <span className={"tagDelete"} onClick={() => tagDelete(key)}>
                  <img src={"/assets/close.svg"} />
                </span>
              </div>
            ))}
        </div>
      );
      if (
        col.type === "search" &&
        col.name === "sub_type" &&
        Type !== "TRAILER"
      ) {
        return <div></div>;
      }
      return row;
    }
  }

  return (
    <div className={"editView"}>
      <OutsideClickHandler onOutsideClick={() => close()}>
        <div className={"editForm"}>
          <div className={"title"}>
            {t("table.Edit")} {Title}
          </div>
          {Error && (
            <div className="alert alert-danger">
              <strong>{t("table.Error")}</strong> {Error}
            </div>
          )}
          {TableRowsData.map((col: Record<string, any>, key: number) =>
            inputType(col, key),
          )}
          <div className={"editFormSave"}>
            <div className={"closeParent"}>
              <div
                className={"editAction editActionDelete"}
                onClick={() => deleteRow(Data)}
              >
                {t("table.Delete")}
              </div>
            </div>
            <div
              className={"editAction editActionCancel"}
              onClick={() => close()}
            >
              {t("table.Cancel")}
            </div>
            <LoadingButton
              className={"editAction editActionSave"}
              loading={BtnLoading}
              onClick={() => {
                readyForPatch();
              }}
              text={t("table.Save")}
              icon={"/assets/images/SideBars/Cats/plus.svg"}
            />
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}
