import { createAsyncThunk } from "@reduxjs/toolkit";
import * as tableService from "../services/table.service";
import * as userService from "../services/user.service";
import { errorHandler } from "../apiGateway";

export const users = createAsyncThunk(
  "company/profiles/",
  async (data: any) => {
    try {
      return await tableService.users(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const drivers = createAsyncThunk(
  "company/profiles/drivers",
  async (data: any) => {
    try {
      return await tableService.drivers(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const driverCreate = createAsyncThunk(
  "company/profile/register/",
  async (data: any) => {
    try {
      return await tableService.driversCreate(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const usersTag = createAsyncThunk("user/tag/", async (data: any) => {
  try {
    return await tableService.usersTag(data);
  } catch (err) {
    return errorHandler(err);
  }
});

export const vehiclesTag = createAsyncThunk(
  "vehicles​/tag​/",
  async (data: any) => {
    try {
      return await tableService.vehiclesTag(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const driverDelete = createAsyncThunk(
  "company/profiles/",
  async (data: any) => {
    try {
      return await tableService.driversDelete(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const driverEdit = createAsyncThunk(
  "company/profiles/",
  async (data: any) => {
    try {
      return await tableService.driversEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const vehicles = createAsyncThunk(
  "table/company/profiles/",
  async (data: any) => {
    try {
      return await tableService.companyVehicle(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const vehiclesCAN = createAsyncThunk(
  "table/company/profiles/CAN",
  async (data: any) => {
    try {
      return await tableService.companyVehicleCAN(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const vehiclesBrands = createAsyncThunk(
  "table/vehicles/brands/",
  async (data: any) => {
    try {
      return await tableService.vehiclesBrands(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const vehiclesSubtypes = createAsyncThunk(
  "table/vehicles/subtypes/",
  async (data: any) => {
    try {
      return await tableService.vehiclesSubtypes(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const vehicleCreate = createAsyncThunk(
  "table/company-vehicles/",
  async (data: any) => {
    try {
      return await tableService.vehicleCreate(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const vehicleEdit = createAsyncThunk(
  "table/company-vehicles/",
  async (data: any) => {
    try {
      return await tableService.vehicleEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const vehicleDelete = createAsyncThunk(
  "table/company-vehicles/",
  async (data: any) => {
    try {
      return await tableService.vehicleDelete(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const trailers = createAsyncThunk(
  "vehicles/brands/?type=trailer",
  async (data: any) => {
    try {
      return await tableService.trailer(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);

export const canDevices = createAsyncThunk(
  "can-devices/",
  async (data: any) => {
    try {
      return await tableService.canDevices(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const canDevicesCreate = createAsyncThunk(
  "can-devices/",
  async (data: any) => {
    try {
      return await tableService.canDevicesCreate(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const canDevicesDelete = createAsyncThunk(
  "can-devices/",
  async (data: any) => {
    try {
      return await tableService.canDevicesDelete(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
export const canDevicesEdit = createAsyncThunk(
  "can-devices/",
  async (data: any) => {
    try {
      return await tableService.canDevicesEdit(data);
    } catch (err) {
      return errorHandler(err);
    }
  },
);
