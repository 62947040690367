import { createSlice } from "@reduxjs/toolkit";
import { holidays, myRequests } from "../actions/calendar.action";

const initialState: Record<string, any> = {
  holidays: null,
  myRequests: null,
};
const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(holidays.fulfilled, (state, { payload }) => {
      state.holidays = payload;
    });
    builder.addCase(myRequests.fulfilled, (state, { payload }) => {
      state.myRequests = payload;
    });
  },
});

export default calendarSlice;
