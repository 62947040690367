import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useDispatch } from "../../redux/store";
import { profile, profileHeader } from "../../redux/actions/user.action";
import { useState } from "react";
import ky from "ky";

const FrontHeader = ({ page, token, history }) => {
  const [Login, setLogin] = useState(token);
  const [t] = useTranslation();
  const changeLanguage = (lng) => {
    localStorage.setItem("lang", lng);
    i18n.changeLanguage(lng);
  };
  const langs = [
    { name: "English", key: "en", flag: "/assets/flags/gb.png" },
    { name: "Deutsch", key: "de", flag: "/assets/flags/de.png" },
    { name: "Polski", key: "pl", flag: "/assets/flags/pl.png" },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileHeader(token)).then((response) => {
      if (response.payload && !response.payload.error) {
        setLogin(true);
      } else {
        setLogin(false);
      }
    });
  }, []);
  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand>
        <Link to={page !== "Home" ? "/" : null} className="navbar-brand">
          <img
            className="remove-under-1250"
            src={"/assets/images/logo/Logox2.png"}
            alt="fleetspark-logo"
          />
          <img
            className="show-above-1250"
            src={"/assets/images/logo/Ficonx2.png"}
            alt="fleetspark-logo"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <ul className="navbar-nav align-items-baseline">
          <li className="nav-item">
            <Link
              to={page !== "Home" ? "/" : null}
              className={
                page === "Home"
                  ? "front-page-link-active"
                  : "front-page-link-deactive"
              }
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={page !== "Impressum" ? "/impressum/impressum" : null}
              className={
                page === "Impressum"
                  ? "front-page-link-active"
                  : "front-page-link-deactive"
              }
            >
              {t("contact.impressum")}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to={page !== "Contact" ? "/contact-us/general" : null}
              className={
                page === "Contact"
                  ? "front-page-link-active"
                  : "front-page-link-deactive"
              }
            >
              {t("home.contact")}
            </Link>
          </li>

          <li className="nav-item langMenu">
            <div className="langMenuHome  language-header-main d-flex align-items-center ">
              <div className="header-language-icon d-flex align-items-center">
                <img
                  src={"/assets/images/header/Language@2x.png"}
                  alt="input-icon"
                />
              </div>
              <button className="header-language-container align-items-center">
                <img
                  src={"/assets/images/header/ArrowDown@2x.png"}
                  alt="input-icon"
                />
              </button>
              <div className="langMenu-drop-menu">
                {langs.map((lang, key) => (
                  <button
                    key={key}
                    onClick={() => changeLanguage(lang.key)}
                    className={i18n.language === lang.key ? "activeLang" : ""}
                  >
                    <img className={"langFlag"} src={lang.flag} />

                    {lang.name}
                  </button>
                ))}
              </div>
            </div>
          </li>
          <li className="nav-item">
            <div className="front-header-buttons">
              {!Login && (
                <button
                  onClick={() => history.push("/login")}
                  className="login"
                >
                  {t("home.login")}
                </button>
              )}
              <button
                onClick={() => {
                  if (Login) {
                    history.push("/dashboard");
                  } else {
                    history.push("/sign-up");
                  }
                }}
              >
                {Login ? t("home.go-to-panel") : t("home.signUp")}
              </button>
            </div>
          </li>
        </ul>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default FrontHeader;
