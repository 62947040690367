import * as React from "react";
import ActionController from "./Package/ActionController";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  saveSetting,
  getSetting,
  groups,
} from "../../redux/actions/dashboard.action";
import { drivers } from "../../redux/actions/table.action";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useDispatch } from "../../redux/store";
import { useState, useRef, useLayoutEffect } from "react";
import { useEffect } from "react";
import { company } from "../../redux/actions/user.action";
import { apiAction } from "./Package/Widgets/ApiCall";
import i18n from "i18next";
const DefaultParam = {
  offset: 0,
  limit: 299,
  search: false,
  ordering: false,
  according: false,
};
export default function DashboardMain() {
  const history = useHistory();
  const Token = useSelector((state: RootState) => state.auth.token);
  const menuOpen = useSelector((state: RootState) => state.menu.menuState);
  const dispatch = useDispatch();
  const [SettingsData, setSettingsData] = useState(undefined);
  const [Reload, setReload] = useState(false);
  const [Drivers, setDrivers] = useState(undefined);
  const [Groups, setGroups] = useState(undefined);
  const [CompanyLogo, setCompanyLogo] = useState("");
  const [Lists, setLists] = useState({});

  const [t] = useTranslation();

  function getList() {
    apiAction("list-api", {}).then((res: any) => {
      if (res && !res.error) {
        setLists(res);
      }
    });
  }
  function settings() {
    getList();
    dispatch(company(Token)).then((response: any) => {
      if (response.payload) {
        setCompanyLogo(response.payload.logo);
      }
    });
    dispatch(drivers(DefaultParam)).then((res: any) => {
      if (res && res.payload) {
        setDrivers(res.payload.results);
      }
    });

    dispatch(groups()).then((res: any) => {
      if (res && res.payload) {
        setGroups(res.payload.locations);
      }
    });

    dispatch(getSetting(Token)).then((response: any) => {
      if (response.payload) {
        const data = response.payload.setting;
        setSettingsData(data);
      } else {
        localStorage.removeItem("token");
        history.replace("/login");
      }
    });
  }
  useEffect(() => {
    settings();
  }, []);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    getList();
    setReload(!Reload);
  }, [i18n.language]);
  function save(settings: any) {
    dispatch(saveSetting({ sections: settings, Token })).then(
      (response: any) => {
        if (response.payload) {
          const data = response.payload.setting;
          setSettingsData(data);
        }
      },
    );
  }
  return (
    <>
      <div
        className={
          "page-container d-flex flex-column justify-content-center DashboardFa"
        }
      >
        <ActionController
          string={t("dashboard", { returnObjects: true })}
          reload={Reload}
          menuOpen={menuOpen}
          saveSetting={(settings: any) => {
            save(settings);
          }}
          getSetting={SettingsData}
          api={{
            headers: {
              Accept: "application/json",
              "x-api-key": "ZuRgyZaRCC1e1w4bYQFEz175H45MXc5F3h3vd6Ul",
              Authorization: `Bearer ${Token}`,
              "Accept-Language": localStorage.getItem("lang") || "en",
            },
            mainUrl: "",
          }}
          WidgetsList={Lists}
          logo={CompanyLogo}
          usersList={Drivers}
          GroupsList={Groups}
        />
      </div>
    </>
  );
}
