import { combineReducers } from "redux";
import dashboard from "./dashboard.reducer";
import auth from "./auth.reducer";
import table from "./table.reducer";
import user from "./user.reducer";
import calendar from "./calendar.reducer";
import menu from "./menu.reducer";
import param from "./param.reducer";

const rootReducer = combineReducers({
  param,
  dashboard: dashboard.reducer,
  auth: auth.reducer,
  table: table.reducer,
  user: user.reducer,
  menu: menu.reducer,
  calendar: calendar.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
