import * as React from "react";
import { Link } from "react-router-dom";
import Impressum from "../LegalConditions/Impressum";
import PrivacyPolicy from "../LegalConditions/PrivacyPolicy";
import CookiePolicy from "../LegalConditions/CookiePolicy";
import TermsAndConditions from "../LegalConditions/TermsAndConditions";
import { useTranslation } from "react-i18next";
const tabs = [
  "impressum",
  "terms-and-conditions",
  "cookie-policy",
  "privacy-policy",
];

export default function LegalCondition({ history, match }: any) {
  const [t] = useTranslation();
  const [tab, setTab] = React.useState("impressum");
  const mainRef: any = React.useRef(null);
  React.useEffect(() => {
    if (history.action === "PUSH") {
      mainRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (tabs.find((x) => x === match.params.tab) !== undefined) {
      if (tab !== match.params.tab) {
        setTab(match.params.tab);
      }
    }
  }, [history.action, match.params.tab, tab]);
  return (
    <div ref={mainRef}>
      <div className="front-main-container-overlay">
        <h1>{t("contact.legal")}</h1>
        <div className="front-sub-page-items d-flex align-items-center justify-content-center">
          <Link
            to={tab !== "impressum" ? "/impressum/impressum" : ""}
            className={tab === "impressum" ? "active" : ""}
          >
            {t("contact.impressum")}
          </Link>
          <Link
            to={
              tab !== "terms-and-conditions"
                ? "/impressum/terms-and-conditions"
                : ""
            }
            className={tab === "terms-and-conditions" ? "active" : ""}
          >
            {t("contact.terms")}
          </Link>
          <Link
            to={tab !== "cookie-policy" ? "/impressum/cookie-policy" : ""}
            className={tab === "cookie-policy" ? "active" : ""}
          >
            {t("contact.cookie-policy")}
          </Link>
          <Link
            to={tab !== "privacy-policy" ? "/impressum/privacy-policy" : ""}
            className={tab === "privacy-policy" ? "active" : ""}
          >
            {t("contact.privacy-policy")}
          </Link>
        </div>
        {tab === "impressum" && <Impressum />}
        {tab === "terms-and-conditions" && <TermsAndConditions />}
        {tab === "cookie-policy" && <CookiePolicy />}
        {tab === "privacy-policy" && <PrivacyPolicy />}
      </div>
    </div>
  );
}
