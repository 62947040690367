import { rest } from "../apiGateway";
import ky from "ky";

export const holidays = () => {
  return rest.get("calendar/manage-holiday/?ordering=-&limit=500").json();
};
export const holidaysOpen = () => {
  return rest
    .get("calendar/manage-holiday/?request_status=OPEN&ordering=-&limit=500")
    .json();
};
export const holidayEdit = (json: any) => {
  return rest
    .patch("calendar/manage-holiday/" + json.id + "/", { json })
    .json();
};
export const myRequests = () => {
  return rest.get("calendar/request-holiday/").json();
};

export const createRequest = (json: any) => {
  const formData = new FormData();
  for (const key in json) {
    if (json[key]) {
      formData.append(key, json[key]);
    }
  }
  return ky
    .post(
      `${process.env.REACT_APP_BACKEND_REST_ADDR_APIS}/` +
        "calendar/request-holiday/",
      {
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    )
    .json();
  //return rest.post("calendar/request-holiday/", { json }).json();
};
