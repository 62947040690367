import React, { useEffect } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch } from "../../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import {
  canDevices,
  canDevicesCreate,
  canDevicesEdit,
  canDevicesDelete,
} from "../../../redux/actions/table.action";
import Table from "../../../utilities/components/Table/Table";
import { useTranslation } from "react-i18next";

// interface ChartProps {
//     // pin: () => void | undefined;
//     // remove: () => void | undefined;
//     // changeDate: (Range: object) => void | undefined;
//     // el: Record<string, any>;
// }

const DefaultParam = {
  offset: 0,
  limit: 10,
  search: false,
  ordering: false,
  according: false,
};

export default function CanDevices() {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const TableRowsData = [
    {
      name: "imei",
      label: t("table.imei"),
      type: "text",
      required: true,
      help_text: "",
      editable: true,
    },
    {
      name: "serial",
      label: t("table.serial"),
      type: "text",
      required: true,
      help_text: "",
      editable: true,
    },
    {
      name: "vehicle",
      label: t("table.vehicle"),
      type: "dropDown",
      search: "vehiclesCAN",
      options: [],
      required: false,
      help_text: "",
      editable: true,
      hiddenCreate: true,
    },
  ];

  const [Result, setResult] = React.useState<any>();
  const [DefaultParams, setDefaultParams] = React.useState<any>(DefaultParam);
  const tableData = useSelector((state: RootState) => state.table.canDevices);
  useEffect(() => {
    //dispatch(canDevices(DefaultParams));
  }, []);
  useEffect(() => {
    if (Result) {
      dispatch(canDevices(DefaultParams));
    }
  }, [Result]);

  function request(Params: any) {
    if (
      DefaultParams.offset !== Params.offset ||
      DefaultParams.limit !== Params.limit ||
      DefaultParams.search !== Params.search ||
      DefaultParams.ordering !== Params.ordering ||
      DefaultParams.according !== Params.according
    ) {
      setDefaultParams(Params);
      dispatch(canDevices(Params));
    }
  }

  function create(data: any) {
    dispatch(canDevicesCreate(data)).then((res) => {
      if (res) {
        setResult(res);
      }
    });
  }

  function edit(data: any) {
    dispatch(canDevicesEdit(data)).then((res) => {
      if (res) {
        setResult(res);
      }
    });
  }

  function deleteRow(data: any) {
    dispatch(canDevicesDelete(data)).then((res) => {
      if (!res.payload.error) {
        setResult({ delete: true });
      } else {
        setResult(res);
      }
    });
  }

  return (
    <div>
      <Table
        getData={(Params: any) => {
          request(Params);
        }}
        CreateItem={(data) => create(data)}
        EditItem={(data) => edit(data)}
        ActionResult={Result}
        clearResult={() => {
          setResult(null);
        }}
        TableRowsData={TableRowsData}
        tableData={tableData}
        editable={true}
        creatable={true}
        DeleteRow={(data) => deleteRow(data)}
        tableType={t("menu.can_devices")}
      />
    </div>
  );
}
