import React, { ReactNode } from "react";
import Countdown from "react-countdown";
import "react-animated-slider/build/vertical.css";
interface Props {
  container: any;
  CountdownRef: any;
  SlideTimer: any;
  renderer: any;
  ShowSlideTimer: any;
  SliderStop: any;
  CountDown: any;
  exit: () => void | undefined;
  slideAction: (el: any) => void | undefined;
  setShowSlideTimer: (el: any) => void | undefined;
  setSliderStop: (action: any) => void | undefined;
  setSlideShowMode: (action: any) => void | undefined;
  setScale: (action: any) => void | undefined;
  setSlideTimer: (time: any) => void | undefined;
  t: Record<string, any>;
  children: ReactNode;
}

export default function SlideShow({
  container,
  slideAction,
  SlideTimer,
  renderer,
  CountdownRef,
  ShowSlideTimer,
  setShowSlideTimer,
  SliderStop,
  CountDown,
  setSliderStop,
  setSlideTimer,
  exit,
  setSlideShowMode,
  setScale,
  children,
}: Props) {
  function SliderTimer() {
    const times = [30000, 60000, 120000, 300000, 600000];
    return (
      <div className={"timesSlide"}>
        {times.map((time, key) => (
          <div
            key={key}
            onClick={() => {
              setSlideTimer(time);
            }}
            className={SlideTimer === time ? "activeTime" : ""}
          >
            {time / 60000 < 1
              ? (time / 60000) * 60 + " Seconds"
              : time / 60000 + " Minutes"}
          </div>
        ))}
      </div>
    );
  }
  return (
    <>
      <div className={"slider slideShowDashboard"}>{children}</div>
      <div className={"dashboardSliderActionView"} ref={container}>
        <div className={"dashboardSliderAction"}>
          <div className={"CountdownFa"}>
            <Countdown
              onComplete={() => {
                slideAction(true);
              }}
              onPause={() => {
                console.log("onPause");
              }}
              controlled={false}
              ref={CountdownRef}
              date={Date.now() + SlideTimer}
              renderer={renderer}
            />
          </div>
          <div className={"fsLogoSlideShow"}>
            <img src={"./assets/images/SideBars/FS_logo.svg"} />
          </div>

          <div className={"SliderActionGroup"}>
            <div className={"sliderAction"}>
              <img src={"./assets/icon-basic-moon.svg"} />
            </div>
            <div
              className={"sliderAction"}
              onClick={() => {
                setShowSlideTimer(!ShowSlideTimer);
              }}
            >
              <img src={"./assets/Timer.svg"} />
              {ShowSlideTimer && SliderTimer()}
            </div>
          </div>
          <div className={"SliderActionGroup"}>
            <div
              className={"sliderAction"}
              onClick={() => {
                slideAction(true);
              }}
            >
              <img src={"./assets/icon-basic-arrow-down.svg"} />
            </div>
            <div
              className={"sliderAction"}
              onClick={() => {
                slideAction(false);
              }}
            >
              <img src={"./assets/icon-basic-arrow-up.svg"} />
            </div>
          </div>
          <div className={"SliderActionGroup"}>
            <div
              className={"sliderAction"}
              onClick={() => {
                if (CountDown) {
                  setSliderStop(!SliderStop);
                  if (SliderStop) {
                    CountDown.start();
                  } else {
                    CountDown.pause();
                  }
                }
              }}
            >
              {!SliderStop ? (
                <img src={"./assets/icon-basic-pause.svg"} />
              ) : (
                <img src={"./assets/play-icon.svg"} />
              )}
            </div>
            <div
              className={"sliderAction"}
              onClick={() => {
                exit();
                setSlideShowMode(false);
                setScale(1);
              }}
            >
              <img src={"./assets/slide-close.svg"} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
