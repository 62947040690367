import * as React from "react";
import { ChartLoadingSvg } from "../../Components/svg";

export default function Loading({ Type }: any) {
  return (
    <div className={"widgetLoading"}>
      <ChartLoadingSvg />
    </div>
  );
}
