import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../../redux/store";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  holidays,
  holidayEdit,
  myRequests,
} from "../../redux/actions/calendar.action";
import AddHolidayRequest from "./AddHolidayRequest";

const localizer = momentLocalizer(moment);

export default function CalendarDriver({ history }: any) {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [event, setEvent] = React.useState([]);
  const [requestsShow, setRequestsShow] = React.useState(true);
  const [AddRequest, setAddRequest] = React.useState(false);

  const [RowOpen, setRowOpen] = React.useState(0);

  const holidaysList = useSelector(
    (state: RootState) => state.calendar.holidays,
  );
  const user = useSelector((state: RootState) => state.user.profile);
  const myRequestsList = useSelector(
    (state: RootState) => state.calendar.myRequests,
  );
  function NameWeek(date: any, culture: any) {
    return t("calendar." + [localizer.format(date, "dddd", culture)]);
  }
  const formats = {
    weekdayFormat: (date: any, culture: any) => NameWeek(date, culture),
  };

  function RequestStatus(request_status: any, request: any) {
    const data = {
      id: request.id,
      request_status: request_status,
    };
    setRowOpen(0);

    dispatch(holidayEdit(data)).then((res) => {
      dispatch(holidays());
    });
  }

  function eventStyleGetter(
    event: any,
    start_date: any,
    end_date: any,
    isSelected: any,
  ) {
    const backgroundColor = event.color; //
    const style = {
      backgroundColor: isSelected ? "#000" : backgroundColor,
      color: "#fff",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
      title: "dasdad asda ",
    };
  }

  React.useEffect(() => {
    dispatch(myRequests());
  }, []);
  React.useEffect(() => {
    const events: any = [];
    if (myRequestsList !== null) {
      myRequestsList.results.map((holiday: any, key: any) => {
        const e: any = {};
        e.title =
          user.first_name +
          " " +
          user.last_name +
          " - " +
          t("calendar." + holiday.type);
        e.type = holiday.type;
        e.status = holiday.request_status;
        if (e.status === "OPEN") {
          e.color = "#fdbf5e";
        } else if (e.status === "REJECTED") {
          e.color = "#ff3d57";
        } else {
          e.color = "#09b66d";
        }

        e.start_date = new Date(holiday.start_date);
        e.end_date = new Date(holiday.end_date);
        e.end = holiday.end_date;
        e.start = holiday.start_date;
        if (e.status !== "REJECTED") {
          events.push(e);
        }
      });
      setEvent(events);
    }
  }, [myRequestsList]);
  const CustomToolbar = (toolbar: any) => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate("next");
    };

    const goToDayView = () => {
      toolbar.onViewChange("day");
    };
    const goToWeekView = () => {
      toolbar.onViewChange("week");
    };
    const goToMonthView = () => {
      toolbar.onViewChange("month");
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate("current");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          {/* <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span> */}
        </span>
      );
    };

    return (
      <div className={"toolbar-container"}>
        <label className={"label-date"}>{label()}</label>

        <div className={"back-next-buttons"}>
          <button className={"btn-back"} onClick={goToBack}>
            &#8249;
          </button>
          <button className={"btn-current"} onClick={goToCurrent}>
            today
          </button>
          <button className={"btn-next"} onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={"calendarFa"}>
      {AddRequest && (
        <AddHolidayRequest
          close={() => {
            setAddRequest(false);
            dispatch(myRequests());
          }}
        />
      )}
      <div
        className="createBtn addRequestBtn"
        onClick={() => {
          setAddRequest(true);
        }}
      ></div>
      <div
        className={"requestLink requestLinkUser"}
        onClick={() => {
          setRequestsShow(!requestsShow);
        }}
      >
        <span>{t("calendar.requests")}</span>
        <i className={requestsShow ? "" : "closeItem"}></i>
      </div>
      <div
        className={
          requestsShow
            ? "CalendarView UserCalendarToolbar"
            : "CalendarView CalendarViewBarOpen"
        }
      >
        <Calendar
          localizer={localizer}
          formats={formats}
          events={event}
          components={
            {
              //toolbar: CustomToolbar,
            }
          }
          eventPropGetter={eventStyleGetter}
          startAccessor="start_date"
          endAccessor="end_date"
          style={{}}
          messages={{
            month: t("calendar.month"),
            agenda: t("calendar.agenda"),
            allDay: t("calendar.allDay"),
            day: t("calendar.day"),
            today: t("calendar.today"),
            previous: t("calendar.previous"),
            next: t("calendar.next"),
            date: t("calendar.date"),
            noEventsInRange: t("calendar.noEventsInRange"),
            time: t("calendar.time"),
            tomorrow: t("calendar.tomorrow"),
            week: t("calendar.week"),
            work_week: t("calendar.work_week"),
            yesterday: t("calendar.yesterday"),
          }}
        />
      </div>
      <div
        className={
          requestsShow
            ? "OpenRequests customScrollBar"
            : "OpenRequests customScrollBar none"
        }
      >
        <div className="RequestsList">
          {myRequestsList &&
            myRequestsList.results &&
            myRequestsList.results.map((e: any, key: any) => (
              <div className="RequestsItem" key={key}>
                <div
                  className="RequestsItemHeader"
                  onClick={() => {
                    console.log("");
                  }}
                >
                  <div className={"imgBox"}>
                    <img src="" />
                  </div>
                  <div className="RequestTitle">
                    <div>{user.first_name + " " + user.last_name}</div>
                    <div className="RequestType">{t("calendar." + e.type)}</div>
                    <div className="RequestDate">
                      {e.start_date + " - " + e.end_date}
                    </div>
                  </div>
                  <div className={"requestIconStatus " + e.request_status}>
                    {e.request_status === "REJECTED" && (
                      <img src={"/assets/icon-basic-cross-white.svg"} />
                    )}
                    {e.request_status === "ACCEPTED" && (
                      <img src={"/assets/check.svg"} />
                    )}
                    {e.request_status === "OPEN" && "?"}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
